import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DataLablerComponent } from './view-components/data-labler/data-labler.component';
import { LabelerListComponent } from './view-components/labeler-list/labeler-list.component';
import { ProjectComponent } from './view-components/project/project.component';
import { OverviewComponent } from './view-components/overview/overview.component';
import { ReportsComponent } from './view-components/reports/reports.component';
import { ProjectReportsComponent } from './view-components/project-reports/project-reports.component';
import { MappingComponent } from './view-components/mapping/mapping.component';
import { TodayReportComponent } from './view-components/today-report/today-report.component';
import { DailyReportComponent } from './view-components/daily-report/daily-report.component';
import { ClientUploadComponent } from './view-components/client-upload/client-upload.component';
import { ClientDashboardComponent } from './view-components/client-dashboard/client-dashboard.component';
import { ClientProjectMappingComponent } from './view-components/client-project-mapping/client-project-mapping.component';
import { ImageDistributionComponent } from './view-components/image-distribution/image-distribution.component';
import { LoginComponent } from './view-components/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { RolesComponent } from './view-components/roles/roles.component';
import { UtilizationReportComponent } from './view-components/utilization-report/utilization-report.component';
import { ExamComponent } from './view-components/exam/exam.component';

/* data: {
    breadcrumb: { // when breadcrumb data is an object
        queryParamKey: '' // possible values are string with queryparam name (eg: 'cpu_id') or array of query params (eg: ['edge_node_id','cpu_id']) or string with value 'all' . based on this all queryparams from current route will be available on the breadcrumb link,
            , addQueryParamInUrl: true // if this key is set to true then only the query params are added in the link of the breadcrumb segment
    }
} */

const routes: Routes = [
    { path: 'overview',canActivate:[AuthGuardService], data: { breadcrumb: 'Overview' }, component: OverviewComponent },
    { path: 'today-report',canActivate:[AuthGuardService], data: { breadcrumb: 'Today Report' }, component: TodayReportComponent },
    { path: 'daily-report',canActivate:[AuthGuardService], data: { breadcrumb: 'Daily Report' }, component: DailyReportComponent },
    { path: 'project',canActivate:[AuthGuardService], data: { breadcrumb: 'Project' }, component: ProjectComponent },
    { path: 'mapping',canActivate:[AuthGuardService], data: { breadcrumb: 'Mapping' }, component: MappingComponent},
    { path: 'data-labeler',canActivate:[AuthGuardService], data: { breadcrumb: 'Data Labeler' }, component: DataLablerComponent},
    { path: 'reports',canActivate:[AuthGuardService], data: { breadcrumb: 'Reports' }, component: ReportsComponent},
    { path: 'roles',canActivate:[AuthGuardService], data: { breadcrumb: 'Roles' }, component: RolesComponent},
    { path: 'project-reports',canActivate:[AuthGuardService], data: { breadcrumb: 'Project-Reports' }, component: ProjectReportsComponent},
    { path: 'labeler-list',canActivate:[AuthGuardService], data: { breadcrumb: 'Data Labeler List' }, component: LabelerListComponent},
    { path: 'client-dashboard',canActivate:[AuthGuardService], data: { breadcrumb: 'Client-Dashboard' }, component: ClientDashboardComponent},
    { path: 'client-mapping',canActivate:[AuthGuardService], data: { breadcrumb: 'Client-mapping' }, component: ClientProjectMappingComponent},
    { path: 'image-distribution',canActivate:[AuthGuardService], data: { breadcrumb: 'image-distribution' }, component: ImageDistributionComponent},
    { path: 'upload',canActivate:[AuthGuardService], data: { breadcrumb: 'Upload' }, component: ClientUploadComponent},
    { path: 'utilization',canActivate:[AuthGuardService], data: { breadcrumb: 'Utilization' }, component: UtilizationReportComponent},
    { path: 'exam',canActivate:[AuthGuardService], data: { breadcrumb: 'Exam' }, component: ExamComponent},
    { path: 'login', data: { breadcrumb: 'Login' }, component: LoginComponent },
    // { path: '', redirectTo: 'login', pathMatch: 'full' },
    // { path: 'login', redirectTo: 'login', pathMatch: 'full' },
    // { path: '', loadChildren: './user-modules/common/pre-sigin-in/pre-sigin-in.module#PreSiginInModule' },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
// http://localhost:4200/logical-view/edge-node/edge-node-details/cpu-details?ip=192.168.26.16&edge_node_id=192.168.26.16&view=Frames&cpu_id=1&&gpu_id=1