import { Component, OnInit, ViewChild } from '@angular/core';
import { SingletonService } from '../../services/singleton.service';
import { HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { HttpClientService } from 'src/app/services/http-client.service';
import { distinct } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as moment from 'moment'
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { DatePipe } from '@angular/common';
import * as jsPDF from 'jspdf';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss']
})
export class DailyReportComponent implements OnInit {
  options: NgxDrpOptions;
  today = new Date();
  range: any;
  filterFormGroup: FormGroup;
  @ViewChild('pickerOne', /* TODO: add static flag */ {static:false}) pickerOne;
  @ViewChild('canvasGraph', /* TODO: add static flag */ {static:false}) canvasGraph;
  solutionList: any;
  empDailyHours = 8;
  chartType: string = 'line';
  projectList: any;
  employeeList: any;
  dataInput: any;
  dataOutput: any;
  dataNewlyAdded: any;
  dataTarget: any;
  role_id: any;
  rolePriviliges: any;
  userId: any;

  constructor(
    private httpClient: HttpClientService,
    private ss: SingletonService,
    private user: UserService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe
  ) {
    this.filterFormGroup = this.formBuilder.group({
      solutions: [''],
      projects: [''],
      employees: ['']
    });

  }
  public lineChartData = [
    { data: [], label: 'series A', hidden: true },
    { data: [], label: 'series B' },
    { data: [], label: 'series C' },
    { data: [], label: 'series D', hidden: true },
    { data: [], label: 'series E', hidden: true }
  ];

  public lineChartLabels: Label[] = [];
  public lineChartOptions = this.ss.lineChartOptions;
  public lineChartColors = this.ss.lineChartColors;
  public lineChartLegend = true;
  public lineChartType = 'line';

  public barChartColors = this.ss.barChartColors;

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
      scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
          },
              barPercentage: 0.5
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true
          }
          }],
      },legend: {
        labels: {
          fontSize: 14,
        }
      },
  };
  public barChartTypeSingle = 'bar';
  public barChartLabels = [];
  public barChartType = 'horizontalBar';
  public barChartLegend = true;
  public barChartData = [
    { data: [], label: 'Series A' , hidden: true},
    { data: [], label: 'Series B' },
    { data: [], label: 'Series C' },
    { data: [], label: 'Series D' , hidden: true },
    { data: [], label: 'Series E' , hidden: true }
  ];

  ngOnInit() {
    this.role_id = this.user.getRole();
    this.userId = this.user.getUserId();
    this.user.currentMessage.subscribe(val=>{
      this.rolePriviliges = val[0];
    });
    const today = new Date();
    const todayTo = new Date();
    let todayFrom = new Date();
    todayFrom = new Date(todayFrom.setDate(todayFrom.getDate() - 1));
    const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    this.ss.setupPresets();
    this.options = {
      presets: this.ss.presets,
      format: 'mediumDate',
      range: { fromDate: new Date(today.setDate(today.getDate() - 7)), toDate: new Date(todayTo.setDate(todayTo.getDate() - 1)) },
      applyLabel: 'Submit',
      // excludeWeekends:true,
      fromMinMax: { fromDate: fromMin, toDate: todayFrom },
      toMinMax: { fromDate: fromMin, toDate: todayFrom },
    };
    this.activeEmployees();
    this.activeSolutions();
    this.activeProjects();
  }

  ngAfterViewInit(){
    this.filterFormGroup.controls['solutions'].setValue(-1);
    this.filterFormGroup.controls['projects'].setValue(-1);
    this.filterFormGroup.controls['employees'].setValue(-1);
  }

  reset() {
    const today = new Date();
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date();
    this.pickerOne.resetDates({ fromDate: currMonthStart, toDate: currMonthEnd });
  }

  updateRange(range: Range) {
    this.range = range;
    this.range.fromDate = this.datepipe.transform(this.range.fromDate, 'yyyy-MM-dd');
    this.range.toDate = this.datepipe.transform(this.range.toDate, 'yyyy-MM-dd');
    this.activeSolutions();
  }

  activeSolutionsList(){
    this.httpClient.request('get', this.ss.getActiveSolutionList).subscribe((res: HttpResponse<any>) => {
      this.solutionList = res.body;
    });
  }

  activeSolutions() {
    this.httpClient.request('get', this.ss.getActiveSolutionList).subscribe((res: HttpResponse<any>) => {
      this.solutionList = res.body;
      this.filterSearch(this.filterFormGroup.value);
    });
  }

  activeProjects(){
    this.httpClient.request('get', this.ss.getActiveProjectList).subscribe((res: HttpResponse<any>) => {
      this.projectList = res.body;
    });
  }

  activeEmployees(){
    this.httpClient.request('get', this.ss.getActiveEmployeeList).subscribe((res: HttpResponse<any>) => {
      this.employeeList = res.body;
      if(this.role_id == 2){
        this.employeeList.forEach(element => {
          if(this.userId === element.emp_id){
            let output = [];
            output.push(element);
            this.employeeList = output;
            console.log(this.employeeList);
            
            console.log(this.employeeList[0].emp_id);
            this.filterFormGroup.controls['employees'].setValue(this.employeeList[0].emp_id);
          }
        });
        this.employeeWiseData(this.userId);
      }
    });
  }

  filterSearch(value){
    if(value.solutions == -1 ){
      value.solutions = '';
    }
    if(value.projects == -1 ){
      value.projects = '';
    }
    if(value.employees == -1 ){
      value.employees = '';
    }
    
    this.range.fromDate = this.datepipe.transform(this.range.fromDate, 'yyyy-MM-dd');
    this.range.toDate = this.datepipe.transform(this.range.toDate, 'yyyy-MM-dd');
    this.range.solutions = value.solutions;
    this.range.projects = value.projects;
    this.range.employees = value.employees;

    this.httpClient.request('get', this.ss.dayFilterDataInput, 'range=' + JSON.stringify(this.range)).subscribe((res: HttpResponse<any>) => {
      this.dataInput = res.body;
      console.log(this.dataInput);
      
      let dataInputtHolder = {};
      let dataOutputHolder = {};
      let dataDeleteHolder = {};
      let dataNewlyAddedHolder = {};
      let dataTargetHolder = {};

      this.dataInput.forEach(function (d) {
        if (dataInputtHolder.hasOwnProperty(d.actualdate)) {
          dataInputtHolder[d.actualdate] = dataInputtHolder[d.actualdate] + d.input_count;
        } else {
          dataInputtHolder[d.actualdate] = d.input_count;
        }
      });

      this.httpClient.request('get', this.ss.dayFilterDataOutput, 'range=' + JSON.stringify(this.range)).subscribe((res: HttpResponse<any>) => {
        this.dataOutput = res.body;
        console.log(this.dataOutput);

        this.dataOutput.forEach((d) => {
          if (dataOutputHolder.hasOwnProperty(d.actualdate)) {
            dataOutputHolder[d.actualdate] = dataOutputHolder[d.actualdate] + d.output_count;
          } else {
            dataOutputHolder[d.actualdate] = d.output_count;
          }
        });

        this.dataOutput.forEach((d) => {
          if (dataDeleteHolder.hasOwnProperty(d.actualdate)) {
            dataDeleteHolder[d.actualdate] = dataDeleteHolder[d.actualdate] + d.delete_count;
          } else {
            dataDeleteHolder[d.actualdate] = d.delete_count;
          }
        });

        this.httpClient.request('get', this.ss.dayFilterDataNewlyAdded, 'range=' + JSON.stringify(this.range)).subscribe((res: HttpResponse<any>) => {
          this.dataNewlyAdded = res.body;

          this.dataNewlyAdded.forEach((d) => {
            if (dataNewlyAddedHolder.hasOwnProperty(d.actualdate)) {
              dataNewlyAddedHolder[d.actualdate] = dataNewlyAddedHolder[d.actualdate] + d.diff;
            } else {
              dataNewlyAddedHolder[d.actualdate] = d.diff;
            }
          });

          this.httpClient.request('get', this.ss.dayFilterDataTarget, 'range=' + JSON.stringify(this.range)).subscribe((res: HttpResponse<any>) => {
            this.dataTarget = res.body;
            this.dataTarget.forEach(element => {
              element.totaltarget = element.target * element.emp_daily_hours;
            });
            this.dataTarget.forEach((d) => {
              if (dataTargetHolder.hasOwnProperty(d.actualdate)) {
                dataTargetHolder[d.actualdate] = dataTargetHolder[d.actualdate] + d.totaltarget;
              } else {
                dataTargetHolder[d.actualdate] = d.totaltarget;
              }
            });

            let dataInputObj = [];
            let dataOutputObj = [];
            let dataDeleteObj = [];
            let dataNewlyAddedObj = [];
            let dataTargetObj = [];

            for (var prop in dataInputtHolder) {
              dataInputObj.push({ proj_id: prop, input_count: dataInputtHolder[prop] });
            }

            for (var prop in dataOutputHolder) {
              dataOutputObj.push({ proj_id: prop, output_count: dataOutputHolder[prop] });
            }
            for (var prop in dataDeleteHolder) {
              dataDeleteObj.push({ proj_id: prop, delete_count: dataDeleteHolder[prop] });
            }
            for (var prop in dataNewlyAddedHolder) {
              dataNewlyAddedObj.push({ proj_id: prop, added: dataNewlyAddedHolder[prop] });
            }
            for (var prop in dataTargetHolder) {
              dataTargetObj.push({ proj_id: prop, target: dataTargetHolder[prop] });
            }
            console.log(dataDeleteObj);
            
            this.barChartData[0].data = [];
            this.barChartData[1].data = [];
            this.barChartData[2].data = [];
            this.barChartData[3].data = [];
            this.barChartData[4].data = [];
            this.barChartLabels = [];
            this.lineChartData[0].data = [];
            this.lineChartData[1].data = [];
            this.lineChartData[2].data = [];
            this.lineChartData[3].data = [];
            this.lineChartData[4].data = [];
            this.lineChartLabels = [];

            if (dataInputObj.length === 1 && dataOutputObj.length === 1 && dataNewlyAddedObj.length === 1) {
              this.chartType = 'bar';
              dataInputObj.forEach(elementInput => {
                dataOutputObj.forEach(elementOutput => {
                  dataDeleteObj.forEach(elementDelete => {
                  dataNewlyAddedObj.forEach(elementAdded => {
                    dataTargetObj.forEach(elementTarget => {
                      if ((elementInput.proj_id === elementOutput.proj_id) && (elementInput.proj_id === elementDelete.proj_id) && (elementInput.proj_id === elementAdded.proj_id) && (elementInput.proj_id === elementTarget.proj_id)) {
                        this.barChartData[0].label = 'Already Available';
                        this.barChartLabels.push(elementInput.proj_id);
                        this.barChartData[0].data.push(elementInput.input_count);
                        this.barChartData[1].label = 'Available';
                        this.barChartData[1].data.push(elementAdded.added + elementInput.input_count + elementDelete.delete_count);
                        this.barChartData[2].label = 'Completed';
                        this.barChartData[2].data.push(elementOutput.output_count);
                        this.barChartData[3].label = 'Newly Added';
                        this.barChartData[3].data.push(elementAdded.added);
                        this.barChartData[4].label = 'Capacity';
                        this.barChartData[4].data.push(elementTarget.target);
                      }
                    });
                  });
                });
              });
              });
              console.log(this.barChartData);

            }
            else {
              this.chartType = 'line';
              dataInputObj.forEach(elementInput => {
                dataOutputObj.forEach(elementOutput => {
                  dataDeleteObj.forEach(elementDelete => {
                  dataNewlyAddedObj.forEach(elementAdded => {
                    dataTargetObj.forEach(elementTarget => {
                      if ((elementInput.proj_id === elementOutput.proj_id) && (elementInput.proj_id === elementDelete.proj_id) && (elementInput.proj_id === elementAdded.proj_id) && (elementInput.proj_id === elementTarget.proj_id)) {
                        this.lineChartData[0].label = 'Already Available';
                        this.lineChartLabels.push(elementInput.proj_id);
                        this.lineChartData[0].data.push(elementInput.input_count);
                        this.lineChartData[1].label = 'Available';
                        this.lineChartData[1].data.push(elementAdded.added + elementInput.input_count + elementDelete.delete_count);
                        this.lineChartData[2].label = 'Completed';
                        this.lineChartData[2].data.push(elementOutput.output_count);
                        this.lineChartData[3].label = 'Newly Added';
                        this.lineChartData[3].data.push(elementAdded.added + elementDelete.delete_count);
                        this.lineChartData[4].label = 'Capacity';
                        this.lineChartData[4].data.push(elementTarget.target);
                      }
                    });
                  });
                });
              });
              });
            }

          });
        });
      });
    });
  }

  solutionWiseProjectList(sol_id?) {
    if(this.role_id === 2){
      this.userId = this.user.getUserId();
      
      this.httpClient.request('get', this.ss.getSolutionWiseProjectListRole, 'sol_id=' + sol_id + '&emp_id=' + this.userId).subscribe((res: HttpResponse<any>) => {
        this.projectList = res.body;
        console.log(this.projectList);
      });
      this.httpClient.request('get', this.ss.getSolutionWiseEmployeeList, 'sol_id=' + sol_id).subscribe((res: HttpResponse<any>) => {
        this.employeeList = res.body;
        if(this.role_id == 2){
          this.employeeList.forEach(element => {
            if(this.userId === element.emp_id){
              let output = [];
              output.push(element);
              this.employeeList = output;
              this.filterFormGroup.controls['employees'].setValue(this.employeeList[0].emp_id);
            }
          });
        }
      });
    }
else {
    
    if (sol_id != -1 && sol_id != '') {
      this.httpClient.request('get', this.ss.getSolutionWiseProjectList, 'sol_id=' + sol_id).subscribe((res: HttpResponse<any>) => {
        this.projectList = res.body;
      });
      this.httpClient.request('get', this.ss.getSolutionWiseEmployeeList, 'sol_id=' + sol_id).subscribe((res: HttpResponse<any>) => {
        this.employeeList = res.body;
        if(this.role_id == 2){
          this.employeeList.forEach(element => {
            if(this.userId === element.emp_id){
              let output = [];
              output.push(element);
              this.employeeList = output;
              this.filterFormGroup.controls['employees'].setValue(this.employeeList[0].emp_id);
            }
          });
        }
      });
    }
  }
    if(sol_id == -1){
      this.activeProjects();
      this.activeEmployees();
    }
  }

  projectWiseData(proj_id) {

    if (proj_id != -1 && proj_id != '') {
      this.httpClient.request('get', this.ss.getProjectWiseSolutionList, 'proj_id=' + proj_id).subscribe((res: HttpResponse<any>) => {
        this.solutionList = res.body;
      });
      this.httpClient.request('get', this.ss.getProjectWiseEmployeeList, 'proj_id=' + proj_id).subscribe((res: HttpResponse<any>) => {
        this.employeeList = res.body;
        if(this.role_id == 2){
          this.employeeList.forEach(element => {
            if(this.userId === element.emp_id){
              let output = [];
              output.push(element);
              this.employeeList = output;
              console.log(this.employeeList);
              
              console.log(this.employeeList[0].emp_id);
              this.filterFormGroup.controls['employees'].setValue(this.employeeList[0].emp_id);
            }
          });
        }
      });
    }
    if(proj_id == -1){
      this.activeSolutionsList();
      this.activeEmployees();
    }

  }

  employeeWiseData(emp_id) {
    
    if (emp_id != -1) {
      this.httpClient.request('get', this.ss.getemployeeWiseSolutionList, 'emp_id=' + emp_id).subscribe((res: HttpResponse<any>) => {
        this.solutionList = res.body;
      });
      this.httpClient.request('get', this.ss.getemployeeWiseProjectListDay, 'emp_id=' + emp_id).subscribe((res: HttpResponse<any>) => {
        this.projectList = res.body;
        console.log(this.projectList);
        
      });
    }
    if(emp_id == -1){
      this.activeProjects();
      this.activeSolutions();
    }
  }

  excelDownload(value){

    if(value.solutions == -1 ){
      value.solutions = null;
    }
    if(value.projects == -1 ){
      value.projects = null;
    }
    if(value.employees == -1 ){
      value.employees = null;
    }

    this.range.fromDate = this.datepipe.transform(this.range.fromDate, 'yyyy-MM-dd');
    this.range.toDate = this.datepipe.transform(this.range.toDate, 'yyyy-MM-dd');
    this.range.solutions = value.solutions;
    if(value.solutions != '' && value.solutions){
      this.solutionList.forEach(element => {
        if (element.sol_id === value.solutions) {
          this.range.sol_name = element.sol_name;
        }
      });
    }

    this.range.projects = value.projects;
    if(value.projets != '' && value.projects){
      this.projectList.forEach(element => {
        if (element.proj_id === value.projects) {
          this.range.proj_name = element.proj_name;
        }
      });
    }

    this.range.employees = value.employees;
    if(value.employees != '' && value.employees){
      this.employeeList.forEach(element => {
        if (element.emp_id === value.employees) {
          this.range.emp_name = element.emp_name;
        }
      });
    }
    
    return JSON.stringify(this.range);
  }

  exportPdf() {
    this.filterSearch(this.filterFormGroup.value);
    setTimeout(() => {
    let canvas = this.canvasGraph.nativeElement;
    var canvasImg = canvas.toDataURL("image/png", 1.0);

    //creates PDF from img
    var doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let sol_id = this.filterFormGroup.controls.solutions.value;
    let proj_id = this.filterFormGroup.controls.projects.value;
    let emp_id = this.filterFormGroup.controls.employees.value;
    let sol_name;
    let proj_name;
    let emp_name;

    if(sol_id == -1){
      sol_name = 'All';
    }
    if(proj_id == -1){
      proj_name= 'All';
    }
    if(emp_id == -1){
      emp_name = 'All';
    }
    if (sol_id && proj_id && emp_id) {
      this.solutionList.forEach(element => {
        if (element.sol_id === sol_id) {
          sol_name = element.sol_name;
        }
      });
      this.projectList.forEach(element => {
        if (element.proj_id === proj_id) {
          proj_name = element.proj_name;
        }
      });
      this.employeeList.forEach(element => {
        if (element.emp_id === emp_id) {
          emp_name = element.emp_name;
        }
      });
      doc.text(100, 15, "Solution: " + sol_name + " Project: " + proj_name + " Employee: " + emp_name);
    }
    else if (sol_id && proj_id) {
      this.solutionList.forEach(element => {
        if (element.sol_id === sol_id) {
          sol_name = element.sol_name;
        }
      });
      this.projectList.forEach(element => {
        if (element.proj_id === proj_id) {
          proj_name = element.proj_name;
        }
      });

      doc.text(150, 15, "Solution: " + sol_name + " Project: " + proj_name);
    }
    else if (sol_id) {
      this.solutionList.forEach(element => {
        if (element.sol_id === sol_id) {
          sol_name = element.sol_name;
        }
      });
      doc.text(150, 15, "Solution: " + sol_name);
    }

    doc.text(15, 15, "Day Chart: " + this.range.fromDate + " - " + this.range.toDate);
    doc.addImage(canvasImg, 'JPEG', 20, 20, 250, 125);
    doc.save('canvas.pdf');
  }, 2000);
  }

}
