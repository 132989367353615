import { Component, OnInit, ViewChild } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { VERSION } from '@angular/material/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SingletonService } from 'src/app/services/singleton.service';
import { HttpClientService } from 'src/app/services/http-client.service';
import { HttpResponse } from '@angular/common/http';
import { identifierModuleUrl } from '@angular/compiler';
import { UserService } from 'src/app/services/user.service';
import { ThemeService } from 'ng2-charts';


@Component({
  selector: 'app-data-labler',
  templateUrl: './data-labler.component.html',
  styleUrls: ['./data-labler.component.scss']
})
export class DataLablerComponent implements OnInit {
  formGroup: FormGroup;
  @ViewChild('addEmployeeRef', /* TODO: add static flag */ {static:false}) myNgForm;
  roleList: any;
  role_id: any;
  rolePriviliges: string;
  changeRoleId: any;
  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClientService,
    private ss: SingletonService,
    private user: UserService
  ) {
    this.formGroup = this.formBuilder.group({
      'name': ['', Validators.required],
      'emp_no': ['', Validators.required],
      'role_id': ['', Validators.required],
      'ip_address': [''],
      'username': [''],
      'password': ['', Validators.required]
    })
  }

  ngOnInit() {
    this.role_id = this.user.getRole();
    this.user.getUserId();
    this.user.currentMessage.subscribe(val => {
      this.rolePriviliges = val[0];
    });
    let version = VERSION;
    this.getRoleList();
  }

  getRoleList() {
    this.httpClient.request('get', this.ss.getRoles).subscribe((res: HttpResponse<any>) => {
      this.roleList = res.body;
      console.log(this.roleList);
    });
  }

  changeRole(role_id) {
    console.log(role_id);
    this.changeRoleId = role_id;
    if (this.changeRoleId === 2) {
      this.formGroup.controls['ip_address'].setValidators([Validators.required]);
      this.formGroup.controls['username'].setValidators([Validators.required]);
    }
    else {
      this.formGroup.controls['ip_address'].clearValidators();
      this.formGroup.controls['ip_address'].updateValueAndValidity();
      this.formGroup.controls['username'].clearValidators();
      this.formGroup.controls['username'].updateValueAndValidity()
    }
  }

  addEmployee(value) {
    console.log(value);
    if (value.role_id === 2) {
      if (this.formGroup.valid) {
        var formData = new FormData();
        formData = value;
        console.log(value)

        this.httpClient.request('post', this.ss.addEmployee, '', formData).subscribe((res: any) => {
          if (res.status === 200) {
            if (res.error.text === "Employee added successfully") {
              this.ss.statusMessage.showStatusMessage(true, 'Employee added successfully');
            }
            else if (res.error.text === "Employee already exist") {
              this.ss.statusMessage.showStatusMessage(false, 'Employee already exist');
            }
            this.myNgForm.resetForm();
          }
          else {
            this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
          }
        });
      }
    }
    else {
      if (this.formGroup.valid) {
        var formData = new FormData();
        formData = value;
        console.log(value)

        this.httpClient.request('post', this.ss.addEmployeeRole, '', formData).subscribe((res: any) => {
          if (res.status === 200) {
            if (res.error.text === "Employee added successfully") {
              this.ss.statusMessage.showStatusMessage(true, 'Employee added successfully');
            }
            else if (res.error.text === "Employee already exist") {
              this.ss.statusMessage.showStatusMessage(false, 'Employee already exist');
            }
            this.myNgForm.resetForm();
          }
          else {
            this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
          }
        });
      }
    }

  }

}
