import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PieChartComponent implements OnInit {

  @Input() data = [{ "label": "emp1", "data": 400 }, { "label": "emp1", "data": 600 }, { "label": "emp1", "data": 800 }, { "label": "emp1", "data": 900 }];

  @Input() width = 250;

  @Input() height = 250;

  @Input() type = 'pie';

  @ViewChild("piechart", /* TODO: add static flag */   {static:false}) elementRef: ElementRef;

  radius = Math.min(this.width, this.height) / 2;

  @Input() colors = ["#4682b4", "#5cb85c", "blue", "green"];


  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      
      this.drawPieChart();
    }, 1000);
    
  }

  drawPieChart() {
    let color: any = d3.scaleOrdinal().range(this.colors);
    let arc;
    if (this.type == "donut") {
      arc = d3.arc()
        .outerRadius(this.radius)
        .innerRadius(50);
    }
    else {
      arc = d3.arc()
        .outerRadius(this.radius - 10)
        .innerRadius(0);
    }
    let labelArc = d3.arc()
      .outerRadius(this.radius)
      .innerRadius(this.radius - 80);


    let pie = d3.pie()
      .value(function (d: any) { return d.data; });

    let svg = d3.select(this.elementRef.nativeElement).append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .data([this.data])
      .attr("transform", "translate(" + this.width / 2 + "," + this.height / 2 + ")");

    // TOOLTIP
    var tooltip = d3.select(this.elementRef.nativeElement) // select element in the DOM with id 'chart'
      .append('div') // append a div element to the element we've selected                                    
      .attr('class', 'tooltip'); // add class 'tooltip' on the divs we just selected
    tooltip.append('div') // add divs to the tooltip defined above
      .attr('class', 'label'); // add class 'label' on the selection
    tooltip.append('div') // add divs to the tooltip defined above   
      .attr('class', 'count'); // add class 'count' on the selection

    //this selects all <g> elements with class slice (there aren't any yet)
    var arcs = svg.selectAll("g.slice")
      .data(<any>pie)
      .enter()
      .append("svg:g")

      //allow us to style things in the slices (like text)
      .attr("class", "slice");

    // mouse event handlers are attached to path so they need to come after its definition
    arcs.on('mouseover', (d: any) => {  // when mouse enters div      

      tooltip.select('.label').html(d.data.label); // set current label           
      tooltip.select('.count').html(d.data.data); // set current count                  
      tooltip.style('display', 'block'); // set display                     
    });

    arcs.on('mouseout', () => { // when mouse leaves div                        
      tooltip.style('display', 'none'); // hide tooltip for that element
    });

    arcs.on('mousemove', (d) => { // when mouse moves                  
      tooltip.style('top', (d3.event.layerY + 10) + 'px') // always 10px below the cursor
        .style('left', (d3.event.layerX + 10) + 'px'); // always 10px to the right of the mouse
    });



    arcs.append("svg:path")
      //set the color for each slice to be chosen from the color function defined above
      .attr("fill", (d: any, i) => { return color(i); })
      //this creates the actual SVG path using the associated data (pie) with the arc drawing function
      .attr("d", arc);

    //add a label to each slice
    arcs.append("svg:text")
      //set the label's origin to the center of the arc
      .attr("transform", (d: any) => {
        //we have to make sure to set these before calling arc.centroid
        d.innerRadius = 0;
        //this gives us a pair of coordinates like [50, 50]
        return "translate(" + arc.centroid(d) + ")";
      })
      //center the text on it's origin
      .attr("text-anchor", "middle")
      .text((d: any) => { return d.data.data; })
      .style('fill', '#fff');


    // legend dimensions
    var legendRectSize = 20; // defines the size of the colored squares in legend
    var legendSpacing = 6; // defines spacing between squares

    // define legend
    var legend = svg.selectAll('.legend') // selecting elements with class 'legend'
      .data(color.domain()) // refers to an array of labels from our dataset
      .enter() // creates placeholder
      .append('g') // replace placeholders with g elements
      .attr('class', 'legend') // each g is given a legend class
      .attr('transform', function (d, i) {
        var height = legendRectSize + legendSpacing; // height of element is the height of the colored square plus the spacing      
        var offset = height * color.domain().length / 2; // vertical offset of the entire legend = height of a single element & half the total number of elements  
        var horz = 7 * legendRectSize; // the legend is shifted to the left to make room for the text
        var vert = i * height - offset; // the top of the element is hifted up or down from the center using the offset defiend earlier and the index of the current element 'i'               
        return 'translate(' + horz + ',' + vert + ')'; //return translation       
      });
      let newData = this.data;

      newData.forEach((d:any)=> {
        d.data = +d.data; // calculate count as we iterate through the data
        d.enabled = true; // add enabled property to track which entries are checked
      });
    // adding colored squares to legend
    legend.append('rect') // append rectangle squares to legend                                   
      .attr('width', legendRectSize) // width of rect size is defined above                        
      .attr('height', legendRectSize) // height of rect size is defined above                      
      .style('fill', color) // each fill is passed a color
      .style('stroke', color) // each stroke is passed a color
      .on('click', function (label) {
        var rect = d3.select(this); // this refers to the colored squared just clicked
        var enabled = true; // set enabled true to default
        var totalEnabled = d3.sum(newData.map((d:any)=> { // can't disable all options
          return (d.enabled) ? 1 : 0; // return 1 for each enabled entry. and summing it up
        }));
        if (rect.attr('class') === 'disabled') { // if class is disabled
          rect.attr('class', ''); // remove class disabled
        } else { // else
          if (totalEnabled < 2) return; // if less than two labels are flagged, exit
          rect.attr('class', 'disabled'); // otherwise flag the square disabled
          enabled = false; // set enabled to false
        }

        pie.value( (d:any)=> {
          if (d.label === label) d.enabled = enabled; // if entry label matches legend label
          return (d.enabled) ? d.data : 0; // update enabled property and return count or 0 based on the entry's status
        });

        arcs = arcs.data(pie(<any>newData)); // update pie with new data

        // arcs.transition() // transition of redrawn pie
        //   .duration(750) // 
        //   .attrTween('d', (d)=> { // 'd' specifies the d attribute that we'll be animating
        //     var interpolate = d3.interpolate(this._current, d); // this = current path element
        //     this._current = interpolate(0); // interpolate between current value and the new value of 'd'
        //     return function (t) {
        //       return arc(interpolate(t));
        //     };
        //   });

        // calculate new total
        var newTotalCalc = d3.sum(newData.filter((d:any)=> { return d.enabled; }), (d:any) => d.data)
        // console.log(newTotalCalc);

        // append newTotalCalc to newTotal which is defined above
        // newTotal.text(newTotalCalc);
      });

    // adding text to legend
    legend.append('text')
      .attr('x', legendRectSize + legendSpacing)
      .attr('y', legendRectSize - legendSpacing)
      .text( (d:any)=> {return ((newData[d].label)); }); // return label

  }
}