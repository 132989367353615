import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import * as d3 from 'd3';
import * as _ from 'lodash';

var dateMonthRef = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

interface sides {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
}

interface CandleStickSettings {
    width?: number;
    height?: number;
    color?: string;
    padding?: sides;
    caption?: string;
    tooltip?: true;
}

@Component({
    selector: 'dbd-candle-stick',
    templateUrl: './candle-stick.component.html',
    styleUrls: ['./candle-stick.component.scss']
})
export class CandleStickComponent implements OnInit {
    svgWidth: number;
    svgHeight: number;
    
    @Input() idd: string = "";

    @Input() width: number = 750;

    @Input() height: number = 300;

    @Input() settings: CandleStickSettings = {};

    defaultSettings: CandleStickSettings = {
        height: 300,
        width: 750,
        tooltip: true,
        color: '#000',
        caption: "",
        padding: { left: 40, top: 20, right: 0, bottom:0  },
    }

    @ViewChild('candleStickWrap', /* TODO: add static flag */   {static:false}) wrapperElement: ElementRef;

    svgViewPort: d3.Selection<any, any, any, any>;

    toolTip: d3.Selection<any, any, any, any>;

    data: Array<any> = [10, 20, 50, 100, 200, 220, 240, 270, 320, 400, 500, 540, 600];

    svgWrapperRectangle: d3.Selection<any, any, any, any>;

    axes: { x?: d3.Axis<any>, y?: d3.Axis<any> } = {};

    axesElements: { x?: any, y?: any } = {};

    axesScale: { x?: d3.AxisScale<any>, y?: d3.AxisScale<any> } = {};

    axesScaleOriginal: { x?: d3.AxisScale<any>, y?: d3.AxisScale<any> } = {};

    scale: { x?: d3.AxisScale<any>, y?: d3.AxisScale<any> };

    zoomScale: { x?: d3.ZoomScale, y?: d3.ZoomScale } = {};

    get currentZoom() {
        return d3.zoomTransform(this.svgViewPort.node()).k;
    }

    zoom: d3.ZoomBehavior<any, any>;

    drag: d3.ZoomBehavior<any, any>;

    defs: d3.Selection<any, any, any, any>;

    clipPath: d3.Selection<any, any, any, any>;

    constructor(
        private httpClient: HttpClient,
        private renderer: Renderer2,
        private el: ElementRef
    ) {
    }

    ngOnInit() {
        // this.renderer.setStyle(this.wrapperElement.nativeElement, 'padding-left', '20px');
    }

    ngOnChanges() {
        this.settings = _.merge(this.defaultSettings, this.settings);
        this.width = this.settings.width;
        this.height = this.settings.height;
    }

    dateArray: Array<any>;

    getAxesOriginal() {

        this.axesScaleOriginal = this.axesScaleOriginal || {};

        this.axesScaleOriginal.y = d3.scaleLinear()
            .domain(this.domainY)
            .range(this.rangeY);

        this.axesScaleOriginal.x = d3.scaleLinear()
            .domain(this.domainX)
            .range(this.rangeX);

        return this.axesScaleOriginal;

    }

    positionToolTip(d, i, node, e: MouseEvent) {

        this.toolTip = this.svgViewPort.append('g')
            .classed('.tool-tip', true)
            .attr('transform', 'translate(' + (this.axesScale.x(this.dateArray[i] ) + 10) + ', ' + (e.pageY - this.svgViewPort.node().getBoundingClientRect().top - this.settings.padding.top) + ')');

        this.toolTip.append('rect')
            .attr('width', 100)
            .attr('height', 100)
            .attr('fill', '#000')
            .attr('ry', 10);

        this.toolTip.append('text')
            .html('<tspan x="10" y="25">Min : ' + d.min + '</tspan><tspan x="10" y="50">Max : ' + d.max + '</tspan><tspan x="10" y="75">Avg : ' + d.avg + '</tspan>');

    }

    get domainY() {
        var minY = d3.min(this.data, function(d) {
		    return d["min"];
        });
        
        var maxY = d3.max(this.data, function(d) {
  		    return d["max"];
        });
      
        console.log([minY, maxY]);
        return [minY, maxY];
    }

    get domainX() {
        // return [(Math.min(...this.dateArray) * 1000 - 86400000), (Math.max(...this.dateArray) * 1000 + 86400000)];

        var minX = d3.min(this.dateArray, (d) => {
            return d;
        });
        var maxX = d3.max(this.dateArray, (d) => {
                return d;
        });

        return [minX, maxX]
    }

    get zoomScaleExtent() {
        var minDate = d3.min(this.dateArray, (d) => {
            return d;
        });
        var maxDate = d3.max(this.dateArray, (d) => {
                return d;
        });

        var zoomMin = 1;
        var diff = d3.timeDay.count(new Date(minDate) , new Date(maxDate));
        var zoomMax = diff < 1 ? 2
          : diff < 7 ? 10
          : diff < 31 ? 20
          : 50;

        console.log([zoomMin, zoomMax]);
        return [zoomMin, zoomMax];
    }

    get rangeX() {
        return [0, this.svgWidth];
    }

    get rangeY() {
        return [this.svgHeight, 0];
    }

    ngAfterContentInit() {

        this.zoom = d3.zoom();


        this.width = this.wrapperElement.nativeElement.clientWidth;
        

        this.svgWidth = this.width - this.settings.padding.left - this.settings.padding.right;
        this.svgHeight = this.height - this.settings.padding.bottom - this.settings.padding.top;
            
        this.svgViewPort = d3.select(this.wrapperElement.nativeElement)
            .append('svg')
            // .attr('transform', 'translate(' + this.settings.padding.left + ', ' + this.settings.padding.top + ')')            
            .attr("height", this.height)
            .attr("width", this.width)
            .attr('viewBox', '-' + this.settings.padding.left + ' -' + this.settings.padding.right + ' ' + this.width + ' ' + this.height);
            
      

        this.defs = this.svgViewPort.append('defs');

        this.clipPath = this.defs.append('clipPath')
            .attr('id', 'clip');

        this.clipPath.append('rect')
            .attr('width', this.svgWidth )
            .attr('height', this.svgHeight)
            .attr('x', 0)
            .attr('y', 0);


        this.svgWrapperRectangle = this.svgViewPort.append('g')
            .attr('clip-path', 'url(#clip)');

        // this.axesScaleOriginal.y = d3.scaleLinear()
        //     .domain(this.domainY)
        //     .range(this.rangeY);

        // this.axesScale.y = this.axesScaleOriginal.y.copy();

        // this.axes.y = d3.axisLeft(this.axesScale.y);

        // // add the y axis 
        // this.axesElements.y = this.svgViewPort.append("g")
        //     .classed('y-axis', true)
        //     .call(this.axes.y);

        this.httpClient.get('/assets/demo/temp.json').subscribe((res: Array<any>) => {

            this.data = res;

           
            this.dateArray = _.map(res, (obj) => {
                // // let splitDate = obj['timestamp'].split(' ');
                // // let datum = new Date(splitDate[2], dateMonthRef.indexOf(splitDate[0]), splitDate[1], 1, 0, 0);
                // let datum = new Date(obj['timestamp'])
                // return datum.getTime() / 1000;
             
                let datum = new Date(obj['timestamp'])
                return datum;
            })

            let scaleExtent = this.zoomScaleExtent;
            this.zoom
            .scaleExtent([scaleExtent[0], scaleExtent[1]])
            .translateExtent([[0, 0], [this.width, this.height]])
            .extent([[0, 0], [this.width, this.height]])
            .on('zoom', () => {
                    this.zoomFunction();
                });
                this.svgViewPort.call(this.zoom);

            // var minDate = _.da
            this.axesScaleOriginal.x = d3.scaleTime()
                .domain(this.domainX)
                .range(this.rangeX);

            this.axesScale.x = this.axesScaleOriginal.x.copy();

            this.axes.x = d3.axisBottom(this.axesScale.x);

            this.axesScaleOriginal.y = d3.scaleLinear()
            .domain(this.domainY)
            .range(this.rangeY);

            this.axesScale.y = this.axesScaleOriginal.y.copy();
    
            this.axes.y = d3.axisLeft(this.axesScale.y);

            // set the string format of the display string on x axis
            this.axes.x.tickFormat(this.multiFormat);

            // add the x axis
            this.axesElements.x = this.svgViewPort.append("g")
                .classed('x-axis', true)
                .attr("transform", "translate(0, " + (this.svgHeight) + ")")
                .call(this.axes.x);

                // add the y axis 
            this.axesElements.y = this.svgViewPort.append("g")
            .classed('y-axis', true)
            
            .call(this.axes.y);

            var groups = this.svgWrapperRectangle.selectAll(".mma-group")
                .data(res).enter()
                .append('g')
                .style('overflow', 'hidden')
                .attr('class', 'mma-group');


            groups.on('mouseenter', (d, i, node) => {

                // console.log(this.axesScale.x(this.dateArray[i] * 1000));
                this.positionToolTip(d, i, node, d3.event);

            });

            groups.on('mouseleave', (d, i, node) => {
                this.toolTip.remove();
            });


            var scaleMultiplier = this.currentZoom;

            groups.append("circle")
                .classed('min', true)
                .attr("cx", (d, i) => {
                    return this.axesScale.x(this.dateArray[i]);
                })
                .attr("cy", (d, i) => {
                    return this.axesScale.y(d.min);
                })
                .attr("r", "3px")
                .attr("fill", "#444");

            groups.append("circle")
                .classed('max', true)
                .attr("cx", (d, i) => {
                    return this.axesScale.x(this.dateArray[i] );
                })
                .attr("cy", (d, i) => {
                    return this.axesScale.y(d.max);
                })
                .attr("r",  "3px")
                .attr("fill", "#444");

            groups.append("circle")
                .classed('avg', true)
                .attr("cx", (d, i) => {
                    return this.axesScale.x(this.dateArray[i]);
                })
                .attr("cy", (d, i) => {
                    return this.axesScale.y(d.avg);
                })
                .attr("r", "5px")
                .attr("fill", "#444");

            groups.append("line")
                .classed('mma-line', true)
                .attr("x1", (d, i) => {
                    return this.axesScale.x(this.dateArray[i] );
                })
                .attr("x2", (d, i) => {
                    return this.axesScale.x(this.dateArray[i]);
                })
                .attr("y1", (d, i) => {
                    return this.axesScale.y(d.max);
                })
                .attr("y2", (d, i) => {
                    return this.axesScale.y(d.min);
                }).attr("stroke-width",  "2px")
                .attr("stroke", "#444");

            this.svgWrapperRectangle.append("path")
                .classed('avg-line', true)
                .datum(res)
                .attr("d", this.getPath())
                .attr("fill", "none")
                .attr("stroke-width", "2px")
                .attr('stroke-dasharray', '1, 2')
                .attr("stroke", "#444");
        });


    }




    zoomFunction() {

        var that = this;

        var transition = d3.transition().duration(750);

        this.axesScale.x = d3.event.transform.rescaleX(this.axesScaleOriginal.x);
        this.axesScale.y = d3.event.transform.rescaleY(this.axesScaleOriginal.y);

        this.axesElements.x.transition(transition).call(this.axes.x.scale(this.axesScale.x));
        this.axesElements.y.transition(transition).call(this.axes.y.scale(this.axesScale.y));

        this.axesElements.x.transition(transition).call(this.axes.x);
        this.axesElements.y.transition(transition).call(this.axes.y);

        // g.select(".axis--y").transition(t).call(yAxis);


        var scaleMultiplier = this.currentZoom;

        var zoomEvent: d3.D3ZoomEvent<any, any> = d3.event;

        var groups = this.svgViewPort.selectAll('.mma-group');

        groups.select('.min')
            .transition(transition)
            .attr("cx", (d, i) => {
                return this.axesScale.x(this.dateArray[i]);
            })
            .attr("cy", (d: any, i) => {
                return this.axesScale.y(d.min);
            })
            .attr("r", "3px");

        groups.select('.max')
            .transition(transition)
            .attr("cx", (d, i) => {
                return this.axesScale.x(this.dateArray[i]);
            })
            .attr("cy", (d: any, i) => {
                return this.axesScale.y(d.max);
            })
            .attr("r", "3px");

        groups.select('.avg')
            .transition(transition)
            .attr("cx", (d, i) => {
                return this.axesScale.x(this.dateArray[i]);
            })
            .attr("cy", (d: any, i) => {
                return this.axesScale.y(d.avg);
            })
            .attr("r", "5px")

        groups.select('.mma-line')
            .transition(transition)
            .attr("x1", (d, i) => {
                return this.axesScale.x(this.dateArray[i]);
            })
            .attr("x2", (d, i) => {
                return this.axesScale.x(this.dateArray[i]);
            })
            .attr("y1", (d: any, i) => {
                return this.axesScale.y(d.max);
            })
            .attr("y2", (d: any, i) => {
                return this.axesScale.y(d.min);
            })
            .attr("stroke-width",  "2px");

        this.svgViewPort.selectAll('.avg-line')
            .transition(transition)
            .attr("d", this.getPath());


    }

    getPath() {
        return d3.line()
            .x((d, i) => {
                return  this.axesScale.x(this.dateArray[i] );
              })
            .y((d, i) => {
                return this.axesScale.y(d['avg']);
            })
            .curve(d3.curveCatmullRom.alpha(0.8));
    }

    // Define filter conditions
    multiFormat(date) {
        var formatMinute = d3.timeFormat("%I:%M %p"),
        formatHour = d3.timeFormat("%I %p"),
        formatDay = d3.timeFormat("%a %d"),
        formatWeek = d3.timeFormat("%b %d"),
        formatMonth = d3.timeFormat("%B"),
        formatYear = d3.timeFormat("%Y");

        return (d3.timeHour(date) < date ? formatMinute
          : d3.timeDay(date) < date ? formatHour
          : d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
          : d3.timeYear(date) < date ? formatMonth
          : formatYear)(date);
  }

}
