import { Component, OnInit, ViewChild } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { HttpClientService } from 'src/app/services/http-client.service';
import { HttpResponse } from '@angular/common/http';
import { SingletonService } from 'src/app/services/singleton.service';
import { NgxDrpOptions } from 'ngx-mat-daterange-picker';
import { DatePipe } from '@angular/common';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';
import * as moment from 'moment';
import { UserService } from 'src/app/services/user.service';
import { NgModule }      from '@angular/core';
import * as _ from 'lodash';
// import moment = require('moment');

@Component({
  selector: 'app-utilization-report',
  templateUrl: './utilization-report.component.html',
  styleUrls: ['./utilization-report.component.scss']
})
export class UtilizationReportComponent implements OnInit {

  @ViewChild('pickerOne', /* TODO: add static flag */ {static:false}) pickerOne;
  options: NgxDrpOptions;
  today = new Date();
  range: any;
  todo = [
    {'id':1,'name':'Get to work'},
    {'id':2,'name':'Pick up groceries'},
    {'id':3, 'name':'Go home'},
    {'id':4,'name':'Fall asleep'}
  ];
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartColors = this.ss.pieChartColors;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];

  done = [];
  activeEmployeeList: any;
  filterData: any;
  idleDays: number;
  utilizationDays: any;
  bussinessDays: number;
  storeEmpList: any;
  employeesSelected: any;
  rolePriviliges: string;

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      console.log(event.container.data[0]);
      transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
      console.log(this.done);
      this.done.sort((a,b) =>{
        var nameA = a.emp_name.toLowerCase();
              var nameB = b.emp_name.toLowerCase();
              if (nameA < nameB) //sort string ascending
                  return -1
      });
      
      let empIds:any = event.container.data; 
      
      console.log(this.activeEmployeeList);

      empIds.forEach(element=>{
        if(element.emp_id == 1){
          this.done.forEach(element1=>{ 
            if(element1.emp_id !=1){
              this.activeEmployeeList.push(element1);
            }
          })
          this.done = [];
          this.done.push(element);
          console.log(this.activeEmployeeList);
        }
        
      });
      
      const found = this.done.find(item => item.emp_id === 1);

      console.log(found);
      
      
      this.activeEmployeeList.forEach(element3 => {
        empIds.forEach(element4 => {
          if(!found){
          if(element3.emp_id === element4.emp_id){
            this.activeEmployeeList.forEach( (item, index) => {
              if(item.emp_id === element4.emp_id) this.activeEmployeeList.splice(index,1);
            });
            console.log(this.activeEmployeeList);
          }
        }
        });
      });
    }
    
  }

  constructor(
    private httpClient: HttpClientService,
    private ss : SingletonService,
    private user : UserService,
    public datepipe: DatePipe
  ) { }

  ngOnInit() {
    const today = new Date();
    const todayTo = new Date();
    let todayFrom = new Date();
    todayFrom = new Date(todayFrom.setDate(todayFrom.getDate() - 1));
    const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    this.ss.setupPresets();
    this.options = {
      presets: this.ss.presets,
      format: 'mediumDate',
      range: { fromDate: new Date(today.setDate(today.getDate() - 7)), toDate: new Date(todayTo.setDate(todayTo.getDate() - 1)) },
      applyLabel: 'Submit',
      // excludeWeekends:true,
      fromMinMax: { fromDate: fromMin, toDate: todayFrom },
      toMinMax: { fromDate: fromMin, toDate: todayFrom },
    };
    this.user.currentMessage.subscribe(val => {
      this.rolePriviliges = val[0];
    });
    this.getActiveEmployeeList();
  }
  reset() {
    const today = new Date();
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date();
    this.pickerOne.resetDates({ fromDate: currMonthStart, toDate: currMonthEnd });
  }

  updateRange(range: Range) {
    this.range = range;
    this.range.fromDate = this.datepipe.transform(this.range.fromDate, 'yyyy-MM-dd');
    this.range.toDate = this.datepipe.transform(this.range.toDate, 'yyyy-MM-dd');
  }

  getActiveEmployeeList(){
    this.httpClient.request('get', this.ss.getActiveEmployeeList).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.activeEmployeeList = res.body;
        this.storeEmpList = res.body;
        this.activeEmployeeList.push({emp_id :1,emp_name:'All'})
        this.activeEmployeeList.sort((a,b) =>{
          var nameA = a.emp_name.toLowerCase();
                var nameB = b.emp_name.toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1
        });
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  removeItem(val){
    this.done = this.done.filter( h => h.emp_id !== val.emp_id);
    this.activeEmployeeList.push(val);
    this.activeEmployeeList.sort((a,b) =>{
      var nameA = a.emp_name.toLowerCase();
            var nameB = b.emp_name.toLowerCase();
            if (nameA < nameB) //sort string ascending
                return -1
    });
  }

 calcBusinessDays(startDate, endDate) { 
  var day = moment(startDate);
  var businessDays = 0;

  while (day.isSameOrBefore(endDate,'day')) {
    if (day.day()!= 0 && day.day()!= 6) businessDays++;
    day.add(1,'d');
  }
  return businessDays;
}

  getUtilizationReport(values){
    
      this.range.fromDate = this.datepipe.transform(this.range.fromDate, 'yyyy-MM-dd');
      this.range.toDate = this.datepipe.transform(this.range.toDate, 'yyyy-MM-dd');
      this.range.empid = [];
      values.forEach(element => {
        if(element.emp_id != 1){
        this.range.empid.push({'id':element.emp_id});
        this.employeesSelected = values.length;
      }
      else{
        this.storeEmpList.forEach(element => {
          this.range.empid.push({'id':element.emp_id});
          this.employeesSelected = this.storeEmpList.length;
        });
      }
      });
      console.log(this.range);
      this.httpClient.request('get', this.ss.filterForUtilization, 'range=' + JSON.stringify(this.range)).subscribe((res: HttpResponse<any>) => {
        this.filterData = res.body;
        console.log(this.filterData);
         this.idleDays = 0;
         this.utilizationDays = 0; 
        this.filterData.forEach(element => {
          if(element.proj_id == 50){
            this.idleDays += 1;
          }
          else{
            this.utilizationDays += 1;
          }
        });
        
        this.bussinessDays = this.calcBusinessDays(this.range.fromDate,this.range.toDate);
        this.pieChartData = [];
        this.pieChartLabels = [];
        let idleDays:any = ((this.idleDays * 100) / this.filterData.length).toFixed(2);
        this.pieChartData.push(idleDays);
        this.pieChartLabels.push('Idle %');
        let utilDays:any = ((this.utilizationDays * 100) / this.filterData.length).toFixed(2);
        this.pieChartData.push(utilDays);
        this.pieChartLabels.push('Project %');
      })
    
  }

}
