import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { MatInputModule } from '@angular/material/input';
import { HttpResponse } from '@angular/common/http';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-labeler-list',
  templateUrl: './labeler-list.component.html',
  styleUrls: ['./labeler-list.component.scss']
})
export class LabelerListComponent implements OnInit {

  @ViewChild(ModalPopupComponent, /* TODO: add static flag */ {static:false}) modal;
  EditFormGroup: FormGroup;
  active = new FormControl('');
  employeeList: any;
  totalOutput: any;
  dataOutputObj: any[];
  totalInput: any;
  totalNewlyAdded: any;
  dataInputObj: any[];
  dataNewlyAddedObj: any[];
  totalAvailable: any[];
  dataDeleteObj: any[];
  roleList: any;
  role_id: any;
  rolePriviliges: string;
  changeRoleId: any;

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClientService,
    private ss: SingletonService,
    private user: UserService,
    public dialog: MatDialog
  ) {
    this.EditFormGroup = this.formBuilder.group({
      'id': ['', Validators.required],
      'name': ['', Validators.required],
      'role_id': ['', Validators.required],
      'emp_no': ['', Validators.required],
      'ip_address': ['',Validators.required],
      'username': ['',Validators.required],
      'password': ['', Validators.required]
    });
  }

  ngOnInit() {
    this.role_id = this.user.getRole();
    this.user.getUserId();
    this.user.currentMessage.subscribe(val=>{
      this.rolePriviliges = val[0];
    });
    this.getEmployeeDetails();
    this.getTotalInput();
    this.getTotalOutput();
    this.getRoleList();
    
  }

  getRoleList(){
    this.httpClient.request('get', this.ss.getRoles).subscribe((res: HttpResponse<any>) => {
      this.roleList = res.body;
      console.log(this.roleList);
      this.employeeList.forEach(element => {
        this.roleList.forEach(element1 => {
        if(element.role_id == element1.role_id){
          element.role_name = element1.role_name
        }
      });
      });
    });
  }
  
  getEmployeeDetails() {
    this.httpClient.request('get', this.ss.getEmployeeList).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        this.employeeList = res.body;
        
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
    console.log(this.active.value);
  }

  getTotalInput(){
    this.httpClient.request('get', this.ss.dayDataInputTotal).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.totalInput = res.body;
        let dataInputHolder = {};
        
          this.totalInput.forEach((d) => {
            if (dataInputHolder.hasOwnProperty(d.emp_id)) {
              dataInputHolder[d.emp_id] = dataInputHolder[d.emp_id] + d.input_count;
            } else {
              dataInputHolder[d.emp_id] = d.input_count;
            }
          });

          this.httpClient.request('get', this.ss.dayDataNewlyAddedTotal).subscribe((res: HttpResponse<any>) => {
            if (res.status === 200) {
              this.totalNewlyAdded = res.body;

        let dataNewlyAddedHolder = {};
        
          this.totalNewlyAdded.forEach((d) => {
            if (dataNewlyAddedHolder.hasOwnProperty(d.emp_id)) {
              dataNewlyAddedHolder[d.emp_id] = dataNewlyAddedHolder[d.emp_id] + d.diff;
            } else {
              dataNewlyAddedHolder[d.emp_id] = d.diff;
            }
          });
       
        this.dataInputObj = [];
        this.dataNewlyAddedObj = [];

        for (var prop in dataInputHolder) {
          this.dataInputObj.push({ emp_id: prop, total_count: dataInputHolder[prop] });
        }
        for (var prop in dataNewlyAddedHolder) {
          this.dataNewlyAddedObj.push({ emp_id: prop, total_count: dataNewlyAddedHolder[prop] });
        }
        console.log(this.dataInputObj);
        
        console.log(this.dataNewlyAddedObj);
        
        this.totalAvailable = [];
        
        // this.dataInputObj.forEach(elementInput => {
          this.dataNewlyAddedObj.forEach(element => {
            // if(elementInput.emp_id === element.emp_id){
              this.totalAvailable.push({'emp_id':element.emp_id,'total_count':(element.total_count)})
            // }
          });
        // });
        
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }});
  }

  getTotalOutput(){
    this.httpClient.request('get', this.ss.dayDataOutputTotal).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.totalOutput = res.body;

        let dataOutputHolder = {};
        let dataDeleteHolder = {};

          this.totalOutput.forEach((d) => {
            if (dataOutputHolder.hasOwnProperty(d.emp_id)) {
              dataOutputHolder[d.emp_id] = dataOutputHolder[d.emp_id] + d.output_count;
            } else {
              dataOutputHolder[d.emp_id] = d.output_count;
            }
          });

          this.totalOutput.forEach((d) => {
            if (dataDeleteHolder.hasOwnProperty(d.emp_id)) {
              dataDeleteHolder[d.emp_id] = dataDeleteHolder[d.emp_id] + d.delete_count;
            } else {
              dataDeleteHolder[d.emp_id] = d.delete_count;
            }
          });
          
        this.dataOutputObj = [];
        this.dataDeleteObj = [];

        for (var prop in dataOutputHolder) {
          this.dataOutputObj.push({ emp_id: prop, total_count: dataOutputHolder[prop] });
        }
        for (var prop in dataDeleteHolder) {
          this.dataDeleteObj.push({ emp_id: prop, total_count: dataDeleteHolder[prop] });
        }
        console.log(this.dataOutputObj);
        
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  editEmployee(details) {
    this.EditFormGroup.controls['id'].setValue(details.emp_id);
    this.EditFormGroup.controls['name'].setValue(details.emp_name);
    this.EditFormGroup.controls['role_id'].setValue(details.role_id);
    this.EditFormGroup.controls['emp_no'].setValue(details.emp_no);
    this.EditFormGroup.controls['ip_address'].setValue(details.ip_address);
    this.EditFormGroup.controls['username'].setValue(details.username);
    this.EditFormGroup.controls['password'].setValue(details.password);
    this.changeRoleId = details.role_id;
    if(details.role_id != 2){
      this.EditFormGroup.controls['ip_address'].clearValidators();
        this.EditFormGroup.controls['ip_address'].updateValueAndValidity();
        this.EditFormGroup.controls['username'].clearValidators();
        this.EditFormGroup.controls['username'].updateValueAndValidity()
    }
    this.modal.open();
  }

  changeRole(role_id) {
    console.log(role_id);
    this.changeRoleId = role_id;
    if (role_id === 2) {
      this.EditFormGroup.controls['ip_address'].setValidators([Validators.required]);
      this.EditFormGroup.controls['username'].setValidators([Validators.required]);
    }
    else {
      this.EditFormGroup.controls['ip_address'].clearValidators();
      this.EditFormGroup.controls['ip_address'].updateValueAndValidity();
      this.EditFormGroup.controls['username'].clearValidators();
      this.EditFormGroup.controls['username'].updateValueAndValidity()
    }
  }

  editEmployeeSubmit(value) {
    if (this.EditFormGroup.valid) {
      var formData = new FormData();
      formData = value;
      this.httpClient.request('post', this.ss.updateEmployee, '', formData).subscribe((res: any) => {
        if (res.status === 200) {
          if(res.error.text === "Employee Details Already Exist"){
            this.ss.statusMessage.showStatusMessage(false, "Employee Details Already Exist");
          }
          else if(res.error.text === 'Employee Updated Successfully'){
          this.ss.statusMessage.showStatusMessage(true, "Employee Updated Succesfully");
          this.getEmployeeDetails();
          this.modal.close();
        }
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });

    }
  }

  activeEmployeeStatus(value) {
    if (value == 1) {
      this.active.setValue(true);
    }
    if (value == 0) {
      this.active.setValue(false);
    }
  }

  onChangeEmployeeStatus(id, value) {
    let status;
    if (value == true) {
      status = 1;
    }
    else {
      status = 0;
    }
    var formData = {};
    formData['status'] = status;
    formData['id'] = id;

    this.httpClient.request('post', this.ss.updateEmployeeStatus, '', formData).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        this.getEmployeeDetails();
        this.ss.statusMessage.showStatusMessage(true, "Employee Status updated succesfully");
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });

  }


}
