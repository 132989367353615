import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { HttpClientService } from 'src/app/services/http-client.service';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';
import { SingletonService } from 'src/app/services/singleton.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'dashboard-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  today: any = new Date();
  userName: string;
  notifyMssg: any = [];
  @ViewChild('notificationsModal', /* TODO: add static flag */   {static:false}) notificationsModal: ModalPopupComponent;
  count: any;
  reason = new FormArray([]);
  reasonType = new FormArray([]);
  resonsList: any;
  enterReason: boolean = false;
  constructor(
    private datepipe: DatePipe,
    private user: UserService,
    private httpClient: HttpClientService,
    private _router: Router,
    private ss: SingletonService,

  ) {



  }

  ngOnInit() {
    this.today = this.datepipe.transform(this.today, 'dd-MMM-yyyy');

  }

  getAllNotifications() {
    this.httpClient.request('get', "notification/getUserNotif").subscribe(res => {
      if (res.status === 200) {
        this.notifyMssg = res.body;
        this.user.getNotifCount();
        this.notifyMssg.forEach(element => {
          // if(element.notif_type == 9){
          // this.reason.push(new FormControl('', [Validators.required]));  
          // }
          // else{
          // this.reason.push(new FormControl());  
          // }
          this.reason.push(new FormControl());      
          this.reasonType.push(new FormControl());
        });
        this.enterReason = false;
      }
      else {

      }
    })
    this.reason.reset();
    this.reasonType.reset();
    this.enterReason = false;
    this.notificationsModal.open();
    this.getReasons();
  }

  ngAfterViewInit() {
    this.user.user.subscribe(val => {
      this.userName = val;
      // console.log(val);

    })
    setTimeout(() => {
      this.user.getNotifCount();

      this.user.notificationCount.subscribe(val => {
        this.count = val;
      })
    }, 3000);
  }

  logout() {
    this.user.logout();
  }
  viewed(id) {
    this.httpClient.request('post', "notification/markAsSeen", "id=" + id).subscribe(res => {
      if (res.status === 200) {
        this.getAllNotifications();
      }
    })
  }

  getReasons(){
    this.httpClient.request('get', "notification/getReasons").subscribe(res => {
      if (res.status === 200) {
        this.resonsList = res.body;
      }
    })
  }

  checkReasonType(type){
    if (type == 6){
      this.enterReason = true;
    }
    else{
      this.enterReason = false;
    }
  }

  onReasonSubmit(id, reasonType, reason) {
    // alert(" reasonType " + reasonType  + "-----reason" +reason);
    if( reasonType != null && (reasonType != 6 || ( reasonType == 6 && reason != null)) ){
      if(reason == null){
        this.resonsList.forEach(element => {
          if(reasonType ==  element.reason_id){
            reason = element.reason_type;
          }
        });
      }
    this.httpClient.request('post', "notification/updateReason", "id=" + id + "&reasonType=" +reasonType +  "&reason= " + reason).subscribe(res => {
      if (res.status === 200) {
        this.getAllNotifications();
      }
    })
  }
  }

}
