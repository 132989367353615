import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SingletonService } from 'src/app/services/singleton.service';
import { Router } from '@angular/router';
import { HttpClientService } from 'src/app/services/http-client.service';
import { UserService } from 'src/app/services/user.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  fb:FormBuilder = new FormBuilder();

  loginForm : FormGroup;

  constructor( 
    private ss: SingletonService,
    private httpClient: HttpClientService, 
    private router: Router,
    private user:UserService
    ) {
        this.loginForm = this.fb.group({
          username:["",Validators.required],
          password:["",Validators.required],
          // captcha:["",Validators.required]
        });
    }

  ngOnInit() { 
    if(this.user.validateSession()){
      this.ss.loggedIn$.next(true);
      this.router.navigate(["/overview"])
    } 
    // this.getCaptcha()   
  }

  onLogin(e:Event){
    if(this.loginForm.valid){
      this.httpClient.request('post',this.ss.login,"",this.loginForm.value).subscribe(res=>{
        // console.log(res);
        if(res.status === 200){ 
          localStorage.setItem('token',res.body);
          window.location.reload();

					this.user.getNotifCount();
          this.router.navigate(['/overview'])
        }else if (res.status === 403){
            this.ss.statusMessage.showStatusMessage(false, "Invalid Credentials",3000,'login')
        }else{
          this.ss.statusMessage.showStatusMessage(false,"Something went wrong")
        }
      })
    }
  }

  

}
