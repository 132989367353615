import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SidebarComponent } from '../layout/sidebar/sidebar.component'
import { LoaderComponent } from '../components/loader/loader.component';
import { ProgressBarComponent } from '../components/progress-bar/progress-bar.component';
import { StatusMessageComponent } from '../components/status-message/status-message.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { UAParser } from 'ua-parser-js';
import {uaParser} from './../interfaces/ua-parser';
import { ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';

@Injectable({
  providedIn: 'root'
})
export class SingletonService {


  isMenuOpenSubject: Subject<boolean> = new Subject();
  setactive: Subject<number> = new Subject();
  knowFrames: Subject<string> = new Subject();

  loader: LoaderComponent;

  progressBar: ProgressBarComponent;

  statusMessage: StatusMessageComponent;

  notificationCount : number;


  // baseUrl = "http://10.60.62.54:3001/api/";
  baseUrl = "https://wave.atdata.ai/api/";
  // baseUrl = "http://10.60.62.73:3000/api/";
  // baseUrl = "http://10.60.62.61:3000/api/";
  // baseUrl = "http://10.60.69.50:3000/api/";
  // baseUrl = "http://10.60.62.73:5555/api/";
  // baseUrl = "http://192.168.33.173:3000/api/";
  // baseUrl = "http://13.233.72.116/api/";

  uploader = 'https://wave.atdata.ai/api/upload-single';
  // uploader = 'https://10.60.62.54:3001/api/upload-single';
  login = 'login';
  uploadUpdate = 'upload-update';
  getSolutionWiseProjectHourDataListRole = 'get-solution-wise-project-hour-data-list-role';
  getSolutionWiseProjectListRole = 'get-solution-wise-project-list-role';
  updateUploadCount = 'update-upload-count';
  filterForUtilization = 'filter-for-utilization';
  clientProjectData = 'get-client-project-list-project';
  getActiveProjectData = 'get-active-project-mapping-list'
  clientProjectMapping = 'client-project-mapping';
  getDevianceData = 'get-deviance-data';
  clientProjectMappedData = 'client-project-mapped-data';
  clientProjectMappedList = 'client-project-mapped-list';
  clientProjectMappingClientWise = 'client-project-mapped-data-client-wise';
  getProjectWiseActiveEmpList = 'get-project-wise-active-employee-list';
  clientProjectImageDistribution = 'client-project-image-distribution';
  getClientWaveProjectData = 'get-client-wave-project-data';
  imageDistributionData = 'get-image-distribution-data'
  getClientProjectUploads = 'get-client-project-upload';
  getUploaddata = 'get-upload-data';
  ClientProjectStatus = 'client-project-status-data';
  ClientProjectsuploadCount = 'client-project-upload-count';
  downloadImages = 'download-images';
  distrubuteImages = 'distribute-images';
  upload = 'upload';
  addCategory ='add-category';
  deleteCategory ='delete-category';
  downloadExcel = 'download-excel';
  downloadExcelDayWise='download-excel-day-wise';
  downloadExcelDayWiseDiviance = 'download-excel-day-wise-diviance';
  downloadExcelHourData = 'download-excel-hour-data';
  downloadExcelHourDataSolutionWise = 'download-excel-hour-data-solution-wise';
  downloadExcelHourDataProjectWise = 'download-excel-hour-data-project-wise';
  downloadExcelHourDataEmployeeWise= 'download-excel-hour-data-employee-wise';
  downloadExcelDayData = 'download-excel-day-data';
  downloadExcelDayDataSolutionWise = 'download-excel-day-data-solution-wise';
  downloadExcelDayDataProjectWise = 'download-excel-day-data-project-wise';
  downloadExcelDayDataEmployeeWise = 'download-excel-day-data-employee-wise';
  addProject ='add-project';
  addClientProject = 'add-client-project';
  updateProject = 'update-project';
  updateSolution = 'update-solution';
  updateProjectStatus = 'project-status';
  updateSolutionStatus = 'solution-status';
  updateProjectMappingStatus = 'update-project-mapping-status';
  addSolution = 'add-solution';
  addRole = 'add-role';
  getRoles = 'get-role-list';
  updateRole = 'update-role';
  getRoleAccess = 'get-role-access';
  addEmployeeMapping ='add-employee-mapping';
  updateEmployeeMapping = 'update-employee-mapping';
  addProjectMapping = 'add-project-mapping';
  updateProjectMapping = 'update-project-mapping';
  getProjectList ='get-project-list';
  getClientProjectList = 'get-client-project-list';
  getSolutionWiseProjectList = 'get-solution-wise-project-list';
  getSolutionWiseProjectListHour = 'get-solution-wise-project-list-for-hour-data';
  getSolutionWiseProjectHourDataList = 'get-solution-wise-project-hour-data-list';
  getSolutionWiseEmployeeList = 'get-solution-wise-employee-list';
  getSolutionWiseEmployeeListHour = 'get-solution-wise-employee-list-hour';
  getSolutionWiseEmployeeHourDataList = 'get-solution-wise-employee-list-hour-data';
  getProjectWiseSolutionList = 'get-project-wise-solution-list';
  getProjectWiseSolutionListHourData = 'get-project-wise-solution-list-hour-data';
  getProjectWiseEmployeeList = 'get-project-wise-employee-list';
  getProjectWiseEmployeeListHourData = 'get-project-wise-employee-list-hour-data';
  getProjectWiseInputOutput = 'get-project-wise-input-output';
  getSolutionWiseInputOutput = 'get-solution-wise-input-output';
  getSolutionWiseInput = 'get-solution-wise-input';
  getEmployeeWiseInputOutput = 'get-employee-wise-input-output';
  getSolutionList = 'get-solution-list';
  getactiveProjectList ='get-active-project-list';
  getDistinctProjectList= 'get-distinct-project-list';
  getProjectWiseCategoryList = 'get-projectwsie-category-list';
  getemployeeWiseSolutionList = 'get-employeewsie-solution-list';
  getemployeeWiseSolutionListHourData = 'get-employeewsie-solution-list-hour-data';
  getemployeeWiseProjectList = 'get-employeewsie-project-list';
  getemployeeWiseProjectListDay = 'get-employeewsie-project-list-day';
  getemployeeWiseWorkedHours = 'get-employeewsie-worked-hours';
  getemployeeWiseProjectListHour = 'get-employeewsie-project-list-hour';
  getemployeeWiseProjectListHourData = 'get-employeewsie-project-list-hour-data';
  addTargetMapping= 'add-target-mapping';
  employeeAssignment ='employee-assignment-other-project';
  employeeWiseProjects = 'employee-wise-projects';
  updateTargetMapping = 'update-mapping-list';
  getEmployeeMappingList ='get-employee-mapping-list';
  getProjectMappingList = 'get-project-mapping-list';
  rangeWiseData = 'get-range-wise-data';
  filterSearch = 'get-data-with-filter-wise';
  allSolutionsHourData = 'get-all-solution-data-daily';
  allSolutionsHourDataTarget = 'get-all-solutions-data-daily-target';
  filterSearchTarget = 'get-filter-search-capacity';
  filterSearchworkedHours = 'get-filter-search-worked-hours';
  filterSearchworkedHoursDay = 'get-filter-search-worked-hours-day';
  solutionWiseHourDataTarget = 'get-solution-wise-data-daily-target';
  projectWiseHourDataTarget = 'get-project-wise-data-daily-target';
  employeeWiseHourDataTarget = 'get-employee-wise-data-daily-target';
  rangeWiseDataDaily = 'get-range-wise-data-daily';
  dayFilterDataInput = 'day-wise-filter-input';
  dayDataOutputTotal='day-wise-filter-output-total';
  dayDataInputTotal = 'day-wise-filter-input-total';
  dayDataNewlyAddedTotal ='day-wise-filter-newlyadded-total';
  allSolutionsDailyDataInput = 'get-all-solutions-data-day-wise-input';
  rangeWiseDailyDataInput = 'get-range-wise-data-day-wise-input';
  projectWiseDailyDataInput = 'get-project-wise-data-day-wise-input';
  employeeWiseDailyDataInput = 'get-employee-wise-data-day-wise-input';
  allSolutionsDailyDataOutput = 'get-all-solution-data-day-wise-output';
  dayFilterDataOutput = 'day-wise-filter-output';
  allSolutionsDataOutput = 'get-all-solutions-data-day-wise-output';
  rangeWiseDailyDataOutput = 'get-range-wise-data-day-wise-output';
  projectWiseDailyDataOutput = 'get-project-wise-data-day-wise-output';
  employeeWiseDailyDataOutput = 'get-employee-wise-data-day-wise-output';
  dayFilterDataNewlyAdded = 'day-wise-filter-newly-added';
  allSolutionsDailyDataNewlyAdded = 'get-all-solutions-data-day-wise-newly-added';
  rangeWiseDailyDataNewlyAdded = 'get-range-wise-data-day-wise-newly-added';
  projectWiseDailyDataNewlyAdded = 'get-project-wise-data-day-wise-newly-added';
  employeeWiseDailyDataNewlyAdded = 'get-employee-wise-data-day-wise-newly-added';
  dayFilterDataTarget = 'day-wise-filter-target';
  allSolutionsDailyTarget = 'get-all-solutions-data-day-wise-target';
  rangeWiseDailyTarget = 'get-range-wise-data-day-wise-target';
  projectWiseDailyTarget = 'get-project-wise-data-day-wise-target';
  employeeWiseDailyTarget  = 'get-employee-wise-data-day-wise-target';
  projectWiseDataDaily = 'get-project-wise-data-daily';
  employeeWiseDataDaily = 'get-employee-wise-data-daily';
  rangeWiseTargetData = 'get-range-wise-target-data';
  getHourlyData ='get-hourly-data';
  getDailyData ='get-daily-data';
  getLabelingData = 'get_labeling_data';
  getLabelingDataFilterWise = 'get_labeling_data-filter-wise';
  addEmployee = 'add-employee';
  addEmployeeRole = 'add-employee-role';
  updateEmployee = 'update-employee';
  updateEmployeeStatus = 'employee-status';
  getEmployeeList = 'get-employee-list';
  getActiveEmployeeList= 'get-active-employee-list';
  getActiveEmployeeListHour = 'get-active-employee-list-hour';
  getHourDataEmployeeList = 'get-hour-data-employee-list';
  getActiveProjectList = 'get-active-project-list';
  getActiveProjectListHour = 'get-active-project-list-hour';
  getHourdataProjectList = 'get-hour-data-project-list';
  getActiveSolutionList = 'get-active-solution-list';
  getHourDataSolutionList = 'get-hourdata-active-solution-list'
  getEmployeewiseProjectList = 'get-employeewise-project-list';
  filterOutput='get-employee-wise-data';
  filterOutputDateWise = 'get-employee-wise-data-date-wise';
  filterTargetEmployeeWise = 'get-employee-wise-target';
  filterTargetEmployeeWiseDateWise = 'get-employee-wise-target-date-wise';
  getCategoriesList='get-categories-list';
  isMac;
  presets: { presetLabel: string; range: { fromDate: Date; toDate: Date; }; }[];
  
  // client_config io_config
  constructor(private _http:HttpClient) { 
    this.checkForMacOs();
  }

  /************ Start BREADCRUMB  ************/
  public breadCrumbMap: Array<any> = [];

  public breadCrumbSubject: Subject<any> = new Subject();

  setBreadCrumbData(key, value, noclick: boolean = false, queryParamsObj: Object = {}) {
    // set the breadcrumb text value as part of the data available for the breadcrumb
    this.breadCrumbMap[key] = value;
    // send the noclick boolean into the data available for the breadcrumb
    this.breadCrumbMap[key + '_noclick'] = noclick;
    // send the query Params if any into the data available for the breadcrumb
    this.breadCrumbMap[key + '_queryParams'] = queryParamsObj;

    this.breadCrumbSubject.next();
  }

  loggedIn$ : Subject<boolean> = new Subject();

  /************** END Bread CRumb implementation ************/
  setActive(userid: number) {
    this.setactive.next(userid)
  }

  getFrames(name: string) {
    this.knowFrames.next(name)
    console.log(this.knowFrames);
  }

  setupPresets() {
    const backDate = numOfDays => {
      // tslint:disable no-shadowed-variable
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date();
    // new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      {
        presetLabel: 'Yesterday',
        range: { fromDate: yesterday, toDate: yesterday }
      },
      {
        presetLabel: 'Last 7 Days',
        range: { fromDate: minus7, toDate: yesterday }
      },
      {
        presetLabel: 'Last 30 Days',
        range: { fromDate: minus30, toDate: yesterday }
      },
      {
        presetLabel: 'This Month',
        range: { fromDate: currMonthStart, toDate: yesterday }
      },
      {
        presetLabel: 'Last Month',
        range: { fromDate: lastMonthStart, toDate: lastMonthEnd }
      }
    ];
  }
  
public lineChartOptionsDisable: (ChartOptions & { annotation: any }) = {
  responsive: true,
//   legend: {
//     onClick: (e:any,legendItem) =>{
//       var index = legendItem.datasetIndex;
//       console.log(legendItem);
      
//         if(index == 2){

//         }
//         else{

//         }
//         console.log(index);
        
//     }
// },
legend: {
  position: 'top',
  display: true,
  fullWidth: true,
  labels: {
    fontSize: 14,
  }
},
  scales: {
 
    // We use this empty structure as a placeholder for dynamic theming.
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Day Hour(24 Hour)'
      },
      offset: true
    }],
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Image Count'
      },
        id: 'y-axis-0',
        position: 'left',
      }
    ]
  },
  annotation: {
    annotations: [
      {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: 'March',
        borderColor: 'orange',
        borderWidth: 2,
        label: {
          enabled: true,
          fontColor: 'orange',
          content: 'LineAnno'
        }
      },
    ],
  },
};

public annotationOptionsDisable: (ChartOptions & { annotation: any }) = {
  responsive: true,
//   legend: {
//     onClick: (e:any,legendItem) =>{
//       var index = legendItem.datasetIndex;
//       console.log(legendItem);
      
//         if(index == 2){

//         }
//         else{

//         }
//         console.log(index);
        
//     }
// },
legend: {
  position: 'top',
  display: true,
  fullWidth: true,
  labels: {
    fontSize: 14,
  }
},
  scales: {
 
    // We use this empty structure as a placeholder for dynamic theming.
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Day Hour(24 Hour)'
      },
      offset: true
    }],
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Annotation Count'
      },
        id: 'y-axis-0',
        position: 'left',
      }
    ]
  },
  annotation: {
    annotations: [
      {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: 'March',
        borderColor: 'orange',
        borderWidth: 2,
        label: {
          enabled: true,
          fontColor: 'orange',
          content: 'LineAnno'
        }
      },
    ],
  },
};

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    legend: {
      labels: {
        fontSize: 14,
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Date'
        },
        offset: true
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Images'
        },
          id: 'y-axis-0',
          position: 'left',
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public pieChartColors = [
    {
    backgroundColor:['#3e95cd','#8e5ea2','rgba(255,99,132,1)']
  }
]
  
  public barChartColors: Color[] = [
    { // red
      backgroundColor: 'rgba(63, 149, 205,1)',
      borderColor: '#3e95cd',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // green
      backgroundColor: 'rgba(125, 104, 171,1)',
      borderColor: '#8e5ea2',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // blue
      backgroundColor: 'rgba(255,99,132,1)',
      borderColor: 'rgba(255,99,132,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // orange
      backgroundColor: 'rgba(43, 106, 146,1)',
      borderColor: '#3cba9f',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // purple
      backgroundColor: 'rgba(196, 87, 80,1)',
      borderColor: '#c45850',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartColors: Color[] = [
    { // red
      backgroundColor: 'rgba(63, 149, 205,0.2)',
      borderColor: '#3e95cd',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // green
      backgroundColor: 'rgba(125, 104, 171,0.2)',
      borderColor: '#8e5ea2',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // blue
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // orange
      backgroundColor: 'rgba(43, 106, 146,0.2)',
      borderColor: '#3cba9f',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // purple
      backgroundColor: 'rgba(196, 87, 80,0.2)',
      borderColor: '#c45850',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartColorsLiveData: Color[] = [
   
    { // green
      backgroundColor: 'rgba(125, 104, 171,0.2)',
      borderColor: '#8e5ea2',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // blue
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // purple
      backgroundColor: 'rgba(196, 87, 80,0.2)',
      borderColor: '#c45850',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public annotationChartColorsLiveData: Color[] = [
   
    { // green
      backgroundColor: 'rgba(125, 104, 171,0.2)',
      borderColor: '#004d4d',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // blue
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgb(0, 102, 255)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // purple
      backgroundColor: 'rgba(196, 87, 80,0.2)',
      borderColor: '#800080',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // 
      backgroundColor: 'rgba(76, 29, 39,0.2)',
      borderColor: '#999999',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  getDateTime() {

    let date = new Date();

    let hour:any = date.getHours();
    hour = (hour < 10 ? "0" : "") + hour;

    let min:any = date.getMinutes();
    min = (min < 10 ? "0" : "") + min;

    let sec:any = date.getSeconds();
    sec = (sec < 10 ? "0" : "") + sec;

    let year:any = date.getFullYear();

    let month:any = date.getMonth() + 1;
    month = (month < 10 ? "0" : "") + month;

    let day:any = date.getDate();
    day = (day < 10 ? "0" : "") + day;

    return year + "_" + month + "_" + day + "_" + hour + "_" + min;

}
  

  checkForMacOs() {
    let platform = navigator.platform;
    if ( platform === 'MacIntel' || platform === 'MacPPC' ) {
      this.isMac = true;
  }
  }
  
}
