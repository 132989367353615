import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { svg } from 'd3';

var dateMonthRef = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

interface sides {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
}

interface LineSettings {
    label?: string;
    targetKey?: string;
    color?: string;
}

interface MultiLineSettings {
    width?: number | string;
    height?: number;
    color?: string;
    padding?: sides;
    caption?: string;
    tooltip?: true;
    lines?: Array<LineSettings>
}

@Component({
    selector: 'dbd-multi-line-chart',
    templateUrl: './multi-line-chart.component.html',
    styleUrls: ['./multi-line-chart.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MultiLineChartComponent implements OnInit {
    svgWidth: number;
    svgHeight: number;

    @Input() idd: string = "";

    @Input() width: number | string = "100%";

    @Input() height: number = 300;

    @Input() settings: MultiLineSettings = {};

    defaultSettings: MultiLineSettings = {
        height: 300,
        width: "100%",
        tooltip: true,
        color: '#000',
        caption: "",
        padding: { left: 40, top: 60, right: 0, bottom: 0 }
    }

    @ViewChild('candleStickWrap', /* TODO: add static flag */   {static:false}) wrapperElement: ElementRef;

    svgViewPort: d3.Selection<any, any, any, any>;

    svgLengendsWrapper: d3.Selection<any, any, any, any>;

    toolTip: d3.Selection<any, any, any, any>;

    @Input()
    data: Array<any>;

    svgWrapperRectangle: d3.Selection<any, any, any, any>;

    axes: { x?: d3.Axis<any>, y?: d3.Axis<any> } = {};

    axesElements: { x?: any, y?: any } = {};

    axesScale: { x?: d3.AxisScale<any>, y?: d3.AxisScale<any> } = {};

    axesScaleOriginal: { x?: d3.AxisScale<any>, y?: d3.AxisScale<any> } = {};

    scale: { x?: d3.AxisScale<any>, y?: d3.AxisScale<any> };

    zoomScale: { x?: d3.ZoomScale, y?: d3.ZoomScale } = {};

    get currentZoom() {
        return d3.zoomTransform(this.svgViewPort.node()).k;
    }

    zoom: d3.ZoomBehavior<any, any>;

    drag: d3.ZoomBehavior<any, any>;

    defs: d3.Selection<any, any, any, any>;

    clipPath: d3.Selection<any, any, any, any>;

    linesData: Array<any> = [];

    constructor(
        private httpClient: HttpClient,
        private renderer: Renderer2,
        private el: ElementRef,
        private router: Router,
    ) {
    }

    ngOnInit() {
        // this.renderer.setStyle(this.wrapperElement.nativeElement, 'padding-left', '20px');
    }

    ngOnChanges() {
        this.settings = _.merge(this.defaultSettings, this.settings);
        this.width = this.settings.width;
        this.height = this.settings.height;
        // this.drawChart();
    }

    dateArray: Array<any>;

    getAxesOriginal() {

        this.axesScaleOriginal = this.axesScaleOriginal || {};

        this.axesScaleOriginal.y = d3.scaleLinear()
            .domain(this.domainY)
            .range(this.rangeY);

        this.axesScaleOriginal.x = d3.scaleLinear()
            .domain(this.domainX)
            .range(this.rangeX);

        return this.axesScaleOriginal;

    }

    positionToolTip(d, i, node, e) {
        
        console.log(new Date(d.timestamp));
        
        const datePipe = new DatePipe('en-US');
        let midX = this.svgWidth/2;
        let midY = this.svgHeight/2;

        let nodeX = this.axesScale.x(this.dateArray[i])
        let nodeY = this.axesScale.y(d["avg"])
        
        this.toolTip = this.svgViewPort.append('g')
            .classed('.tool-tip', true)
            .attr('transform', 'translate(' + (nodeX + (nodeX <= midX ? 10 : -100) + ', ' + (nodeY + (nodeY >= midY ? -100 : 10))) + ')');

        this.toolTip.append('rect')
            .attr('width', 150)
            .attr('height', 80)
            .attr('fill', '#000')
            .attr('ry', 10);

        this.toolTip.append('text')
            .attr('class','texts')
            .html('<tspan x="10" y="33"> Y: ' + d.avg.toFixed(2) + '</tspan>' + '<tspan x="10" y="63">X: ' + datePipe.transform(d.timestamp, 'MM-dd-yy HH:MM') + '</tspan>');

    }
    positionToolTipError(d, i, node, e) {


        let midX = this.svgWidth/2;
        let midY = this.svgHeight/2;

        let nodeX = this.axesScale.x(this.dateArray[i])
        let nodeY = this.axesScale.y(0)
        
        this.toolTip = this.svgViewPort.append('g')
            .classed('.tool-tip', true)
            .attr('transform', 'translate(' + (nodeX + (nodeX <= midX ? 10 : -100) + ', ' + (nodeY + (nodeY >= midY ? -100 : 10))) + ')');

        this.toolTip.append('rect')
            .attr('width', 200)
            .attr('height', 50)
            .attr('fill', 'white')
            .attr('color','white')
            .attr('ry', 10);
        // alert(JSON.stringify(d))
        this.toolTip.append('text')
            .html('<tspan x="10" y="25">Error : ' + d.err_info + '</tspan>');

    }
    get domainY() {
        var mins = [], maxs = [];
        this.settings.lines.forEach((lineInfo, index) => {
            var data = this.linesData[index];
            

            
           
        });

        return [0, 100];
    }

    get domainX() {
        // return [(Math.min(...this.dateArray) * 1000 - 86400000), (Math.max(...this.dateArray) * 1000 + 86400000)];

        var minX = d3.min(this.dateArray, (d) => {
            return d;
        });
        var maxX = d3.max(this.dateArray, (d) => {
            return d;
        });

        return [minX, maxX]
    }

    get zoomScaleExtent() {
        var minDate = d3.min(this.dateArray, (d) => {
            return d;
        });
        var maxDate = d3.max(this.dateArray, (d) => {
            return d;
        });

        var zoomMin = 1;
        var diff = d3.timeDay.count(new Date(minDate), new Date(maxDate));

        var zoomMax = diff < 1 ? 2
            : diff < 7 ? 10
                : diff < 31 ? 20
                    : 50;

        console.log([zoomMin, zoomMax]);
        return [zoomMin, zoomMax];
    }

    get rangeX() {
        return [0, this.svgWidth];
    }

    get rangeY() {
        return [this.svgHeight, 0];
    }

    ngAfterContentInit() {


    }

    toggle(targetKey) {
        // console.log('.targetKey');
        var targetclass = "." + targetKey;
        // console.log(targetclass);
        var target: any = d3.select(targetclass);
        var target_label: any = d3.select(".label_"+targetKey);
        var display = target.style("display");
        target.style("display", display == "inline" ? "none" : "inline");    
        if(display == "inline") {
            target_label.classed("inactive" , true);
            target_label.classed("active" , false);
            
        } else {
            target_label.classed("active" , true);
            target_label.classed("inactive" , false);
        }
        
    }

    drawChart() {

        if (this.data) {
            this.zoom = d3.zoom();


            this.width = <number>(this.wrapperElement.nativeElement.clientWidth);
            this.svgWidth = this.wrapperElement.nativeElement.clientWidth - this.settings.padding.left - this.settings.padding.right;
            this.svgHeight = this.height - this.settings.padding.bottom - this.settings.padding.top;

            this.svgViewPort = d3.select(this.wrapperElement.nativeElement)
                .append('svg')
                // .attr('transform', 'translate(' + this.settings.padding.left + ', ' + this.settings.padding.top + ')')            
                .attr("height", this.height)
                .attr("width", this.width)
                .attr('viewBox', '-' + this.settings.padding.left + ' -' + this.settings.padding.right + ' ' + this.width + ' ' + this.height);



            this.defs = this.svgViewPort.append('defs');

            this.clipPath = this.defs.append('clipPath')
                .attr('id', 'clip');

            this.clipPath.append('rect')
                .attr('width', this.svgWidth)
                .attr('height', this.svgHeight)
                .attr('x', 0)
                .attr('y', 0);


            this.svgWrapperRectangle = this.svgViewPort.append('g')
                .attr('clip-path', 'url(#clip)');     

            this.dateArray = _.map(this.data, (obj) => {
                let datum = new Date(obj['timestamp'])
                return datum;
            });

            this.settings.lines.forEach(lineInfo => {

                var lineData = [];
                this.data.forEach(element => {
                    let obj = {};
                    let dataInfo = element[lineInfo.targetKey] || {};
                    if (lineInfo.targetKey == 'error') {
                        // alert('error')
                        obj["timestamp"] = element["timestamp"];
                        obj["err"] = dataInfo["err"];
                        obj["err_info"] = dataInfo["err_info"];
                        obj["avg"] = 0;
                    } else {
                        obj["timestamp"] = element["timestamp"];
                        obj["avg"] = dataInfo["avg"] || 0;
                    }
                    lineData.push(obj);
                });

                this.linesData.push(lineData);
            });

            console.log(this.linesData);

            let scaleExtent = this.zoomScaleExtent;
            this.zoom
                .scaleExtent([scaleExtent[0], scaleExtent[1]])
                .translateExtent([[0, 0], [this.width, this.height]])
                .extent([[0, 0], [this.width, this.height]])
                .on('zoom', () => {
                    this.zoomFunction();
                });
            this.svgViewPort.call(this.zoom);

            // console.log(this.dateArray);

            // var minDate = _.da
            this.axesScaleOriginal.x = d3.scaleTime()
                .domain(this.domainX)
                .range(this.rangeX);

            this.axesScale.x = this.axesScaleOriginal.x.copy();

            this.axes.x = d3.axisBottom(this.axesScale.x);

            this.axesScaleOriginal.y = d3.scaleLinear()
                .domain(this.domainY)
                .range(this.rangeY);

            this.axesScale.y = this.axesScaleOriginal.y.copy();

            this.axes.y = d3.axisLeft(this.axesScale.y);

            // set the string format of the display string on x axis
            this.axes.x.tickFormat(this.multiFormat);

            // add the x axis
            this.axesElements.x = this.svgViewPort.append("g")
                .classed('x-axis', true)
                .attr("transform", "translate(0, " + (this.svgHeight) + ")")
                .call(this.axes.x)
            // .selectAll("text")	
            // .style("text-anchor", "end")
            // .attr("dx", "-.2em")
            // .attr("dy", ".15em")
            // .attr("transform", "rotate(-45)");

            // add the y axis 
            this.axesElements.y = this.svgViewPort.append("g")
                .classed('y-axis', true)

                .call(this.axes.y);

            this.settings.lines.forEach((lineInfo, i) => {
                var transition = d3.transition().duration(750);
                var line = this.svgWrapperRectangle.append('g')
                    .classed(lineInfo.targetKey, true);

                var groups = line.selectAll(".mma-group")
                    .data(this.linesData[i]).enter()
                    .append('g')
                    .style('overflow', 'hidden')
                    .attr('class', 'mma-group');

                if (lineInfo.targetKey == 'error') {
                    
                    groups.on('click', (d, i, node) => {
                        // console.log(this.axesScale.x(this.dateArray[i] * 1000));
                        // this.positionToolTipError(d, i, node, d3.event);
                        // this.router.navigate(['/alert-view'],{queryParams:{date:d['timestamp']}});
                        // this.router.navigate(['/alert-view']);

                    });
                    
                } else {
                    groups.on('mouseenter', (d, i, node) => {
                        // console.log(this.axesScale.x(this.dateArray[i] * 1000));
                        this.positionToolTip(d, i, node, d3.event);
                        // console.log(node[i]);
                        
                        d3.select(node[i])
                        .select('circle')
                        .attr('r','10')
                        .transition(transition);

                    });
                    groups.on('mouseleave', (d, i, node) => {
                        this.toolTip.remove();
                        d3.select(node[i])
                        .select('circle')
                        .attr('r','5')
                        .transition(transition);
                    });
                }
                var scaleMultiplier = this.currentZoom;

                

                groups.append("circle")
                    .classed('avg', true)
                    .attr("cx", (d, i) => {
                        return this.axesScale.x(this.dateArray[i]);
                    })
                    .attr("cy", (d, i) => {
                        return this.axesScale.y(d["avg"]);
                    })
                    .attr("r", "5px")
                    .attr("fill", lineInfo.color);

                // groups.append("line")
                //     .classed('mma-line', true)
                //     .attr("x1", (d, i) => {
                //         return this.axesScale.x(this.dateArray[i]);
                //     })
                //     .attr("x2", (d, i) => {
                //         return this.axesScale.x(this.dateArray[i]);
                //     })

                line.append("path")
                    .classed('avg-line', true)
                    .classed(lineInfo.targetKey, true)
                    .datum(this.linesData[i])
                    .attr("d", this.getPath())
                    .attr("fill", "none")
                    .attr("stroke-width", "2px")
                    .attr('stroke-dasharray', '1, 2')
                    .attr("stroke", lineInfo.color);
            });


        }

    }




    redrawPath() {
        this.resetZoom();
        var transition = d3.transition().duration(750);

        this.dateArray = _.map(this.data, (obj) => {
            let datum = new Date(obj['timestamp'])
            return datum;
        });

        this.linesData = [];
        this.settings.lines.forEach(lineInfo => {

            var lineData = [];
            this.data.forEach(element => {
                let obj = {};
                let dataInfo = element[lineInfo.targetKey] || {};

                if (lineInfo.targetKey == 'error') {
                    // alert('error')
                    obj["timestamp"] = element["timestamp"];
                    obj["err"] = dataInfo["err"];
                    obj["err_info"] = dataInfo["err_info"];
                    obj["avg"] = 0;
                } else {
                    obj["timestamp"] = element["timestamp"];
                    obj["avg"] = dataInfo["avg"] || 0;
                }

                lineData.push(obj);
            });

            this.linesData.push(lineData);
        });


        let scaleExtent = this.zoomScaleExtent;

        // var minDate = _.da
        this.axesScaleOriginal.x = d3.scaleTime()
            .domain(this.domainX)
            .range(this.rangeX);

        this.axesScale.x = this.axesScaleOriginal.x.copy();

        this.axes.x = d3.axisBottom(this.axesScale.x);

        this.axesScaleOriginal.y = d3.scaleLinear()
            .domain(this.domainY)
            .range(this.rangeY);

        this.axesScale.y = this.axesScaleOriginal.y.copy();

        this.axes.y = d3.axisLeft(this.axesScale.y);

        this.axesElements.x.transition(transition).call(this.axes.x);
        this.axesElements.y.transition(transition).call(this.axes.y);


        this.settings.lines.forEach((lineInfo, i) => {

            var line = this.svgWrapperRectangle.select("." + lineInfo.targetKey);
            
            line.selectAll(".mma-group").remove();
            line.selectAll('.avg-line').remove();
            var groups = line.selectAll(".mma-group")
                .data(this.linesData[i]).enter()
                .append('g')
                .style('overflow', 'hidden')
                .attr('class', 'mma-group');
                if (lineInfo.targetKey == 'error') {
                    
                    groups.on('click', (d, i, node) => {
                        // console.log(this.axesScale.x(this.dateArray[i] * 1000));
                        // this.positionToolTipError(d, i, node, d3.event);
                        // this.router.navigate(['/alert-view'],{queryParams:{date:d['timestamp']}});
                        this.router.navigate(['/alert-view']);

                    });
                    
                } else {
                    groups.on('mouseenter', (d, i, node) => {
                        // console.log(this.axesScale.x(this.dateArray[i] * 1000));
                        this.positionToolTip(d, i, node, d3.event);
                        
                    });
                    groups.on('mouseleave', (d, i, node) => {
                        this.toolTip.remove();
                    });
                }

            

            groups.append("circle")
                .transition(transition)
                .attr("cx", (d, i) => {
                    return this.axesScale.x(this.dateArray[i]);
                })
                .attr("cy", (d, i) => {
                    return this.axesScale.y(d["avg"]);
                })
                .attr("r", "5px")
                .attr("fill", lineInfo.color)
                .attr('class', 'circle')
                .attr('class', 'avg');

            groups.append("line")
                .classed('mma-line', true)
                .transition(transition)
                .attr("x1", (d, i) => {
                    return this.axesScale.x(this.dateArray[i]);
                })
                .attr("x2", (d, i) => {
                    return this.axesScale.x(this.dateArray[i]);
                })
                

             line.append("path")
                .classed('avg-line', true)
                .classed(lineInfo.targetKey, true)
                .datum(this.linesData[i])
                .attr("d", this.getPath())
                .attr("fill", "none")
                .attr("stroke-width", "2px")
                .attr('stroke-dasharray', '1, 2')
                .attr("stroke", lineInfo.color);
        });
        this.zoom
            .scaleExtent([scaleExtent[0], scaleExtent[1]])
            .translateExtent([[0, 0], [<number>this.width, this.height]])
            .extent([[0, 0], [<number>this.width, this.height]])
            .on('zoom', () => {
                this.zoomFunction();
            });
    }


    zoomFunction() {

        var that = this;

        var transition = d3.transition().duration(750);

        this.axesScale.x = d3.event.transform.rescaleX(this.axesScaleOriginal.x);
        this.axesScale.y = d3.event.transform.rescaleY(this.axesScaleOriginal.y);

        this.axesElements.x.transition(transition).call(this.axes.x.scale(this.axesScale.x));
        this.axesElements.y.transition(transition).call(this.axes.y.scale(this.axesScale.y));

        this.axesElements.x.transition(transition).call(this.axes.x);
        this.axesElements.y.transition(transition).call(this.axes.y);

        // g.select(".axis--y").transition(t).call(yAxis);


        var scaleMultiplier = this.currentZoom;
        // console.log(scaleMultiplier);

        var zoomEvent: d3.D3ZoomEvent<any, any> = d3.event;

        this.settings.lines.forEach((lineInfo, i) => {
            var that  = this;
            var line = this.svgWrapperRectangle.select("." + lineInfo.targetKey);

            var groups = line.selectAll('.mma-group').each(function(d : any,iGroup){

                let group = d3.select(this);                
                // console.log(group.selectAll('circle'));

                
    
                group.selectAll('.avg').transition(transition).attr('cx',that.axesScale.x(that.dateArray[iGroup])).attr('cy',that.axesScale.y(d.avg)).attr("r", "5px"); 
                // group.select('.avg').attr('cx',that.axesScale.x(that.dateArray[i]));
                // group.selectAll('.mma-line')
                // .transition(transition)
                // .attr("x1", (d, i) => {
                //     return that.axesScale.x(that.dateArray[iGroup]);
                // })
                // .attr("x2", (d, i) => {
                //     return that.axesScale.x(that.dateArray[iGroup]);
                // })
    
                // .attr("stroke-width", "2px");
                            
            });

            // console.log(groups.selectAll('.min').data(this.linesData[i]));

            // groups.selectAll('.min')
            //     .transition(transition)
            //     .attr("cx", (d, i) => {
            //         // console.log(this.axesScale.x(this.dateArray[i]), this.axesScale.y(d.min));
            //         return this.axesScale.x(this.dateArray[i]);
            //     })
            //     .attr("cy", (d: any, i) => {
            //         return this.axesScale.y(d.min);
            //     })
            //     .attr("r", "3px");

            // groups.selectAll('.max')
            //     .transition(transition)
            //     .attr("cx", (d, i) => {
            //         return this.axesScale.x(this.dateArray[i]);
            //     })
            //     .attr("cy", (d: any, i) => {
            //         return this.axesScale.y(d.max);
            //     })
            //     .attr("r", "3px");

            // groups.selectAll('.avg')
            //     .transition(transition)
            //     .attr("cx", (d, i) => {
            //         return this.axesScale.x(this.dateArray[i]);
            //     })
            //     .attr("cy", (d: any, i) => {
            //         return this.axesScale.y(d.avg);
            //     })
            //     .attr("r", "5px")

           

            line.selectAll('.avg-line')
                .transition(transition)
                .attr("d", this.getPath());

        });


    }

    getPath() {
        return d3.line()
            .x((d, i) => {
                return this.axesScale.x(this.dateArray[i]);
            })
            .y((d, i) => {
                return this.axesScale.y(d["avg"]);
            })
            .curve(d3.curveCatmullRom.alpha(0.8));
    }

    resetZoom() {
        if(this.toolTip !== undefined) {
            this.toolTip.remove();
        }
        this.svgViewPort.transition().duration(500).call(this.zoom.transform, d3.zoomIdentity);
    }

    // Define filter conditions
    multiFormat(date) {
        var formatMinute = d3.timeFormat("%I:%M %p"),
            formatHour = d3.timeFormat("%I %p"),
            formatDay = d3.timeFormat("%b %d"),
            formatWeek = d3.timeFormat("%b %d"),
            formatMonth = d3.timeFormat("%B"),
            formatYear = d3.timeFormat("%Y");

        return (d3.timeHour(date) < date ? formatMinute
            : d3.timeDay(date) < date ? formatHour
                : d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
                    : d3.timeYear(date) < date ? formatMonth
                        : formatYear)(date);
    }

}
