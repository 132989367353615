import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';
import { HttpResponse } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  @ViewChild('roleModal', /* TODO: add static flag */ {static:false}) roleModal:ModalPopupComponent;
  @ViewChild('updateRoleModal', /* TODO: add static flag */ {static:false}) updateRoleModal:ModalPopupComponent;
  @ViewChild('NgFormRole', /* TODO: add static flag */ {static:false}) NgFormRole;
  @ViewChild('NgFormUpdateRole', /* TODO: add static flag */ {static:false}) NgFormUpdateRole;
  roleFormGroup:FormGroup;
  UpdateRoleFormGroup: FormGroup;
  emp_add = false;
  roleList: any;
  roleAccess: any;
  role_id: any;
  rolePriviliges: string;
  
  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClientService,
    private ss: SingletonService,
    private user: UserService
  ) {
    this.roleFormGroup = this.formBuilder.group({
      'role_name': ['', Validators.required],
      'emp_add': [''],
      'emp_list': [''],
      'emp_edit': [''],
      'emp_status': [''],
      'sol_list': [''],
      'sol_add': [''],
      'sol_edit': [''],
      'sol_status': [''],
      'proj_list': [''],
      'proj_add': [''],
      'proj_sol_list': [''],
      'proj_sol_status': [''],
      'emp_proj_list': [''],
      'emp_proj_capacity_edit': [''],
      'emp_proj_staus': [''],
      'overview': [''],
      'report': [''],
      'roles': [''],
      'client_dashboard': [''],
      'client_mapping': [''],
      'image_distribution': [''],
      'deviance_report':[''],
      'utilization':[''],
      'exam':[''],
    });
    this.UpdateRoleFormGroup = this.formBuilder.group({
      'role_id':[''],
      'role_name': [''],
      'emp_add': [''],
      'emp_list': [''],
      'emp_edit': [''],
      'emp_status': [''],
      'sol_list': [''],
      'sol_add': [''],
      'sol_edit': [''],
      'sol_status': [''],
      'proj_list': [''],
      'proj_add': [''],
      'proj_sol_list': [''],
      'proj_sol_status': [''],
      'emp_proj_list': [''],
      'emp_proj_capacity_edit': [''],
      'emp_proj_staus': [''],
      'overview': [''],
      'report': [''],
      'roles': [''],
      'client_dashboard': [''],
      'client_mapping': [''],
      'image_distribution': [''],
      'deviance_report':[''],
      'utilization':[''],
      'exam':[''],
    });
   }

  ngOnInit() {
    this.role_id = this.user.getRole();
    this.user.getUserId();
    this.user.currentMessage.subscribe(val=>{
      this.rolePriviliges = val[0];
    });
    this.getRoleList();
    console.log(this.user.getRolePriviliges());
    this.user.currentMessage.subscribe(val=>{
      console.log(val);
      
    })
  }

  getRoleList(){
    this.httpClient.request('get', this.ss.getRoles).subscribe((res: HttpResponse<any>) => {
      this.roleList = res.body;
      console.log(this.roleList);
    });
    this.httpClient.request('get', this.ss.getRoleAccess).subscribe((res: HttpResponse<any>) => {
      this.roleAccess = res.body;
      console.log(this.roleAccess);
    });
  }

  addRoleModal(){
    this.NgFormRole.resetForm();
    this.roleFormGroup.controls['overview'].setValue(1);
    this.roleFormGroup.controls['report'].setValue(1);
    // this.roleFormGroup.controls['proj_list'].setValue(1);
    // this.roleFormGroup.controls['proj_sol_list'].setValue(1);
    // this.roleFormGroup.controls['emp_proj_list'].setValue(1);
    this.roleModal.open();
  }

  updateRoleModalOpen(details){
    this.NgFormUpdateRole.resetForm();
    this.UpdateRoleFormGroup.controls['role_id'].setValue(details.role_id);
    this.UpdateRoleFormGroup.controls['role_name'].setValue(details.role_name);
    this.UpdateRoleFormGroup.controls['role_name'].disable();
    this.UpdateRoleFormGroup.controls['emp_add'].setValue(details.emp_add);
    this.UpdateRoleFormGroup.controls['emp_edit'].setValue(details.emp_edit);
    this.UpdateRoleFormGroup.controls['emp_list'].setValue(details.emp_list);
    this.UpdateRoleFormGroup.controls['emp_proj_capacity_edit'].setValue(details.emp_proj_capacity_edit);
    this.UpdateRoleFormGroup.controls['emp_proj_list'].setValue(details.emp_proj_list);
    this.UpdateRoleFormGroup.controls['emp_proj_staus'].setValue(details.emp_proj_staus);
    this.UpdateRoleFormGroup.controls['emp_status'].setValue(details.emp_status);
    this.UpdateRoleFormGroup.controls['proj_add'].setValue(details.proj_add);
    this.UpdateRoleFormGroup.controls['proj_list'].setValue(details.proj_list);
    this.UpdateRoleFormGroup.controls['proj_sol_list'].setValue(details.proj_sol_list);
    this.UpdateRoleFormGroup.controls['proj_sol_status'].setValue(details.proj_sol_status);
    this.UpdateRoleFormGroup.controls['sol_add'].setValue(details.sol_add);
    this.UpdateRoleFormGroup.controls['sol_edit'].setValue(details.sol_edit);
    this.UpdateRoleFormGroup.controls['sol_list'].setValue(details.sol_list);
    this.UpdateRoleFormGroup.controls['sol_status'].setValue(details.sol_status);
    this.UpdateRoleFormGroup.controls['overview'].setValue(details.overview);
    this.UpdateRoleFormGroup.controls['report'].setValue(details.report);
    this.UpdateRoleFormGroup.controls['roles'].setValue(details.roles);
    this.UpdateRoleFormGroup.controls['client_dashboard'].setValue(details.client_dashboard);
    this.UpdateRoleFormGroup.controls['client_mapping'].setValue(details.client_mapping);
    this.UpdateRoleFormGroup.controls['image_distribution'].setValue(details.image_distribution);
    this.UpdateRoleFormGroup.controls['deviance_report'].setValue(details.deviance_report);
    this.UpdateRoleFormGroup.controls['utilization'].setValue(details.utilization);
    this.UpdateRoleFormGroup.controls['exam'].setValue(details.exam);
    this.updateRoleModal.open();
  }

  addRoleSubmit(values){
    console.log(values);
  
    if(this.roleFormGroup.valid){
      Object.keys(values).forEach(item => {
        Object.values(values).forEach(element =>{
          console.log(values[item]);
          if(values[item] === null){
            values[item]  = false;
          }
        })
      });
      let formData = new FormData();
      formData = values;

      this.httpClient.request('post', this.ss.addRole,'',formData).subscribe((res: any) => {
        if (res.status === 200){
          if(res.error.text === 'success'){
          this.ss.statusMessage.showStatusMessage(true,"Role Added Succesfully");
          this.roleModal.close();
          this.NgFormRole.resetForm();
          this.getRoleList();
        }
        else if(res.error.text === 'role already exist'){
          this.ss.statusMessage.showStatusMessage(false,"Role already exist",3000,'roleexist');
        }
          // this.getSolutionDetails();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false,"Something went wrong",3000,'dberror');
        }
      });
    }

}

updateRoleSubmit(values){
  if(this.UpdateRoleFormGroup.valid){
    Object.keys(values).forEach(item => {
      Object.values(values).forEach(element =>{
        console.log(values[item]);
        if(values[item] === null){
          values[item]  = false;
        }
        if(values[item] === false){
          values[item]  = 0;
        }
      })
    });
    let formData = new FormData();
    formData = values;

    this.httpClient.request('post', this.ss.updateRole,'',formData).subscribe((res: any) => {
      if (res.status === 200){
        if(res.error.text === 'success'){
        this.ss.statusMessage.showStatusMessage(true,"Role Updated Succesfully");
        this.updateRoleModal.close();
        this.NgFormUpdateRole.resetForm();
        this.getRoleList();
      }
      }
      else {
        this.ss.statusMessage.showStatusMessage(false,"Something went wrong",3000,'dberror');
      }
    });
  }

}

}
