import { CandleStickComponent } from './chart-components/candle-stick/candle-stick.component';
import { AppRoutingModule } from './app-routing.module';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule,MAT_FORM_FIELD_DEFAULT_OPTIONS, MatSelectModule, MatTabsModule, MatCheckboxModule, MatButtonModule, MatTableModule, MatBadgeModule, MatDatepickerModule, MatNativeDateModule, MatInputModule, MatSliderModule, MatRadioButton, MatRadioModule, MatAutocompleteModule, MatBottomSheetModule, MatButtonToggleModule, MatCardModule, MatChipsModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatGridListModule, MatListModule, MatMenuModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRippleModule, MatSidenavModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatToolbarModule, MatTooltipModule, MatTreeModule, DateAdapter, MAT_DATE_LOCALE, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { LineChartComponent } from './chart-components/line-chart/line-chart.component';
import { GaugeChartComponent } from './chart-components/gauge-chart/gauge-chart.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MultiLineChartComponent } from "src/app/chart-components/multi-line-chart/multi-line-chart.component";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { LoaderComponent } from './components/loader/loader.component';
import { HttpClientService } from './services/http-client.service';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { XhrProgressService } from './services/xhr-progress.service';
import { LodashPipe } from './pipes/lodash.pipe';
import { StatusMessageComponent } from './components/status-message/status-message.component';
import { ModalPopupModule } from './components/modal-popup/modal-popup.module';
import { AnimationsService } from './services/animations.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FileDownloadModule } from './directives/file-download/file-download.module';
import { WindowReferenceService } from './services/window-reference.service';
import { DonutChartComponent } from './chart-components/donut-chart/donut-chart.component';
import { BarChartComponent } from './chart-components/bar-chart/bar-chart.component';
import { PieChartComponent } from './chart-components/pie-chart/pie-chart.component';
import { ChartsModule } from 'ng2-charts';
import { DataLablerComponent } from './view-components/data-labler/data-labler.component';
import { LabelerListComponent } from './view-components/labeler-list/labeler-list.component';
import { ProjectComponent } from './view-components/project/project.component';
import { OverviewComponent } from './view-components/overview/overview.component';
import { ReportsComponent } from './view-components/reports/reports.component';
import { DatePipe } from '@angular/common';
import { ProjectReportsComponent } from './view-components/project-reports/project-reports.component';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { MappingComponent } from './view-components/mapping/mapping.component';
import { TodayReportComponent } from './view-components/today-report/today-report.component';
import { DailyReportComponent } from './view-components/daily-report/daily-report.component';
import { DialogBodyComponent } from './components/dialog-body/dialog-body.component';
import { ClientUploadComponent } from './view-components/client-upload/client-upload.component';
import { FileDropDirective, FileSelectDirective} from 'ng2-file-upload';
import { ClientDashboardComponent } from './view-components/client-dashboard/client-dashboard.component';
import { ClientProjectMappingComponent } from './view-components/client-project-mapping/client-project-mapping.component';
import { ImageDistributionComponent } from './view-components/image-distribution/image-distribution.component';
import { JwtModule } from '@auth0/angular-jwt';
import { LoginComponent } from './view-components/login/login.component';
import { UserService } from './services/user.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthInterceptor } from './services/auth-interceptor';
import { RolesComponent } from './view-components/roles/roles.component';
import { SocketService } from './services/socket.service';
import { NotificationService } from './services/NotificationService';
import { UtilizationReportComponent } from './view-components/utilization-report/utilization-report.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FilterPipe } from './pipes/filter.pipe';
import { ExamComponent } from './view-components/exam/exam.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LineChartComponent,
    BreadcrumbsComponent,
    GaugeChartComponent,
    CandleStickComponent,
    MultiLineChartComponent,
    LoaderComponent,
    ProgressBarComponent,
    LodashPipe,
    StatusMessageComponent,
    DonutChartComponent,
    BarChartComponent,
    PieChartComponent,
    DataLablerComponent,
    LabelerListComponent,
    ProjectComponent,
    OverviewComponent,
    ReportsComponent,
    ProjectReportsComponent,
    MappingComponent,
    TodayReportComponent,
    DailyReportComponent,
    DialogBodyComponent,
    ClientUploadComponent,
    FileSelectDirective,
    FileDropDirective,
    ClientDashboardComponent,
    ClientProjectMappingComponent,
    ImageDistributionComponent,
    LoginComponent,
    FilterPipe,
    RolesComponent,
    UtilizationReportComponent,
    ExamComponent
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    ModalPopupModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatCheckboxModule,
    MatButtonModule,
    FormsModule,
    FileDownloadModule,
    MatTableModule,
    MatBadgeModule,
    MatSliderModule,
    MatRadioModule,
    ChartsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule, 
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NgxMatDrpModule,
    MatDialogModule,
    DragDropModule,
    ScrollingModule,
    JwtModule
  ],
  providers: [HttpClientService, XhrProgressService, AnimationsService, WindowReferenceService,DatePipe,UserService,AuthGuardService, NotificationService, SocketService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },{ provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }],
  bootstrap: [AppComponent],
  entryComponents: [DialogBodyComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
                 
})
export class AppModule { }
