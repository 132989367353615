import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit, HostListener, HostBinding, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'dbd-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('toggleMenu', [
      state(
        'true',
        style({
          'width': '225px'
        })
      ),
      state(
        'false',
        style({
          'width': '100px'
        })
      ),
      transition('*<=>*', [animate(
        '200ms'
      )])
    ])
  ]
})
export class SidebarComponent implements OnInit {

  overView = '../../../assets/images/Overview.png';
  addEmployee = '../../../assets/images/Add Employee.png';
  employeeList = '../../../assets/images/Employee List.png';
  project = '../../../assets/images/Projects.png';
  mapping = '../../../assets/images/MAPPING.png';
  report = '../../../assets/images/Report.png';

  static selectedIndex: number;
  userPriviliges: any;
  items: { text: string; link: string; icon: string; selectedIndex: number; active: any; }[];
  constructor(
    private ss: SingletonService,
    private user: UserService
  ) { }

  

  isMenuOpen: boolean = false;

  @Input() selectedIndex: number = 0;
  select(index: number) {
      this.selectedIndex = index;
  }

  ngOnInit() {    
    this.ss.isMenuOpenSubject.next(this.isMenuOpen);
     
      this.ss.setactive.subscribe(res => {
        this.selectedIndex = res
      }, err => {
        console.log(err);
      });
    
  }

  ngAfterViewInit(){
    // console.log(this.user.getRolePriviliges());
      this.user.currentMessage.subscribe(val=>{
        this.userPriviliges = val[0];
        this.items = [
          {text:'Overview', link:'/overview', icon:this.overView, selectedIndex: 0,active:this.userPriviliges.overview},
          // {text:'Dashboard', link:'/dashboard', icon:"dashboard", selectedIndex: 1},
          {text:'Add Employee', link:'/data-labeler', icon:this.addEmployee, selectedIndex: 1,active:this.userPriviliges.emp_add},
          {text:'Employee list', link:'/labeler-list', icon:this.employeeList, selectedIndex: 2,active:this.userPriviliges.emp_list},
          {text:'Projects', link:'/project', icon:this.project, selectedIndex: 3,active:this.userPriviliges.proj_list},
          {text:'Mapping', link:'/mapping', icon:this.mapping, selectedIndex: 4,active:this.userPriviliges.proj_sol_list},
          // {text:'Reports', link:'/reports', icon:"dashboard", selectedIndex: 5},
          // {text:'Today Report', link:'/today-report', icon:"dashboard", selectedIndex: 5},
          {text:'Report', link:'/daily-report', icon:this.report, selectedIndex: 5,active:this.userPriviliges.report},
          {text:'Exam', link:'/exam', icon:this.report, selectedIndex: 6,active:this.userPriviliges.exam},
          {text:'Roles', link:'/roles', icon:this.report, selectedIndex: 7,active:this.userPriviliges.roles},
          {text:'Utilization', link:'/utilization', icon:this.report, selectedIndex: 8,active:this.userPriviliges.utilization},
          {text:'Client-Dashboard', link:'/client-dashboard', icon:this.report, selectedIndex: 9,active:this.userPriviliges.client_dashboard},
          {text:'Client-Mapping', link:'/client-mapping', icon:this.report, selectedIndex: 10,active:this.userPriviliges.client_mapping},
          {text:'Image-Distribution', link:'/image-distribution', icon:this.report, selectedIndex: 11,active:this.userPriviliges.image_distribution}]
      })
      // console.log(this.items);
      
  }

 

  // @HostListener('click', ['$event', '$event.target'])
  onClick(e, target) {
    this.isMenuOpen = !this.isMenuOpen;
    this.ss.isMenuOpenSubject.next(this.isMenuOpen);
  }

  @HostBinding('@toggleMenu') get toggleMenu() {
    return this.isMenuOpen;
  }
  // @HostBinding('class.menu-open') get open() { return this.isMenuOpen; }
}
