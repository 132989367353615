import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { PollingService } from 'src/app/services/polling.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { HttpResponse } from '@angular/common/http';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  employeeList: any;
  employeeWiseProjects: any;
  employeeDailyHours: number = 8;
  datePickerInput: Date;
  filterData: any;
  filterTarget: any;
  filterFormGroup:FormGroup;
  chart;
  
  planModel: any = {start_time: new Date() };
  filterLength: any;
  role_id: any;
  rolePriviliges: any;
  userId: any;
  constructor(
    private httpClient: HttpClientService,
    private ss: SingletonService,
    private user:UserService,
    private formBuilder:FormBuilder,
    public datepipe: DatePipe
  ) { 
    this.filterFormGroup=  this.formBuilder.group({
      employees: ['',Validators.required],
      projects: ['',Validators.required],
      labelDate: [''],
      selectedDate: ['',Validators.required]
    });
  }
  public barChartOptions = {
    scaleShowVerticalLines: true,
    responsive: true,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero:true
          }
      }]
  }
  };
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData: ChartDataSets[] = [
    { data: [], label: 'series A' },
    { data: [], label: 'series B' }
  ];
  public barChartLabels: Label[] = [];
  public lineChartData: ChartDataSets[] = [
    { data: [], label: 'series A' },
    { data: [], label: 'series B' }
  ];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // grey
      backgroundColor: 'transparent',
      borderColor: '#d0743c',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'transparent',
      borderColor: '#7b6888',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // red
      backgroundColor: 'transparent',
      borderColor: '#a05d56',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';

  ngOnInit() {
    this.role_id = this.user.getRole();
    this.userId = this.user.getUserId();
    this.user.currentMessage.subscribe(val=>{
      this.rolePriviliges = val[0];
    });
    this.getActiveEmployees();
  }
  getActiveEmployees(){
    this.httpClient.request('get', this.ss.getActiveEmployeeList).subscribe((res: HttpResponse<any>) => {
      this.employeeList = res.body;
      if(this.role_id == 2){
        this.employeeList.forEach(element => {
          if(this.userId === element.emp_id){
            let output = [];
            output.push(element);
            this.employeeList = output;
            console.log(this.employeeList);
            
            console.log(this.employeeList[0].emp_id);
            this.filterFormGroup.controls['employees'].setValue(this.employeeList[0].emp_id);
          }
        });
      }
      });

  }
  getEmployeeWiseProjectList(emp_id){
    console.log(emp_id);
    this.httpClient.request('get', this.ss.getEmployeewiseProjectList, 'employeeId=' + emp_id).subscribe((res: HttpResponse<any>) => {
      this.employeeWiseProjects = res.body;
      console.log(this.employeeWiseProjects);
      
      
      });
    
  }
  filterSeacrch(value){
    if(this.filterFormGroup.valid){
      console.log(value);
    if(value.selectedDate === 'date'){
      value.selectedDate = moment(value.labelDate).format('YYYY-MM-DD');
      console.log(value);
  
      this.httpClient.request('get', this.ss.filterOutputDateWise,'filter='+JSON.stringify(value)).subscribe((res: HttpResponse<any>) => {
        this.filterData = res.body;

        var lineData:any = this.lineChartData[0].data =[];
        var barData:any = this.barChartData[0].data =[];
        // this.lineChartData[0].label= 'Label';
        this.lineChartLabels =[];
        this.barChartLabels =[];
        this.filterData.forEach(element => {
          lineData.push(element.output_count);
          barData.push(element.output_count);
          this.lineChartLabels.push(this.datepipe.transform(element.date, 'yyyy-MM-dd'))
          this.lineChartData[0].label = 'No of Images Labelled';
          this.barChartLabels.push(this.datepipe.transform(element.date, 'yyyy-MM-dd'))
          this.barChartData[0].label = 'No of Images Labelled';
        });
        console.log(this.filterData);
        this.filterLength = this.filterData.length;
        console.log(this.lineChartData[1].data);
        console.log(this.lineChartLabels);
        });
        this.httpClient.request('get', this.ss.filterTargetEmployeeWiseDateWise,'filter='+JSON.stringify(value)).subscribe((res: HttpResponse<any>) => {
          this.filterTarget = res.body;
          var dataLength = this.filterLength;
          var targetData:any = this.lineChartData[1].data =[];
          var barTargetData:any = this.barChartData[1].data =[];
          this.filterTarget.forEach(element => {
            
            targetData.push(element.value * this.employeeDailyHours);
            barTargetData.push(element.value * this.employeeDailyHours);
            this.lineChartData[1].label = 'Target';
            this.barChartData[1].label = 'Target';
          });
          value.selectedDate = 'date';
          console.log(this.lineChartData[1].data);
        });
    }
    else{
      
    this.httpClient.request('get', this.ss.filterOutput,'filter='+JSON.stringify(value)).subscribe((res: HttpResponse<any>) => {
      this.filterData = res.body;
      this.filterData = this.filterData.reverse();
      var lineData:any = this.lineChartData[0].data =[];
      this.lineChartData[0].label = '';
      this.lineChartLabels =[];
      this.barChartLabels =[];
      this.filterData.forEach(element => {
        
        lineData.push(element.output_count);
        // this.lineChartLabels.push(this.datepipe.transform(element.date, 'yyyy-MM-dd'))
        this.lineChartData[0].label = 'No of Images Labelled';
      });
      console.log(this.filterData);
      this.filterLength = this.filterData.length;
      console.log(this.lineChartData[0].data);
      console.log(this.lineChartLabels);
      });

     
      this.httpClient.request('get', this.ss.filterTargetEmployeeWise,'filter='+JSON.stringify(value)).subscribe((res: HttpResponse<any>) => {
        this.filterTarget = res.body;
        let targetData:any = this.lineChartData[1].data =[];
        let dataLength = this.filterLength;
        this.filterTarget.forEach(element => { 
            targetData.push(element.value * this.employeeDailyHours);
            this.lineChartLabels.push(this.datepipe.transform(element.datefield, 'yyyy-MM-dd'))
            this.lineChartData[1].label = 'Target';
            this.barChartData[1].label = 'Target';
        });
        
        console.log(this.lineChartData[1].data);
      });
    }
    }
  }
  addEvent(date: MatDatepickerInputEvent<Date>) {
    console.log(date);
    this.datePickerInput = date.value;
  }
}
