import { Component, OnInit, ViewChild } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { HttpResponse } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  @ViewChild(ModalPopupComponent, /* TODO: add static flag */ {static:false}) modal;
  @ViewChild('projectModal', /* TODO: add static flag */ {static:false}) projectModal:ModalPopupComponent;
  @ViewChild('updateProjectModal', /* TODO: add static flag */ {static:false}) updateProjectModal:ModalPopupComponent;
  @ViewChild('solutionModal', /* TODO: add static flag */ {static:false}) solutionModal:ModalPopupComponent;
  @ViewChild('updateSolutionModal', /* TODO: add static flag */ {static:false}) updateSolutionModal:ModalPopupComponent;
  @ViewChild('NgFormProject', /* TODO: add static flag */ {static:false}) NgFormProject;
  @ViewChild('NgFormUpdateProject', /* TODO: add static flag */ {static:false}) NgFormUpdateProject;
  @ViewChild('NgFormSolution', /* TODO: add static flag */ {static:false}) NgFormSolution;
  @ViewChild('NgFormUpdateSolution', /* TODO: add static flag */ {static:false}) NgFormUpdateSolution;
  projectFormGroup:FormGroup;
  updateProjectFormGroup: FormGroup;
  solutionFormGroup:FormGroup;
  UpdateSolutionFormGroup: FormGroup;
  
  active= new FormControl('');
  mappingActive = new FormControl('');
  projectList: any;
  solutionList: any;
  activeSolutionList: any;
  solutionIndex = null;
  solutionName: any;
  totalOutput: any;
  dataOutputObj: any[];
  totalInput: any;
  dataInputObj: any[];
  totalNewlyAdded: any;
  dataNewlyAddedObj: any[];
  totalAvailable: any[];
  totalAvailableSolution: any[];
  dataOutputObjSolution: any[];
  dataDeleteObjSolution: any[];
  dataDeleteObj: any[];
  role_id:any;
  rolePriviliges:any;
  annotationsList: any;
  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClientService,
    private ss: SingletonService,
    private user: UserService
  ) {
    // this.formGroup = this.formBuilder.group({
    //   'name': ['', Validators.required],
    //   'options': ['', Validators.required],
    //   'input': ['', Validators.required],
    //   'output': ['', Validators.required]
    // });
    this.projectFormGroup = this.formBuilder.group({
      'proj_name': ['', Validators.required,],
      'proj_note': [''],
      'proj_input': ['', Validators.required],
      'proj_output': ['', Validators.required],
      'sol_id':['', Validators.required],
      'annotationType':['', Validators.required]
    });
    this.updateProjectFormGroup = this.formBuilder.group({
      'proj_id':[''],
      'proj_name': ['', Validators.required],
      'proj_input': ['', Validators.required],
      'proj_output': ['', Validators.required]
    });
    this.solutionFormGroup = this.formBuilder.group({
      'cust_name': ['', Validators.required],
      'sol_name': ['', Validators.required]
    });
    this.UpdateSolutionFormGroup = this.formBuilder.group({
      'sol_id':[''],
      'cust_name': ['', Validators.required],
      'sol_name': ['', Validators.required]
    });
  
    
  }
  ngOnInit() {
    this.role_id = this.user.getRole();
    this.user.getUserId();
    this.user.currentMessage.subscribe(val=>{
      this.rolePriviliges = val[0];
    });
    this.getProjectDetails();
    this.getSolutionDetails();
    this.projectFormGroup.controls['proj_input'].disable();
    this.projectFormGroup.controls['proj_output'].disable();
    this.getTotalOutput();
    this.getTotalInput();
  }
  getSolutionName() {
    if(this.solutionIndex){
    this.solutionList.forEach(element => {
      if(element.sol_id === this.solutionIndex){
        this.solutionName =  element.sol_name;
      }
      
    });
  }
  }
  
  changeSolution(sol_id){
    if(this.solutionIndex) {
      this.solutionIndex = sol_id;
    }
  }
  getAnnotationTypes(){
    this.httpClient.request('get', "annotation/getAnnotationTypes").subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.annotationsList = res.body;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });  
  }

  getActiveSolutionDetails(){
    this.httpClient.request('get', this.ss.getActiveSolutionList).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.activeSolutionList = res.body;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getProjectDetails(){
    this.httpClient.request('get', this.ss.getProjectList).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.projectList = res.body;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getSolutionDetails(){
    this.httpClient.request('get', this.ss.getSolutionList).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.solutionList = res.body;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getTotalInput(){
    this.httpClient.request('get', this.ss.dayDataInputTotal).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.totalInput = res.body;

        let dataInputHolder = {};
          this.totalInput.forEach((d) => {
            if (dataInputHolder.hasOwnProperty(d.proj_id)) {
              dataInputHolder[d.proj_id] = dataInputHolder[d.proj_id] + d.input_count;
            } else {
              dataInputHolder[d.proj_id] = d.input_count;
            }
          });

          let dataInputHolderSolution = {};
          this.totalInput.forEach((d) => {
            if (dataInputHolderSolution.hasOwnProperty(d.sol_id)) {
              dataInputHolderSolution[d.sol_id] = dataInputHolderSolution[d.sol_id] + d.input_count;
            } else {
              dataInputHolderSolution[d.sol_id] = d.input_count;
            }
          });

          this.httpClient.request('get', this.ss.dayDataNewlyAddedTotal).subscribe((res: HttpResponse<any>) => {
            if (res.status === 200) {
              this.totalNewlyAdded = res.body;

        let dataNewlyAddedHolder = {};
        let dataNewlyAddedHolderSolution = {};

          this.totalNewlyAdded.forEach((d) => {
            if (dataNewlyAddedHolder.hasOwnProperty(d.proj_id)) {
              dataNewlyAddedHolder[d.proj_id] = dataNewlyAddedHolder[d.proj_id] + d.diff;
            } else {
              dataNewlyAddedHolder[d.proj_id] = d.diff;
            }
          });
          this.totalNewlyAdded.forEach((d) => {
            if (dataNewlyAddedHolderSolution.hasOwnProperty(d.sol_id)) {
              dataNewlyAddedHolderSolution[d.sol_id] = dataNewlyAddedHolderSolution[d.sol_id] + d.diff;
            } else {
              dataNewlyAddedHolderSolution[d.sol_id] = d.diff;
            }
          });
       
        this.dataInputObj = [];
        this.dataNewlyAddedObj = [];
        let dataInputObjSolution = [];
        let dataNewlyAddedObjSolution = [];

        for (var prop in dataInputHolder) {
          this.dataInputObj.push({ proj_id: prop, total_count: dataInputHolder[prop] });
        }
        for (var prop in dataNewlyAddedHolder) {
          this.dataNewlyAddedObj.push({ proj_id: prop, total_count: dataNewlyAddedHolder[prop] });
        }
        for (var prop in dataInputHolderSolution) {
          dataInputObjSolution.push({ sol_id: prop, total_count: dataInputHolderSolution[prop] });
        }
        for (var prop in dataNewlyAddedHolderSolution) {
          dataNewlyAddedObjSolution.push({ sol_id: prop, total_count: dataNewlyAddedHolderSolution[prop] });
        }
        console.log(this.dataInputObj);
        
        console.log(this.dataNewlyAddedObj);
        
        this.totalAvailable = [];
        this.totalAvailableSolution = [];
        
        // this.dataInputObj.forEach(elementInput => {
          this.dataNewlyAddedObj.forEach(element => {
            // if(elementInput.proj_id === element.proj_id){
              this.totalAvailable.push({'proj_id':element.proj_id,'total_count':(element.total_count)})
            // }
          });
        // });

        // dataInputObjSolution.forEach(elementInput => {
          dataNewlyAddedObjSolution.forEach(element => {
            // if(elementInput.sol_id === element.sol_id){
              this.totalAvailableSolution.push({'sol_id':element.sol_id,'total_count':(element.total_count)})
            // }
            });
        // });
        
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }});
  }

  getTotalOutput(){
    this.httpClient.request('get', this.ss.dayDataOutputTotal).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.totalOutput = res.body;
        let dataOutputHolder = {};
        let dataDeleteHolder = {};
        let dataOutputHolderSolution = {};
        let dataDeleteHolderSolution = {};
  
          this.totalOutput.forEach((d) => {
            if (dataOutputHolder.hasOwnProperty(d.proj_id)) {
              dataOutputHolder[d.proj_id] = dataOutputHolder[d.proj_id] + d.output_count;
            } else {
              dataOutputHolder[d.proj_id] = d.output_count;
            }
          });

          this.totalOutput.forEach((d) => {
            if (dataDeleteHolder.hasOwnProperty(d.proj_id)) {
              dataDeleteHolder[d.proj_id] = dataDeleteHolder[d.proj_id] + d.delete_count;
            } else {
              dataDeleteHolder[d.proj_id] = d.delete_count;
            }
          });

          this.totalOutput.forEach((d) => {
            if (dataOutputHolderSolution.hasOwnProperty(d.sol_id)) {
              dataOutputHolderSolution[d.sol_id] = dataOutputHolderSolution[d.sol_id] + d.output_count;
            } else {
              dataOutputHolderSolution[d.sol_id] = d.output_count;
            }
          });

          this.totalOutput.forEach((d) => {
            if (dataDeleteHolderSolution.hasOwnProperty(d.sol_id)) {
              dataDeleteHolderSolution[d.sol_id] = dataDeleteHolderSolution[d.sol_id] + d.delete_count;
            } else {
              dataDeleteHolderSolution[d.sol_id] = d.delete_count;
            }
          });
          
        this.dataOutputObj = [];
        this.dataDeleteObj = [];
        this.dataOutputObjSolution = [];
        this.dataDeleteObjSolution = [];

        for (var prop in dataOutputHolder) {
          this.dataOutputObj.push({ proj_id: prop, total_count: dataOutputHolder[prop] });
        }
        for (var prop in dataDeleteHolder) {
          this.dataDeleteObj.push({ proj_id: prop, total_count: dataDeleteHolder[prop] });
        }
        for (var prop in dataOutputHolderSolution) {
          this.dataOutputObjSolution.push({ sol_id: prop, total_count: dataOutputHolderSolution[prop] });
        }
        console.log(this.dataOutputObj);
        for (var prop in dataDeleteHolderSolution) {
          this.dataDeleteObjSolution.push({ sol_id: prop, total_count: dataDeleteHolderSolution[prop] });
        }
        
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  addProjectModal(){
    // this.NgFormProject.resetForm();
    this.projectFormGroup.reset();
    this.solutionName = '';
    this.projectFormGroup.controls['proj_name'].setValue('');
    this.projectFormGroup.controls['proj_output'].setValue('');
    this.getAnnotationTypes();
    this.projectModal.open();
    this.getActiveSolutionDetails();
  }

  addSolutionModal(){
    this.NgFormSolution.resetForm();
    this.solutionModal.open();
  }

  updateProjectModalOpen(details){
    this.NgFormUpdateProject.resetForm();
    this.updateProjectFormGroup.controls['proj_id'].setValue(details.proj_id);
    this.updateProjectFormGroup.controls['proj_name'].setValue(details.proj_name);
    this.updateProjectFormGroup.controls['proj_input'].setValue(details.input_folder);
    this.updateProjectFormGroup.controls['proj_output'].setValue(details.output_folder);
    this.updateProjectModal.open();
  }

  updateSolutionModalOpen(details){
    this.NgFormUpdateSolution.resetForm();
    this.UpdateSolutionFormGroup.controls['sol_id'].setValue(details.sol_id);
    this.UpdateSolutionFormGroup.controls['cust_name'].setValue(details.cust_name);
    this.UpdateSolutionFormGroup.controls['sol_name'].setValue(details.sol_name);
    this.updateSolutionModal.open();
  }

  AddProjectSubmit(value){
    console.log(value);
    value.proj_input = "work/" + this.solutionName + "/" + value.proj_name + "/input";
    value.proj_output = "work/" + this.solutionName + "/" + value.proj_name + "/output";
    value.proj_delete = "work/" + this.solutionName + "/" + value.proj_name + "/delete";
    value.proj_label = "work/" + this.solutionName + "/" + value.proj_name + "/labels";
    console.log(value);
    
    if(this.projectFormGroup.valid){
    this.httpClient.request('post', this.ss.addProject, '', value).subscribe((res: any) => {
      if (res.status === 200) {
        if(res.error.text == "Project added sucessfully"){
        this.ss.statusMessage.showStatusMessage(true, 'Project added sucessfully');
        this.projectModal.close();
        this.getProjectDetails();
        this.projectFormGroup.reset();
        }
        else if(res.error.text == "Project already exist"){
          this.ss.statusMessage.showStatusMessage(false, 'Project already exist',3000,'exist');
        }
       
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }
    
  }

  addSolutionSubmit(value){
    
    if(this.solutionFormGroup.valid){
      let formData = new FormData();
      formData = value;

      this.httpClient.request('post', this.ss.addSolution,'',formData).subscribe((res: any) => {
        if (res.status === 200){
          if(res.error.text === 'success'){
          this.ss.statusMessage.showStatusMessage(true,"Solution Added Succesfully");
          this.solutionModal.close();
          this.NgFormSolution.resetForm();
        }
        else if(res.error.text === 'Solution already exist'){
          this.ss.statusMessage.showStatusMessage(false,"Solution already exist");
        }
          this.getSolutionDetails();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false,"Something went wrong");
        }
      });
    }
    
  }

  UpdateProjectSubmit(value){

    if(this.updateProjectFormGroup.valid){
      var formData = new FormData();
      formData = value;

      this.httpClient.request('post', this.ss.updateProject,'',formData).subscribe((res: any) => {
        if(res.status === 200){
          if(res.error.text === 'Project Already Exist With Same Details'){
            this.ss.statusMessage.showStatusMessage(false,"Project Already Exist With Same Details");
          }
          else if(res.error.text === 'Project Updated Successfully'){
          this.ss.statusMessage.showStatusMessage(true,"Project Updated succesfully");
          this.updateProjectModal.close();
          this.getProjectDetails();
        }
        }
        else{
          this.ss.statusMessage.showStatusMessage(false,"Something went wrong");
        }
      });
    }
    
  }

  updateSolutionSubmit(value){

    if(this.UpdateSolutionFormGroup.valid){
      var formData = new FormData();
      formData = value;

      this.httpClient.request('post', this.ss.updateSolution,'',formData).subscribe((res: any) => {
        if(res.status === 200){
          if(res.error.text === 'Solution already exist'){
            this.ss.statusMessage.showStatusMessage(false,"Solution Already Exist With Same Details");
          }
          else if(res.error.text === 'success'){
          this.ss.statusMessage.showStatusMessage(true,"Solution Updated succesfully");
          this.updateSolutionModal.close();
          this.getSolutionDetails();
        }
        }
        else{
          this.ss.statusMessage.showStatusMessage(false,"Something went wrong");
        }
      });
    }
    
  }



  activeStatus(value){
    
    if(value == 1){
      this.active.setValue(true);
    }
    if(value == 0){
      this.active.setValue(false);
    }
  }

  onChangeProjectStatus(id,value){
    let status;
    if(value == true){
      status = 1;
    }
    else{
      status = 0;
    }
    let formData = {};
    formData['status'] = status;
    formData['id']=id;
  
    this.httpClient.request('post', this.ss.updateProjectStatus, '', formData).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        this.ss.statusMessage.showStatusMessage(true, "Project Status updated succesfully");
        this.getProjectDetails();
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
    
  }

  onChangeSolutionStatus(id,value){
    let status;
    if(value == true){
      status = 1;
    }
    else{
      status = 0;
    }
    let formData = {};
    formData['status'] = status;
    formData['id']=id;
  
    this.httpClient.request('post', this.ss.updateSolutionStatus, '', formData).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        this.ss.statusMessage.showStatusMessage(true, "Solution Status updated succesfully");
        this.getSolutionDetails();
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

}
