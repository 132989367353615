import { Component, OnInit, ViewChild } from '@angular/core';
import { SingletonService } from '../../services/singleton.service';
import { HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { HttpClientService } from 'src/app/services/http-client.service';
import { PollingService } from 'src/app/services/polling.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as moment from 'moment'
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { DatePipe } from '@angular/common';
import * as jsPDF from 'jspdf';
import { UnSubscribe } from 'src/app/modules/unsubscribe';
import { UserService } from 'src/app/services/user.service';
import { json } from 'd3';
// import { interval, Subscription } from 'rxjs';

// import moment = require('moment');
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})

export class OverviewComponent implements OnInit {

  today = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  range: any;
  options: NgxDrpOptions;
  @ViewChild('pickerOne', /* TODO: add static flag */ { static: false }) pickerOne;
  @ViewChild('canvasGraph', /* TODO: add static flag */ { static: false }) canvasGraph;
  mappingList: any;
  capacity: number;
  overallCapacity: number;
  employeeDailyHours: number = 8;
  InputData: any;
  inputData: any[];
  input_no: any;
  output_no: number;
  hoursRemaining: any;
  barChartdata: any;
  hoursDone: number;
  todayDate: string;
  empPerformance: number;
  filterFormGroup: FormGroup;
  displayViewForm: FormGroup;
  projectList: any;
  solutionList: any;
  totalTarget: number;
  currentDataList: any;
  employeeList: any;
  dataIO: any;
  dataCapacity: any;
  active: boolean = false;
  timeoutRef;
  workedHoursData: any;
  role_id: any;
  rolePriviliges: any;
  userId: any;
  devianceList: any;
  currentAnnotationDataList: any[];
  showAnnotationGraph: boolean = false;
  diffLegend :any ;
  ProjData: any;

  constructor(
    private httpClient: HttpClientService,
    private ss: SingletonService,
    private pollingService: PollingService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private user: UserService
  ) {
    this.filterFormGroup = this.formBuilder.group({
      solutions: [''],
      projects: [''],
      employees: ['']
    });

    this.displayViewForm = this.formBuilder.group({
      viewOptions: ['']
    });

  }

  public pieChartLabels = [];
  public pieChartData = [];
  public pieChartType = 'pie';
  public pieCapacityChartLabels = [];
  public pieOutputChartLabels = [];
  public pieOutpuChartData = [];
  barchatColors = ["#7b6888", "#6b486b", "#a05d56", "#d0743c", "#ff8c00", "#d0833c"];
  pieChartColors = [
    {
      backgroundColor: [
        "#7b6888", "#6b486b", "#a05d56", "#d0743c", "#ff8c00", "#d0833c", "#7b6888", "#6b486b", "#a05d56", "#d0743c", "#ff8c00", "#d0833c"
      ]
    }
  ];

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public barChartDataSingle = [];
  public barChartLabelsSingle = [];
  public barChartTypeSingle = 'horizontalBar';
  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    { data: [], label: 'Series A' },
    { data: [], label: 'Series B' },
    { data: [], label: 'Series C' }
  ];

  public lineChartData = [
    { data: [], label: 'series A' },
    { data: [], label: 'series B' },
    { data: [], label: 'series C', hidden: true }
  ];


  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions & { annotation: any }) = this.ss.lineChartOptionsDisable;
  public lineChartColors: Color[] = this.ss.lineChartColorsLiveData;
  public lineChartLegend = true;
  public lineChartType = 'line';

  //annotation graph

  public annotationChartData = [
    { data: [], label: 'series A' },
    { data: [], label: 'series B' },
    { data: [], label: 'series C' },
    { data: [], label: 'series D' },
    { data: [], label: 'series E' }
  ]

  public annotationChartLabels: Label[] = [];
  public annotationChartOptions: (ChartOptions & { annotation: any }) = this.ss.annotationOptionsDisable;
  public annotationChartColors: Color[] = this.ss.lineChartColors;
  public annotationChartLegend = true;
  public annotationChartType = 'line';

  ngOnInit() {
    this.role_id = this.user.getRole();
    this.user.getUserId();
    this.user.currentMessage.subscribe(val => {
      this.rolePriviliges = val[0];
    });
    this.todayDate = this.datepipe.transform(this.today, 'dd-MM-yyyy');
    this.activeEmployees();
    this.activeSolutions();
    this.currentData();
    this.activeProjects();
    this.polling(true);
    this.user.getToken();
    this.getDevianceList();

    // this.subscription = this.source.subscribe(val => console.log(this.text)
    // );
  }

  polling(token) {
    this.timeoutRef = setTimeout(() => {
      this.activeSolutions();
      if (token) {
        this.polling(true);
      }
    }, 1800000);
  }

  stopPolling() {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }

  graphList = [{ "graph_id": "2", "graph_name": "Annotation" }];
  displayGraph(value) {
    if (value == "Annotation") {
      this.showAnnotationGraph = true;
    }
    else {
      this.showAnnotationGraph = false;
    }
  }

  activeSolutionsList() {
    this.httpClient.request('get', this.ss.getHourDataSolutionList).subscribe((res: HttpResponse<any>) => {
      this.solutionList = res.body;
      console.log(this.solutionList);
    });
  }

  getDevianceList() {
    let userId = this.user.getUserId();
    this.httpClient.request('get', this.ss.getDevianceData, 'emp_id='+ this.userId).subscribe((res: HttpResponse<any>) => {
      let temp = res.body;
      console.log(temp);
      this.devianceList = [];
      temp.forEach(element => {
        if (element.emp_id === this.userId) {
          this.devianceList.push(element);
        }
      });
      // console.log(this.devianceList);
    });
  }

  activeSolutions() {
    this.httpClient.request('get', this.ss.getHourDataSolutionList).subscribe((res: HttpResponse<any>) => {
      this.solutionList = res.body;
      // console.log(this.solutionList);
      this.filterSearch(this.filterFormGroup.value);
    });
  }

  activeProjects() {
    this.httpClient.request('get', this.ss.getHourdataProjectList).subscribe((res: HttpResponse<any>) => {
      this.projectList = res.body;
    });
  }

  activeEmployees() {
    this.role_id = this.user.getRole();
    this.userId = this.user.getUserId();
    this.httpClient.request('get', this.ss.getHourDataEmployeeList).subscribe((res: HttpResponse<any>) => {
      this.employeeList = res.body;
      if (this.role_id == 2) {
        this.employeeList.forEach(element => {
          if (this.userId === element.emp_id) {
            let output = [];
            output.push(element);
            this.employeeList = output;
            // console.log(this.employeeList);

            // console.log(this.employeeList[0].emp_id);
            this.filterFormGroup.controls['employees'].setValue(this.employeeList[0].emp_id);
          }
        });
        this.employeeWiseData(this.userId);
      }
    });
  }

  filterSearch(value) {
    this.annotationGraph(value);
    if (value.solutions == -1) {
      value.solutions = '';
    }
    if (value.projects == -1) {
      value.projects = '';
    }
    if (value.employees == -1) {
      value.employees = '';
    }

    this.httpClient.noLoader(true).request('get', this.ss.filterSearch, 'values=' + JSON.stringify(value)).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        this.dataIO = res.body;
        // alert(JSON.stringify(res.body));
        let dataInputtHolder = {};
        let dataOutputHolder = {};

        this.dataIO.forEach(function (d) {
          if (dataInputtHolder.hasOwnProperty(d.hour)) {
            dataInputtHolder[d.hour] = dataInputtHolder[d.hour] + d.input_count;
          } else {
            dataInputtHolder[d.hour] = d.input_count;
            // alert(JSON.stringify(dataInputtHolder));
          }
        });


        // console.log(dataInputtHolder);

        this.dataIO.forEach((d) => {
          if (dataOutputHolder.hasOwnProperty(d.hour)) {
            dataOutputHolder[d.hour] = dataOutputHolder[d.hour] + d.output_count;
          } else {
            dataOutputHolder[d.hour] = d.output_count;
          }
        });

        this.httpClient.noLoader(true).request('get', this.ss.filterSearchTarget, 'values=' + JSON.stringify(value)).subscribe((res: HttpResponse<any>) => {
          if (res.status === 200) {
            this.dataCapacity = res.body;

            let date = new Date();
            let hour: any = date.getHours();
            hour = (hour < 10 ? "0" : "") + hour;

            this.input_no = 0;
            this.output_no = 0;

            this.httpClient.noLoader(true).request('get', this.ss.filterSearchworkedHours, 'values=' + JSON.stringify(value)).subscribe((res: HttpResponse<any>) => {
              if (res.status === 200) {
                this.workedHoursData = res.body;
                
                console.log(this.dataCapacity);
                console.log(this.workedHoursData);
                
                this.totalTarget = 0;
                this.dataCapacity.forEach((element) => {
                  if (this.workedHoursData.length > 0) {
                    this.workedHoursData.forEach(elementWork => {
                      if (element.emp_id == elementWork.emp_id) {
                        if(element.proj_id == elementWork.proj_id){
                          element.employeeDailyHours = elementWork.workedhours;
                        }
                      }
                      else {
                        element.employeeDailyHours = this.employeeDailyHours - elementWork.workedhours;
                      }
                    });
                  }
                  else {
                    element.employeeDailyHours = this.employeeDailyHours;
                  }
                  // this.totalTarget = this.totalTarget + element.target_count;
                });

                let dataWorkHoursHolder = {};
                this.workedHoursData.forEach(function (d) {
                  if (dataWorkHoursHolder.hasOwnProperty(d.emp_id)) {
                    dataWorkHoursHolder[d.emp_id] = dataWorkHoursHolder[d.emp_id] + d.workedhours;
                  } else {
                    dataWorkHoursHolder[d.emp_id] = d.workedhours;
                  }
                });

                let dataWorkHoursObj = [];

                for (var prop in dataWorkHoursHolder) {
                  dataWorkHoursObj.push({ emp_id: prop, workedhours: dataWorkHoursHolder[prop] });
                  // alert(JSON.stringify(dataInputObj));
                }


                var result = this.dataCapacity.filter(o => !this.workedHoursData.find(o2 => (o.emp_id === o2.emp_id && o.proj_id === o2.proj_id)))
                  console.log(result);
                  
                result.forEach(element => {
                  dataWorkHoursObj.forEach(element1 =>{
                    if(element.emp_id == element1.emp_id){
                      element.employeeDailyHours = this.employeeDailyHours - parseInt(element1.workedhours);
                    }
                  })
                });
                console.log(dataWorkHoursObj);
                console.log(result);
                
                
                result.forEach(element1 => {
                this.dataCapacity.forEach(element => {
                    if(element.emp_id === element1.emp_id && element.proj_id === element1.proj_id){
                        element.employeeDailyHours = element1.employeeDailyHours;
                    }
                  });
                });
                
                console.log(this.dataCapacity);
                
                this.dataCapacity.forEach(element => {
                  this.totalTarget = this.totalTarget + (element.target_count * element.employeeDailyHours);
                });
                console.log(this.totalTarget);

                let dataInputObj = [];
                let dataOutputObj = [];

                for (var prop in dataInputtHolder) {
                  dataInputObj.push({ proj_id: prop, input_count: dataInputtHolder[prop] });
                  // alert(JSON.stringify(dataInputObj));
                }


                for (var prop in dataOutputHolder) {
                  dataOutputObj.push({ proj_id: prop, output_count: dataOutputHolder[prop] });
                }
                console.log(dataInputObj);
                console.log(dataOutputObj.length);

                this.lineChartData[0].data = [];
                this.lineChartData[1].data = [];
                this.lineChartData[2].data = [];
                this.lineChartLabels = [];

                dataInputObj.forEach(elementInput => {
                  dataOutputObj.forEach(elementOutput => {
                    if (elementInput.proj_id === elementOutput.proj_id) {
                      this.lineChartData[0].label = 'Available';
                      this.lineChartLabels.push(elementInput.proj_id + ' 00')
                      this.lineChartData[0].data.push(elementInput.input_count);
                      this.lineChartData[1].label = 'Completed';
                      this.lineChartData[1].data.push(elementOutput.output_count);
                      this.lineChartData[2].label = 'Capacity';
                      this.lineChartData[2].data.push(this.totalTarget);
                    }
                  });
                });

                this.httpClient.noLoader(true).request('get', this.ss.getLabelingDataFilterWise, 'values=' + JSON.stringify(value)).subscribe((res: any) => {
                  if (res.status === 200) {
                    this.currentDataList = [];

                    this.currentDataList = res.body;
                    this.active = true;
                    // console.log(this.currentDataList);
                    if (this.currentDataList) {
                      this.active = false;
                    }
                    this.input_no = 0;
                    this.output_no =  0;
                    this.currentDataList.forEach(element => {
                      if (element.hour === parseInt(hour)) {
                        this.input_no = this.input_no + element.input_count;
                        this.output_no = this.output_no + element.output_count;
                      }
                    });
                    // console.log(this.output_no);
                    this.overallCapacity = 0;
                    this.dataCapacity.forEach((element) => {
                      this.overallCapacity = (this.overallCapacity + (element.target_count * element.employeeDailyHours));
                    });
                    this.overallCapacity = this.overallCapacity;
                    this.empPerformance = (this.output_no / this.overallCapacity) * 100;
                    // console.log(this.dataCapacity);
                    this.empPerformance = (this.output_no / this.overallCapacity) * 100;

                    this.hoursDone = (this.output_no / this.overallCapacity) * this.employeeDailyHours;
                    // console.log(this.hoursDone);
                    this.hoursRemaining = (this.input_no / this.overallCapacity) * this.employeeDailyHours;

                    let currentInputtHolder = {};
                    let currentOutputHolder = {};

                    this.currentDataList.forEach(function (d) {
                      if (currentInputtHolder.hasOwnProperty(d.minute)) {
                        currentInputtHolder[d.minute] = currentInputtHolder[d.minute] + d.input_count;
                      } else {
                        currentInputtHolder[d.minute] = d.input_count;
                      }
                    });

                    this.currentDataList.forEach((d) => {
                      if (currentOutputHolder.hasOwnProperty(d.minute)) {
                        currentOutputHolder[d.minute] = currentOutputHolder[d.minute] + d.output_count;
                      } else {
                        currentOutputHolder[d.minute] = d.output_count;
                      }
                    });

                    let currentInputObj = [];
                    let currentOutputObj = [];

                    for (var prop in currentInputtHolder) {
                      currentInputObj.push({ proj_id: prop, input_count: currentInputtHolder[prop] });
                    }

                    for (var prop in currentOutputHolder) {
                      currentOutputObj.push({ proj_id: prop, output_count: currentOutputHolder[prop] });
                    }
                    
                    currentInputObj.forEach(elementInput => {
                      currentOutputObj.forEach(elementOutput => {
                        if (elementInput.proj_id === elementOutput.proj_id) {
                          this.lineChartLabels.push(elementInput.proj_id + ' ');
                          this.lineChartData[0].data.push(elementInput.input_count);
                          this.lineChartData[1].data.push(elementOutput.output_count);
                        }
                      });
                    });
                  }
                });

              }
            });
          }
        });
      }
    });
  }

  annotationGraph(value) {
    this.annotationChartData = [];
    this.annotationChartData = [
      { data: [], label: 'series A' },
      { data: [], label: 'series B' },
      { data: [], label: 'series C' },
      { data: [], label: 'series D' },
      { data: [], label: 'series E' }
    ];
    if (value.solutions == -1) {
      value.solutions = '';
    }
    if (value.projects == -1) {
      value.projects = '';
    }
    if (value.employees == -1) {
      value.employees = '';
    }

    this.httpClient.noLoader(true).request('get', "annotation/annotation-data-with-filter-wise", 'values=' + JSON.stringify(value)).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        this.dataIO = res.body;
        this.ProjData = res.body;
        //  alert(JSON.stringify(res.body));
        let dataRectCompletedHolder = {};
        let dataPolygonCompletedHolder = {};
        let dataLandmarkCompletedHolder = {};
        let dataPolylineCompletedHolder = {};
        let dataOthersCompleteHolder = {};
        // console.log("----------response data------------"+JSON.stringify(res.body));
        this.dataIO.forEach(function (d) {
          if (d.annot_id == 1) {
            if (dataRectCompletedHolder.hasOwnProperty(d.hour)) {
              dataRectCompletedHolder[d.hour] = dataRectCompletedHolder[d.hour] + d.count;
            } else {
              dataRectCompletedHolder[d.hour] = d.count;
            }
          }
          // alert(JSON.stringify(dataRectCompletedHolder));
          if (d.annot_id == 2) {
            if (dataPolygonCompletedHolder.hasOwnProperty(d.hour)) {
              dataPolygonCompletedHolder[d.hour] = dataPolygonCompletedHolder[d.hour] + d.count;
            } else {
              dataPolygonCompletedHolder[d.hour] = d.count;
            }
          }
          // alert(JSON.stringify(dataPolygonCompletedHolder));

          if (d.annot_id == 3) {
            if (dataLandmarkCompletedHolder.hasOwnProperty(d.hour)) {
              dataLandmarkCompletedHolder[d.hour] = dataLandmarkCompletedHolder[d.hour] + d.count;
            } else {
              dataLandmarkCompletedHolder[d.hour] = d.count;
            }
          }
          // alert(JSON.stringify(dataLandmarkCompletedHolder));
          if (d.annot_id == 4) {
            if (dataPolylineCompletedHolder.hasOwnProperty(d.hour)) {
              dataPolylineCompletedHolder[d.hour] = dataPolylineCompletedHolder[d.hour] + d.count;
            } else {
              dataPolylineCompletedHolder[d.hour] = d.count;
            }
          }
          if (d.annot_id == 5) {
            if (dataOthersCompleteHolder.hasOwnProperty(d.hour)) {
              dataOthersCompleteHolder[d.hour] = dataOthersCompleteHolder[d.hour] + d.count;
            } else {
              dataOthersCompleteHolder[d.hour] = d.count;
            }
          }
          

        });


        this.annotationChartData[0].data = [];
        this.annotationChartData[1].data = [];
        this.annotationChartData[2].data = [];
        this.annotationChartData[3].data = [];
        this.annotationChartData[4].data = [];
        this.annotationChartLabels = [];

        let dataRectCompletedHolderObj = [];
        let dataPolygonCompletedHolderObj = [];
        let dataLandmarkCompletedHolderObj = [];
        let dataPolylineCompletedHolderObj = [];
        let dataOthersCompleteHolderObj = [];


        for (var prop in dataRectCompletedHolder) {
          dataRectCompletedHolderObj.push({ proj_id: prop, completed_count: dataRectCompletedHolder[prop] });
          // alert(JSON.stringify(dataRectCompletedHolderObj));
        }

        for (var prop in dataPolygonCompletedHolder) {
          dataPolygonCompletedHolderObj.push({ proj_id: prop, completed_count: dataPolygonCompletedHolder[prop] });
          // alert(JSON.stringify(dataPolygonCompletedHolderObj));
        }

        for (var prop in dataLandmarkCompletedHolder) {
          dataLandmarkCompletedHolderObj.push({ proj_id: prop, completed_count: dataLandmarkCompletedHolder[prop] });
          // alert(JSON.stringify(dataLandmarkCompletedHolderObj));
        }

        for (var prop in dataPolylineCompletedHolder) {
          dataPolylineCompletedHolderObj.push({ proj_id: prop, completed_count: dataPolylineCompletedHolder[prop] });
          // alert(JSON.stringify(dataPolylineCompletedHolderObj));
        }

        for (var prop in dataOthersCompleteHolder) {
          dataOthersCompleteHolderObj.push({ proj_id: prop, completed_count: dataOthersCompleteHolder[prop] });
          // alert(JSON.stringify(dataPolylineCompletedHolderObj));
        }
        //Checking if length is 0
        let hourHolder = {};
        this.dataIO.forEach(function (d) {
          if (hourHolder.hasOwnProperty(d.hour)) {
            hourHolder[d.hour] = hourHolder[d.hour] + d.count;
          } else {
            hourHolder[d.hour] = d.count;
          }
        })


        if (dataRectCompletedHolderObj.length != 0 && dataRectCompletedHolderObj.length != Object.keys(hourHolder).length) {
          for (var prop in hourHolder) {
            if (_.find(dataRectCompletedHolderObj, function (o) { return o.proj_id === prop; }) == undefined) {
              dataRectCompletedHolderObj.push({ proj_id: prop, completed_count: "NA" });
            }
          }
        }

        if (dataPolygonCompletedHolderObj.length != 0 && dataPolygonCompletedHolderObj.length != Object.keys(hourHolder).length) {
          for (var prop in hourHolder) {
            if (_.find(dataPolygonCompletedHolderObj, function (o) { return o.proj_id === prop; }) == undefined) {
              dataPolygonCompletedHolderObj.push({ proj_id: prop, completed_count: "NA" });
            }
          }
        }

        if (dataLandmarkCompletedHolderObj.length != 0 && dataLandmarkCompletedHolderObj.length != Object.keys(hourHolder).length) {
          for (var prop in hourHolder) {
            if (_.find(dataLandmarkCompletedHolderObj, function (o) { return o.proj_id === prop; }) == undefined) {
              dataLandmarkCompletedHolderObj.push({ proj_id: prop, completed_count: "NA" });
            }
          }
        }

        if (dataPolylineCompletedHolderObj.length != 0 && dataPolylineCompletedHolderObj.length != Object.keys(hourHolder).length) {
          for (var prop in hourHolder) {
            if (_.find(dataPolylineCompletedHolderObj, function (o) { return o.proj_id === prop; }) == undefined) {
              dataPolylineCompletedHolderObj.push({ proj_id: prop, completed_count: "NA" });
            }
          }
        }

        if (dataOthersCompleteHolderObj.length != 0 && dataOthersCompleteHolderObj.length != Object.keys(hourHolder).length) {
          for (var prop in hourHolder) {
            if (_.find(dataOthersCompleteHolderObj, function (o) { return o.proj_id === prop; }) == undefined) {
              dataOthersCompleteHolderObj.push({ proj_id: prop, completed_count: "NA" });
            }
          }
        }

        if (dataRectCompletedHolderObj.length == 0) {
          for (var prop in hourHolder) {
            dataRectCompletedHolderObj.push({ proj_id: prop, completed_count: "NA" });
          }
        }
        if (dataPolygonCompletedHolderObj.length == 0) {
          for (var prop in hourHolder) {
            dataPolygonCompletedHolderObj.push({ proj_id: prop, completed_count: "NA" });
          }
        }
        // alert(JSON.stringify(dataPolygonCompletedHolderObj));

        if (dataLandmarkCompletedHolderObj.length == 0) {
          for (var prop in hourHolder) {
            dataLandmarkCompletedHolderObj.push({ proj_id: prop, completed_count: "NA" });
          }
        }
        // alert(JSON.stringify(dataLandmarkCompletedHolderObj));
        if (dataPolylineCompletedHolderObj.length == 0) {
          for (var prop in hourHolder) {
            dataPolylineCompletedHolderObj.push({ proj_id: prop, completed_count: "NA" });
          }
        }
        if (dataOthersCompleteHolderObj.length == 0) {
          for (var prop in hourHolder) {
            dataOthersCompleteHolderObj.push({ proj_id: prop, completed_count: "NA" });
          }
        }


        // console.log("---------landmark--------------" + JSON.stringify(dataLandmarkCompletedHolderObj));

        dataRectCompletedHolderObj.forEach(rectOutput => {
          // alert("----------data stored value---------"+ JSON.stringify(dataRectCompletedHolderObj));
          dataPolygonCompletedHolderObj.forEach(polygonOutput => {

            dataLandmarkCompletedHolderObj.forEach(landmarkOutput => {

              dataPolylineCompletedHolderObj.forEach(polylineOutput => {

                dataOthersCompleteHolderObj.forEach(otherOutput => {
                  
                if ((rectOutput.proj_id === polygonOutput.proj_id) && (landmarkOutput.proj_id === polylineOutput.proj_id) && (polygonOutput.proj_id === polylineOutput.proj_id) && (polylineOutput.proj_id === otherOutput.proj_id) ) {
                  this.annotationChartLabels.push(rectOutput.proj_id + ' 00')
                  this.annotationChartData[0].label = 'BoundingBox';
                  this.annotationChartData[0].data.push(rectOutput.completed_count);
                  this.annotationChartData[1].label = 'Polygon';
                  this.annotationChartData[1].data.push(polygonOutput.completed_count);
                  this.annotationChartData[2].label = 'LandMarks';
                  this.annotationChartData[2].data.push(landmarkOutput.completed_count);
                  this.annotationChartData[3].label = 'Polyline';
                  this.annotationChartData[3].data.push(polylineOutput.completed_count);
                  this.annotationChartData[4].label = 'Others';
                  this.annotationChartData[4].data.push(otherOutput.completed_count);
                }

              });
              });
            });
          });
        });


        //get current annotation data

        this.httpClient.noLoader(true).request('get', "get_current_annotation_data_filter_wise", 'values=' + JSON.stringify(value)).subscribe((res: any) => {
          if (res.status === 200) {
            this.currentAnnotationDataList = [];
            
            this.currentAnnotationDataList = res.body;
            this.annotationChartLabels.push(this.currentAnnotationDataList[0].minute);
            this.active = true;
            console.log(this.currentAnnotationDataList);
            if (this.currentAnnotationDataList) {
              this.active = false;
            }

            let dataRectCompletedHolder = {};
            let dataPolygonCompletedHolder = {};
            let dataLandmarkCompletedHolder = {};
            let dataPolylineCompletedHolder = {};
            let otherCompleteHolder = {};

            this.currentAnnotationDataList.forEach(function (d) {
              if (d.annot_id == 1) {
                if (dataRectCompletedHolder.hasOwnProperty(d.minute)) {
                  dataRectCompletedHolder[d.minute] = dataRectCompletedHolder[d.minute] + parseInt(d.count);
                } else {
                  dataRectCompletedHolder[d.minute] = parseInt(d.count);
                }
              }
              // alert(JSON.stringify(dataRectCompletedHolder));
              if (d.annot_id == 2) {
                if (dataPolygonCompletedHolder.hasOwnProperty(d.minute)) {
                  dataPolygonCompletedHolder[d.minute] = dataPolygonCompletedHolder[d.minute] + parseInt(d.count);
                } else {
                  dataPolygonCompletedHolder[d.minute] = parseInt(d.count);
                }
              }
              // alert(JSON.stringify(dataPolygonCompletedHolder));

              if (d.annot_id == 3) {
                if (dataLandmarkCompletedHolder.hasOwnProperty(d.minute)) {
                  dataLandmarkCompletedHolder[d.minute] = dataLandmarkCompletedHolder[d.minute] + parseInt(d.count);
                } else {
                  dataLandmarkCompletedHolder[d.minute] = parseInt(d.count);
                }
              }
              // alert(JSON.stringify(dataLandmarkCompletedHolder));
              if (d.annot_id == 4) {
                if (dataPolylineCompletedHolder.hasOwnProperty(d.minute)) {
                  dataPolylineCompletedHolder[d.minute] = dataPolylineCompletedHolder[d.minute] + parseInt(d.count);
                } else {
                  dataPolylineCompletedHolder[d.minute] = parseInt(d.count);
                }
              }
              if (d.annot_id == 5) {
                if (otherCompleteHolder.hasOwnProperty(d.minute)) {
                  otherCompleteHolder[d.minute] = otherCompleteHolder[d.minute] + parseInt(d.count);
                } else {
                  otherCompleteHolder[d.minute] = parseInt(d.count);
                }
              }
            });

            let dataRectCompletedHolderObj = [];
            let dataPolygonCompletedHolderObj = [];
            let dataLandmarkCompletedHolderObj = [];
            let dataPolylineCompletedHolderObj = [];
            let dataOtherCompletedHolderObj = [];

            for (var prop in dataRectCompletedHolder) {
              dataRectCompletedHolderObj.push({ proj_id: prop, completed_count: dataRectCompletedHolder[prop] });
              // alert(JSON.stringify(dataRectCompletedHolderObj));
            }

            for (var prop in dataPolygonCompletedHolder) {
              dataPolygonCompletedHolderObj.push({ proj_id: prop, completed_count: dataPolygonCompletedHolder[prop] });
              // alert(JSON.stringify(dataPolygonCompletedHolderObj));
            }

            for (var prop in dataLandmarkCompletedHolder) {
              dataLandmarkCompletedHolderObj.push({ proj_id: prop, completed_count: dataLandmarkCompletedHolder[prop] });
              // alert(JSON.stringify(dataLandmarkCompletedHolderObj));
            }

            for (var prop in dataPolylineCompletedHolder) {
              dataPolylineCompletedHolderObj.push({ proj_id: prop, completed_count: dataPolylineCompletedHolder[prop] });
              // alert(JSON.stringify(dataPolylineCompletedHolderObj));
            }

            for (var prop in otherCompleteHolder) {
              dataOtherCompletedHolderObj.push({ proj_id: prop, completed_count: otherCompleteHolder[prop] });
              // alert(JSON.stringify(dataPolylineCompletedHolderObj));
            }

            //Checking if length is 0
            // let hourHolder = {};
            // this.currentAnnotationDataList.forEach(function (d) {
            //   if (hourHolder.hasOwnProperty(d.minute)) {
            //     hourHolder[d.minute] = hourHolder[d.minute] + d.count;
            //   } else {
            //     hourHolder[d.minute] = d.count;
            //   }
            // })
            console.log(dataPolygonCompletedHolder);
            
            dataRectCompletedHolderObj.forEach(rectOutput => { 
              
              this.annotationChartData[0].data.push(rectOutput.completed_count);
            
            });
            dataPolygonCompletedHolderObj.forEach(polygonOutput => {
             
              this.annotationChartData[1].data.push(polygonOutput.completed_count);
            
             });
             dataLandmarkCompletedHolderObj.forEach(landmarkOutput => {
            
              this.annotationChartData[2].data.push(landmarkOutput.completed_count);
              
            });
            dataPolylineCompletedHolderObj.forEach(polylineOutput => {
              
              this.annotationChartData[3].data.push(polylineOutput.completed_count);
            
             });
             dataOtherCompletedHolderObj.forEach(othersOutput => {
            
              this.annotationChartData[4].data.push(othersOutput.completed_count);
              
            });
            


            // dataRectCompletedHolderObj.forEach(rectOutput => {
            //   // alert("----------data stored value---------"+ JSON.stringify(dataRectCompletedHolderObj));
            //   dataPolygonCompletedHolderObj.forEach(polygonOutput => {

            //     dataLandmarkCompletedHolderObj.forEach(landmarkOutput => {

            //       dataPolylineCompletedHolderObj.forEach(polylineOutput => {

            //         dataOtherCompletedHolderObj.forEach(othersOutput => {
            //         // if ((rectOutput.proj_id === polygonOutput.proj_id) && (rectOutput.proj_id === landmarkOutput.proj_id) && (rectOutput.proj_id === polylineOutput.proj_id) && (rectOutput.proj_id ===  othersOutput.proj_id)) {
            //           this.annotationChartLabels.push(rectOutput.proj_id + ' ');
            //           this.annotationChartData[0].data.push(rectOutput.completed_count);
            //           this.annotationChartData[1].data.push(polygonOutput.completed_count);
            //           this.annotationChartData[2].data.push(landmarkOutput.completed_count);
            //           this.annotationChartData[3].data.push(polylineOutput.completed_count);
            //           this.annotationChartData[4].data.push(othersOutput.completed_count);
            //         // }

            //       });
            //       });
            //     });
            //   });
            // });

            // Logic to remove a particular legend item goes here

            let allLegendsData = ["BoundingBox", "Polygon", "LandMarks", "Polyline","Others"] ;
            let legendData = _.uniq(_.map(this.ProjData, _.property('name')));
            console.log("-------allLegendsData-----"+allLegendsData+"------legendData------"+legendData);
            this.diffLegend = _.difference(allLegendsData, legendData);
            console.log("----------this.diffLegend---------"+this.diffLegend);
            this.diffLegend.forEach(element => {
              var removeIndex = this.annotationChartData.map(function (item) { return item.label; }).indexOf(element);
              console.log("----------------remove index-------------------"+removeIndex);
              this.annotationChartData.splice(removeIndex, 1);
            });

          }
        });
      }
    });
  }

  solutionWiseProjectList(sol_id?) {
    if (this.role_id === 2) {
      this.userId = this.user.getUserId();

      this.httpClient.request('get', this.ss.getSolutionWiseProjectHourDataListRole, 'sol_id=' + sol_id + '&emp_id=' + this.userId).subscribe((res: HttpResponse<any>) => {
        this.projectList = res.body;
        console.log(this.projectList);
      });
      this.httpClient.request('get', this.ss.getSolutionWiseEmployeeHourDataList, 'sol_id=' + sol_id).subscribe((res: HttpResponse<any>) => {
        this.employeeList = res.body;
        if (this.role_id == 2) {
          this.employeeList.forEach(element => {
            if (this.userId === element.emp_id) {
              let output = [];
              output.push(element);
              this.employeeList = output;
              console.log(this.employeeList[0].emp_id);
              this.filterFormGroup.controls['employees'].setValue(this.employeeList[0].emp_id);
            }
          });
        }
      });
    }
    else {
      if (sol_id != -1 && sol_id != '') {
        this.httpClient.request('get', this.ss.getSolutionWiseProjectHourDataList, 'sol_id=' + sol_id).subscribe((res: HttpResponse<any>) => {
          this.projectList = res.body;
          console.log(this.projectList);
        });
        this.httpClient.request('get', this.ss.getSolutionWiseEmployeeHourDataList, 'sol_id=' + sol_id).subscribe((res: HttpResponse<any>) => {
          this.employeeList = res.body;
          if (this.role_id == 2) {
            this.employeeList.forEach(element => {
              if (this.userId === element.emp_id) {
                let output = [];
                output.push(element);
                this.employeeList = output;
                console.log(this.employeeList[0].emp_id);
                this.filterFormGroup.controls['employees'].setValue(this.employeeList[0].emp_id);
              }
            });
          }
        });
      }
    }
    if (sol_id == -1) {
      this.activeProjects();
      this.activeEmployees();
    }
  }

  projectWiseData(proj_id) {
    console.log(proj_id);

    if (proj_id != -1 && proj_id != '') {
      this.httpClient.request('get', this.ss.getProjectWiseSolutionListHourData, 'proj_id=' + proj_id).subscribe((res: HttpResponse<any>) => {
        this.solutionList = res.body;
      });
      this.httpClient.request('get', this.ss.getProjectWiseEmployeeListHourData, 'proj_id=' + proj_id).subscribe((res: HttpResponse<any>) => {
        this.employeeList = res.body;
        if (this.role_id == 2) {
          this.employeeList.forEach(element => {
            if (this.userId === element.emp_id) {
              let output = [];
              output.push(element);
              this.employeeList = output;
              console.log(this.employeeList[0].emp_id);
              this.filterFormGroup.controls['employees'].setValue(this.employeeList[0].emp_id);
            }
          });
        }
      });
    }

    if (proj_id == -1) {
      this.activeSolutionsList();
      this.activeEmployees();
    }

  }

  employeeWiseData(emp_id) {

    if (emp_id != -1 && emp_id != '') {
      this.httpClient.request('get', this.ss.getemployeeWiseSolutionListHourData, 'emp_id=' + emp_id).subscribe((res: HttpResponse<any>) => {
        this.solutionList = res.body;
      });
      this.httpClient.request('get', this.ss.getemployeeWiseProjectListHourData, 'emp_id=' + emp_id).subscribe((res: HttpResponse<any>) => {
        this.projectList = res.body;
      });
    }
    if (emp_id == -1) {
      this.activeSolutionsList();
      this.activeProjects();
    }

  }

  currentStatistics(values) {
    this.filterSearch(values);
    // this.generateData();
  }

  dayStatistics() {
    this.barChartdata = undefined;
  }


  private chartData: any;
  piedata = [];
  piecolors = ["blue", "red", "orange", "voilet", "pink", "gray", "#007bff", "#007bff"];

  ngAfterViewInit() {
    this.filterFormGroup.controls['solutions'].setValue(-1);
    this.filterFormGroup.controls['projects'].setValue(-1);
    this.filterFormGroup.controls['employees'].setValue(-1);

    this.displayViewForm.controls['viewOptions'].setValue(-1);
  }

  currentData() {
    this.httpClient.noLoader(true).request('get', this.ss.getLabelingData).subscribe((res: HttpResponse<any>) => {
      this.currentDataList = res.body;
      console.log(this.currentDataList);
    });
  }

  generateData() {
    this.httpClient.noLoader(true).request('get', this.ss.getLabelingData).subscribe((res: HttpResponse<any>) => {
      this.chartData = res.body;
      this.barChartdata = [];
      let target = this.barChartData[0].data = [];
      let input = this.barChartData[1].data = [];
      let output = this.barChartData[2].data = [];
      this.barChartData[0].label = 'target';
      this.barChartData[1].label = 'input';
      this.barChartData[2].label = 'output';
      this.barChartLabels = [];

      const results = this.chartData.map(element => {
        const applyStatus = this.mappingList.find(elementPie => element.label === elementPie.emp_name && element.emp_id === elementPie.emp_id && element.proj_id === elementPie.proj_id);
        return {
          ...element,
          target: applyStatus.target_count ? applyStatus.target_count : undefined
        };
      });
      console.log(results);

      results.forEach(element => {
        this.barChartLabels.push(element.label);
        target.push(element.target * this.employeeDailyHours);
        input.push(element.input);
        output.push(element.output);
      });

    });
  }

  excelDownload(value) {
    if (value.solutions == -1) {
      value.solutions = '';
    }
    if (value.projects == -1) {
      value.projects = '';
    }
    if (value.employees == -1) {
      value.employees = '';
    }
    if (value.solutions != '' && value.solutions) {
      this.solutionList.forEach(element => {
        if (element.sol_id === value.solutions) {
          value.sol_name = element.sol_name;
        }
      });
    }
    if (value.projets != '' && value.projets) {
      this.projectList.forEach(element => {
        if (element.proj_id === value.projects) {
          value.proj_name = element.proj_name;
        }
      });
    }
    if (value.employees != '' && value.employees) {
      this.employeeList.forEach(element => {
        if (element.emp_id === value.employees) {
          value.emp_name = element.emp_name;
        }
      });
    }
    return JSON.stringify(value);
  }

  exportPdf() {
    this.filterSearch(this.filterFormGroup.value);
    setTimeout(() => {
      let canvas = this.canvasGraph.nativeElement;
      let canvasImg = canvas.toDataURL("image/png", 1.0);

      //creates PDF from img
      let doc = new jsPDF('landscape');
      doc.setFontSize(12);

      let sol_id = this.filterFormGroup.controls.solutions.value;
      let proj_id = this.filterFormGroup.controls.projects.value;
      let emp_id = this.filterFormGroup.controls.employees.value;
      let sol_name;
      let proj_name;
      let emp_name;

      if (sol_id && proj_id && emp_id) {
        if (sol_id == -1) {
          sol_name = 'All';
        }
        this.solutionList.forEach(element => {
          if (element.sol_id === sol_id) {
            sol_name = element.sol_name;
          }
        });
        if (proj_id == -1) {
          proj_name = 'All';
        }
        this.projectList.forEach(element => {
          if (element.proj_id === proj_id) {
            proj_name = element.proj_name;
          }
        });
        if (emp_id == -1) {
          emp_name = 'All';
        }
        this.employeeList.forEach(element => {
          if (element.emp_id === emp_id) {
            emp_name = element.emp_name;
          }
        });
        doc.text(100, 15, "Solution: " + sol_name + " Project: " + proj_name + " Employee: " + emp_name);
      }
      else if (sol_id && proj_id) {
        if (sol_id == -1) {
          sol_name = 'All';
        }
        this.solutionList.forEach(element => {
          if (element.sol_id === sol_id) {
            sol_name = element.sol_name;
          }
        });
        if (proj_id == -1) {
          proj_name = 'All';
        }
        this.projectList.forEach(element => {
          if (element.proj_id === proj_id) {
            proj_name = element.proj_name;
          }
        });

        doc.text(150, 15, "Solution: " + sol_name + " Project: " + proj_name);
      }
      else if (sol_id) {
        if (sol_id == -1) {
          sol_name = 'All';
        }
        this.solutionList.forEach(element => {
          if (element.sol_id === sol_id) {
            sol_name = element.sol_name;
          }
        });
        doc.text(150, 15, "Solution: " + sol_name);
      }
      let dateTime = this.ss.getDateTime();
      doc.text(15, 15, "Hour Chart: " + this.todayDate);
      doc.addImage(canvasImg, 'JPEG', 20, 20, 250, 125);
      doc.save('hour_' + dateTime + '_' + sol_name + '_' + proj_name + '_' + emp_name + '.pdf');
    }, 2000);
  }

  @UnSubscribe
  ngOnDestroy() {
    this.stopPolling();
  }

}