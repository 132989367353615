import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { FileUploader } from 'ng2-file-upload';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ClientDashboardComponent implements OnInit {

  displayedColumns = ['No', 'projectName', 'projectDesc', 'created', 'comcount', 'progress', 'details','download'];

  @ViewChild('projectModal', /* TODO: add static flag */ {static:false}) projectModal: ModalPopupComponent;
  @ViewChild('projectDetailsModal', /* TODO: add static flag */ {static:false}) projectDetailsModal: ModalPopupComponent;
  projectFormGroup: FormGroup;
  projectList: any;
  filesToUpload: Array<File> = [];
  public uploader: FileUploader = new FileUploader({ url: this.ss.uploader, authToken:this.user.getToken(), itemAlias: 'file', maxFileSize: 10 * 1024 * 1024 * 1024 });
  projectName: any;
  projectId: any;
  uploadData: any;
  projectStatus: any;
  projectUploadCount: any;
  role_id: any;
  rolePriviliges: any;

  constructor(
    private httpClient: HttpClientService,
    private formBuilder: FormBuilder,
    private ss: SingletonService,
    private user: UserService
  ) {
    this.projectFormGroup = this.formBuilder.group({
      'proj_name': ['', Validators.required],
      'proj_desc': ['', Validators.required]
    });
    this.uploader.onCompleteAll = () => {
      this.updateUploadAction();
      let client_id = 1;
      // this.httpClient.request('get', this.ss.distrubuteImages, 'proj_id=' + this.projectId + '&client_id=' + client_id + '&proj_name=' + this.projectName + '&cust_name=sravan').subscribe((res: any) => {
      //   if (res.status == 200) {
      //     console.log('distributed successfully');

      //   }
      // })
      console.log('completed');
    };
  }


  ngOnInit() {
    this.role_id = this.user.getRole();
    this.user.getUserId();
    this.user.currentMessage.subscribe(val=>{
      this.rolePriviliges = val[0];
    });
    this.clientProjectlist();

    // this.uploader.onBuildItemForm = (file: any, form: any) => {
    //   form.append('name' , 'sravan');
    //  };
    this.uploader.onBuildItemForm = (file: any, form: any) => {
      form.append('name', this.projectName);
      form.append('cust_name', 'sravan');
      form.append('cust_id', '1');
      form.append('proj_id', this.projectId)
    };
    //  this.uploader.onBuildItemForm = (file: any, form: any) => {
    //   form.append('cust_name' , 'sravan');
    //  };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      //  console.log('ImageUpload:uploaded:', item);
    };

  }

  updateUpload() {
    this.httpClient.request('post', this.ss.uploadUpdate, 'proj_id=' + this.projectId + '&count=' + this.uploader.queue.length).subscribe((res: any) => {
      console.log('1 record updated');
      this.clientProjectlist();
    })
  }

  DownloadImages(value){
    console.log(value);
    this.httpClient.request('get', this.ss.downloadImages).subscribe((res: any) => {
      console.log('zip created');
      
    })
  }

  clientProjectlist() {
    this.httpClient.request('get', this.ss.getClientProjectList).subscribe((res: any) => {
      this.projectList = res.body;
      console.log(this.projectList);

      this.httpClient.request('get', this.ss.ClientProjectStatus).subscribe((res: any) => {
        this.projectStatus = res.body;
        console.log(this.projectStatus);

        this.httpClient.request('get', this.ss.ClientProjectsuploadCount).subscribe((res: any) => {
          this.projectUploadCount = res.body;
          console.log(this.projectUploadCount);

          this.projectList.forEach(element => {
            this.projectStatus.forEach(ele => {
              if (element.cliproj_id == ele.cliproj_id) {
                element.status = ele.cliproj_count;
              }
            });
          });


          let dataOutputHolder = {};
          this.projectUploadCount.forEach(function (d) {
            if (dataOutputHolder.hasOwnProperty(d.proj_id)) {
              dataOutputHolder[d.proj_id] = dataOutputHolder[d.proj_id] + d.upload_count;
            } else {
              dataOutputHolder[d.proj_id] = d.upload_count;
            }
          });

          let dataOutputObj = [];

          for (var prop in dataOutputHolder) {
            dataOutputObj.push({ cliproj_id: prop, upload_count: dataOutputHolder[prop] });
          }
          console.log(dataOutputObj);


          this.projectList.forEach(element => {
            dataOutputObj.forEach(ele => {
              if (element.cliproj_id == ele.cliproj_id) {
                element.upload_count = ele.upload_count;
                if(element.status){
                  element.progress = (element.status / element.upload_count)*100;
                }
                else {
                  element.progress = 0;
                }
              }
            });
          });
          console.log(this.projectList);
          
        });
      })
    })
  }

  addProjectModal() {
    this.projectFormGroup.reset();
    this.projectModal.open();
  }

  projectSubmit(value) {
    console.log(value);
    if (this.projectFormGroup.valid) {
      this.httpClient.request('post', this.ss.addClientProject, '', value).subscribe((res: any) => {
        if (res.status === 200) {
          if (res.error.text == "Project added sucessfully") {
            this.ss.statusMessage.showStatusMessage(true, 'Project added sucessfully');
            this.projectModal.close();
            this.projectFormGroup.reset();
            this.clientProjectlist();
          }
          else if (res.error.text == "Project already exist") {
            this.ss.statusMessage.showStatusMessage(false, 'Project already exist', 3000, 'exist');
          }

        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }

  }

  updateUploadAction() {
    this.httpClient.request('get', this.ss.getUploaddata, 'proj_id=' + this.projectId + '&client_id=1').subscribe((res: any) => {
      if (res.status == 200) {
        this.uploadData = res.body;
      }
    })
  }

  showDetails(event: UIEvent, element): void {
    event.stopPropagation();
    this.uploader.queue.length = 0;
    this.projectDetailsModal.open();
    this.projectName = element.cliproj_name;
    this.projectId = element.cliproj_id;
    console.log(this.projectName);
    console.log(this.uploader.progress);
    this.httpClient.request('get', this.ss.getUploaddata, 'proj_id=' + this.projectId + '&client_id=1').subscribe((res: any) => {
      if (res.status == 200) {
        this.uploadData = res.body;
      }
    })
  }

}




