import { Component, OnInit, ViewChild } from '@angular/core';
import { SingletonService } from '../../services/singleton.service';
import { HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { HttpClientService } from 'src/app/services/http-client.service';
import { PollingService } from 'src/app/services/polling.service';
import { distinct } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as moment from 'moment';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-today-report',
  templateUrl: './today-report.component.html',
  styleUrls: ['./today-report.component.scss']
})
export class TodayReportComponent implements OnInit {
  range: any;
  solutionWiseInput: any;
  solutionWiseIO: any;
  solutionWiseProjects: any;
  solutionWiseTarget: any;
  filterFormGroup: FormGroup;
  solutionList: any;
  today = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  projectWiseIO: any;
  projectWiseEmployeeList: any;
  employeeWiseIO: any;
  allSolutionsIO: any;

  constructor(
    private httpClient: HttpClientService,
    private ss: SingletonService,
    private pollingService: PollingService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe
  ) {
    this.filterFormGroup = this.formBuilder.group({
      solutions: [''],
      projects: [''],
      employees: ['']
    });
  }
  public lineChartData = [
    { data: [], label: 'series A' },
    { data: [], label: 'series B' }
  ];

  public lineChartLabels: Label[] = [];
  public lineChartOptions = this.ss.lineChartOptions;
  public lineChartColors = this.ss.lineChartColors;
  public lineChartLegend = true;
  public lineChartType = 'line';

  ngOnInit() {
    this.activeSolutions();
  }

  activeSolutions() {
    this.httpClient.request('get', this.ss.getActiveSolutionList).subscribe((res: HttpResponse<any>) => {
      this.solutionList = res.body;
      this.allsolutionList();
    });
  }

  allsolutionList(){
    
    this.range = {};
    this.range.fromDate = this.today;
    this.range.toDate = this.today;
    console.log(this.range);
    this.httpClient.request('get', this.ss.allSolutionsHourData, 'range=' + JSON.stringify(this.range)).subscribe((res: HttpResponse<any>) => {
      this.allSolutionsIO = res.body;
      console.log(this.allSolutionsIO);

      let allSolutionsInputtHolder = {};
      let allSolutionsOutputHolder = {};

      this.allSolutionsIO.forEach(function (d) {
        if (allSolutionsInputtHolder.hasOwnProperty(d.hour)) {
          allSolutionsInputtHolder[d.hour] = allSolutionsInputtHolder[d.hour] + d.input_count;
        } else {
          allSolutionsInputtHolder[d.hour] = d.input_count;
        }
      });

      this.allSolutionsIO.forEach((d) => {
        if (allSolutionsOutputHolder.hasOwnProperty(d.hour)) {
          allSolutionsOutputHolder[d.hour] = allSolutionsOutputHolder[d.hour] + d.output_count;
        } else {
          allSolutionsOutputHolder[d.hour] = d.output_count;
        }
      });

      let allSolutionInputObj = [];
      let allSolutionOutputObj = [];

      for (var prop in allSolutionsInputtHolder) {
        allSolutionInputObj.push({ proj_id: prop, input_count: allSolutionsInputtHolder[prop] });
      }

      for (var prop in allSolutionsOutputHolder) {
        allSolutionOutputObj.push({ proj_id: prop, output_count: allSolutionsOutputHolder[prop] });
      }

      this.lineChartData[0].data = [];
      this.lineChartData[1].data = [];

      allSolutionInputObj.forEach(elementInput => {
        allSolutionOutputObj.forEach(elementOutput => {
          if (elementInput.proj_id === elementOutput.proj_id) {
            this.lineChartData[0].label = 'Available';
            this.lineChartLabels.push(elementInput.proj_id + ' Hour')
            this.lineChartData[0].data.push(elementInput.input_count);
            this.lineChartData[1].label = 'Completed';
            this.lineChartData[1].data.push(elementOutput.output_count);
          }
        });
      });

    });
  }

  solutionWiseProjectList(sol_id?) {
    console.log();
    this.filterFormGroup.controls.projects.reset();
    this.filterFormGroup.controls.employees.reset();
    this.range = {};
    if (sol_id) {
      this.range.sol_id = sol_id;
    }
    else {
      console.log(this.filterFormGroup.controls.solutions.value);
      this.range.sol_id = this.filterFormGroup.controls.solutions.value;
    }

    this.range.fromDate = this.today;
    this.range.toDate = this.today;
    console.log(this.range);
    this.httpClient.request('get', this.ss.rangeWiseDataDaily, 'range=' + JSON.stringify(this.range)).subscribe((res: HttpResponse<any>) => {
      this.solutionWiseIO = res.body;
      console.log(this.solutionWiseIO);

      let solutionInputtHolder = {};
      let solutionOutputHolder = {};

      this.solutionWiseIO.forEach(function (d) {
        if (solutionInputtHolder.hasOwnProperty(d.hour)) {
          solutionInputtHolder[d.hour] = solutionInputtHolder[d.hour] + d.input_count;
        } else {
          solutionInputtHolder[d.hour] = d.input_count;
        }
      });

      this.solutionWiseIO.forEach((d) => {
        if (solutionOutputHolder.hasOwnProperty(d.hour)) {
          solutionOutputHolder[d.hour] = solutionOutputHolder[d.hour] + d.output_count;
        } else {
          solutionOutputHolder[d.hour] = d.output_count;
        }
      });

      let solutionInputObj = [];
      let solutionOutputObj = [];

      for (var prop in solutionInputtHolder) {
        solutionInputObj.push({ proj_id: prop, input_count: solutionInputtHolder[prop] });
      }

      for (var prop in solutionOutputHolder) {
        solutionOutputObj.push({ proj_id: prop, output_count: solutionOutputHolder[prop] });
      }

      this.lineChartData[0].data = [];
      this.lineChartData[1].data = [];
      this.lineChartLabels =[];

      solutionInputObj.forEach(elementInput => {
        solutionOutputObj.forEach(elementOutput => {
          if (elementInput.proj_id === elementOutput.proj_id) {
            this.lineChartData[0].label = 'Available';
            this.lineChartLabels.push(elementInput.proj_id + ' Hour')
            this.lineChartData[0].data.push(elementInput.input_count);
            this.lineChartData[1].label = 'Completed';
            this.lineChartData[1].data.push(elementOutput.output_count);
          }
        });
      });

    });
    if (sol_id) {
      sol_id = sol_id;
    }
    else {
      sol_id = this.filterFormGroup.controls.solutions.value;
    }

    this.httpClient.request('get', this.ss.getSolutionWiseProjectList, 'sol_id=' + sol_id).subscribe((res: HttpResponse<any>) => {
      this.solutionWiseProjects = res.body;
    });

  }

  projectWiseData(proj_id) {
    this.filterFormGroup.controls.employees.reset();
    this.range = {};
    this.range.fromDate = this.today;
    this.range.toDate = this.today;
    this.range['sol_id'] = this.filterFormGroup.controls.solutions.value;
    this.range['proj_id'] = proj_id;
    console.log(this.range);

    this.httpClient.request('get', this.ss.projectWiseDataDaily, 'range=' + JSON.stringify(this.range)).subscribe((res: HttpResponse<any>) => {
      this.projectWiseIO = res.body;
      console.log(this.projectWiseIO);
      let projecInputtHolder = {};
      let projectOutputHolder = {};

      this.projectWiseIO.forEach((d) => {
        d.date = this.datepipe.transform(d.date, 'yyyy-MM-dd');
        if (projecInputtHolder.hasOwnProperty(d.hour)) {
          projecInputtHolder[d.hour] = projecInputtHolder[d.hour] + d.input_count;
        } else {
          projecInputtHolder[d.hour] = d.input_count;
        }
      });

      this.projectWiseIO.forEach((d) => {
        if (projectOutputHolder.hasOwnProperty(d.hour)) {
          projectOutputHolder[d.hour] = projectOutputHolder[d.hour] + d.output_count;
        } else {
          projectOutputHolder[d.hour] = d.output_count;
        }
      });

      let projectInputObj = [];
      let projectOutputObj = [];

      for (var prop in projecInputtHolder) {
        projectInputObj.push({ proj_id: prop, input_count: projecInputtHolder[prop] });
      }

      for (var prop in projectOutputHolder) {
        projectOutputObj.push({ proj_id: prop, output_count: projectOutputHolder[prop] });
      }
      this.lineChartData[0].data = [];
      this.lineChartData[1].data = [];
      this.lineChartLabels = [];
      projectInputObj.forEach(elementInput => {
        projectOutputObj.forEach(elementOutput => {
          if (elementInput.proj_id === elementOutput.proj_id) {
            this.lineChartData[0].label = 'Available';
            this.lineChartLabels.push(elementInput.proj_id + ' Hour');
            this.lineChartData[0].data.push(elementInput.input_count);
            this.lineChartData[1].label = 'Completed';
            this.lineChartData[1].data.push(elementOutput.output_count);
          }
        });
      });

    });
    this.httpClient.request('get', this.ss.getProjectWiseEmployeeList, 'proj_id=' + proj_id).subscribe((res: HttpResponse<any>) => {
      this.projectWiseEmployeeList = res.body;
    });
  }

  employeeWiseData(emp_id) {
    this.range = {};
    this.range.fromDate = this.today;
    this.range.toDate = this.today;
    this.range['sol_id'] = this.filterFormGroup.controls.solutions.value;
    this.range['proj_id'] = this.filterFormGroup.controls.projects.value;;
    this.range['emp_id'] = emp_id;

    this.httpClient.request('get', this.ss.employeeWiseDataDaily, 'range=' + JSON.stringify(this.range)).subscribe((res: HttpResponse<any>) => {
      this.employeeWiseIO = res.body;
      let employeeInputtHolder = {};
      let employeeOutputHolder = {};

      this.employeeWiseIO.forEach((d) => {
        if (employeeInputtHolder.hasOwnProperty(d.hour)) {
          employeeInputtHolder[d.hour] = employeeInputtHolder[d.hour] + d.input_counthour;
        } else {
          employeeInputtHolder[d.hour] = d.input_count;
        }
      });

      this.employeeWiseIO.forEach((d) => {
        if (employeeOutputHolder.hasOwnProperty(d.hour)) {
          employeeOutputHolder[d.hour] = employeeOutputHolder[d.hour] + d.output_count;
        } else {
          employeeOutputHolder[d.hour] = d.output_count;
        }
      });

      let employeeInputObj = [];
      let employeeOutputObj = [];

      for (var prop in employeeInputtHolder) {
        employeeInputObj.push({ proj_id: prop, input_count: employeeInputtHolder[prop] });
      }

      for (var prop in employeeOutputHolder) {
        employeeOutputObj.push({ proj_id: prop, output_count: employeeOutputHolder[prop] });
      }
      this.lineChartData[0].data = [];
      this.lineChartData[1].data = [];
      this.lineChartLabels = [];
      employeeInputObj.forEach(elementInput => {
        employeeOutputObj.forEach(elementOutput => {
          if (elementInput.proj_id === elementOutput.proj_id) {
            this.lineChartData[0].label = 'Available';
            this.lineChartLabels.push(elementInput.proj_id + ' Hour')
            this.lineChartData[0].data.push(elementInput.input_count);
            this.lineChartData[1].label = 'Completed';
            this.lineChartData[1].data.push(elementOutput.output_count);
          }
        });
      });
    });
  }

}
