import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import * as d3 from 'd3';
@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {

    body: any;
    configDonut: any;
    placeholderName: any = "";
    el: HTMLElement;
    @ViewChild("donutchart", /* TODO: add static flag */   {static:false}) elementRef: ElementRef;
  
    @Input() bands = 2;
  
    @Input() colors= {day: "green", week: "black", month: "#2863a8", avg: "#d17d20"};
  
    @Input() settings: any =  {
          size: 330,
          min:  0,
          max: 100,
          minorTicks: 6,
          greenZones: {},
          avg: {},
          avgMin: {},
          avgHour: {},
          yellowZones: {},
          redZones: {}
    };
  
    constructor() {
  
    }
  
    ngOnInit() {
      // initialization
      this.el = this.elementRef.nativeElement;
      
      
    }
    ngAfterViewInit(){
      // this.drawAvg(this.settings.avg.from, this.settings.avg.to, 0, 0.52, 0.55, 0.50, 0.48);
      // this.redrawMonth(this.settings.avg.avg);
    }
  
    createDonut(name, label, min, max) {
      this.configure1(name, this.getdeafultconfig1(name, label, min, max))
      this.renderDonut();
      this.drawBandsD(label);
      console.log(this.settings);
     
    }
  
    getdeafultconfig1(name, label, min, max) {
      let configDonut =
        {
          size: this.settings.size,
          label: label,
          min: min || this.settings.min,
          max: max || this.settings.max,
          minorTicks: this.settings.minorTicks,
          greenZones: this.settings.greenZones,
          avg: this.settings.avg,
          avgMin: this.settings.avgMin,
          avgHour: this.settings.avgHour,
          yellowZones: this.settings.yellowZones,
          redZones: this.settings.greenZones
  
        }
  
      var range = configDonut.max - configDonut.min;
      // config.yellowZones = [{ from: config.min + range * 0.20, to: config.min + range * 0.50 }];
      // config.redZones = [{ from: config.min, to: config.min + range * 0.20 }];
      // config.yellowZones = [{ from: config.min + range*0.5, to: config.min + range*0.7 }];
      configDonut.greenZones = [{ from: configDonut.min, to: configDonut.max }];
      // config.avg = { from: 45, to: 78, color: '#d17d20' }
      // config.avgMin = { from: 55, to: 75, color: '#d17d20' }
      // config.avgHour = { from: 45, to: 60, color: '#d17d20' }
      return configDonut;
    }
  
    configure1(placeholderName, configuration) {
      this.placeholderName = placeholderName;
      // console.log(this.placeholderName);
      this.configDonut = configuration;
      this.configDonut.size = this.configDonut.size * 0.9;
  
      this.configDonut.raduis = this.configDonut.size * 0.87 / 2;
      this.configDonut.cx = this.configDonut.size / 2;
      this.configDonut.cy = this.configDonut.size / 2;
  
      this.configDonut.min = undefined != configuration.min ? configuration.min : 0;
      this.configDonut.max = undefined != configuration.max ? configuration.max : 100;
      this.configDonut.range = this.configDonut.max - this.configDonut.min;
  
      this.configDonut.majorTicks = configuration.majorTicks || 6;
      this.configDonut.minorTicks = configuration.minorTicks || 2;
  
      this.configDonut.greenColor = configuration.greenColor || "#109618";
      this.configDonut.yellowColor = configuration.yellowColor || "#FF9900";
      this.configDonut.redColor = configuration.redColor || "#DC3912";
  
      this.configDonut.transitionDuration = configuration.transitionDuration || 500;
    }
  
  
    drawBandsD(label){
      for (var index in this.configDonut.greenZones) {
          
          this.drawBandD(this.configDonut.greenZones[index].from, this.configDonut.greenZones[index].to, '#6e6e6e', 0.83,0.95,label+0);
          // this.drawBandD(this.configDonut.greenZones[index].from, this.configDonut.greenZones[index].to, '#6e6e6e', 0.43, 0.55,label+1);
          // this.drawBand(this.config.greenZones[index].from, this.config.greenZones[index].to, '#bbb',0.52,0.53,2);
          
          
        }
    
        for (var index in this.configDonut.yellowZones) {
          this.drawBandD(this.configDonut.yellowZones[index].from, this.configDonut.yellowZones[index].to, this.configDonut.yellowColor,0,0,0);
        }
    
        // for (var index in this.configDonut.redZones) {
        //   this.drawBandD(this.configDonut.redZones[index].from, this.configDonut.redZones[index].to, this.configDonut.redColor,0,0,0);
        // }
    }
  
    pointerLine(){ 
        return d3.line()
        .x(function (d: any) { return d.x; })
        .y(function (d: any) { return d.y; })
        .curve(d3.curveLinear);
    }
  
  
    renderDonut() {
      
  
      this.body = d3.select(this.el)
        .append("svg")
        .attr("class", "gauge")
        .attr("width", this.configDonut.size)
        .attr("height", this.configDonut.size);
  
      
      this.drawBandsD(this.configDonut.label);
  
      // this.drawAvg(this.config.avg.from, this.config.avg.to, 2, 0.88,0.91, 0.88,0.85);
      // this.drawAvg(this.config.avg.from, this.config.avg.to, 2, 0.88,0.91, 0.88,0.85);
      // this.drawAvg1(this.config.avgMin.from, this.config.avgMin.to, this.config.avgMin.color);
      // this.drawAvg2(this.config.avgHour.from, this.config.avgHour.to, this.config.avgHour.color);
  
      if (undefined != this.configDonut.label) {
        var fontSize = Math.round(this.configDonut.size / 9);
        this.body.append("svg:text")
          .attr("x", this.configDonut.cx)
          .attr("y", this.configDonut.cy / 2 + fontSize / 2)
          .attr("dy", fontSize / 2)
          .attr("text-anchor", "middle")
          .text(this.configDonut.label)
          .style("font-size", fontSize + "px")
          .style("fill", "#333")
          .style("stroke-width", "0px");
      }
  
      var pointerLine = d3.line()
        .x(function (d: any) { return d.x; })
        .y(function (d: any) { return d.y; })
        .curve(d3.curveLinear);
    }
  
  
  
    drawBandD = function (start, end, color, min, max,i) {
      if (0 >= end - start) return;
      d3.selectAll('.band-paths.'+ this.placeholderName +i).remove();
      this.body.append("svg:path")
        .style("fill", color)
        .style('opacity','0.4')
        // .style("filter", "url(#drop-shadow)")
        .attr("class", "band-paths " + this.placeholderName+i )
        .attr("d", d3.arc()
          .startAngle(this.valueToRadians(start))
          .endAngle(this.valueToRadians(end))
          .innerRadius(min* this.configDonut.raduis)
          .outerRadius(max* this.configDonut.raduis))
        .attr("transform", () => { return "translate(" + this.configDonut.cx + ", " + this.configDonut.cy + ") rotate(360)" });
    }
  
  
    drawMinToAvg = function (start, end, i, inner, outer, min, max, color ) {
      console.log(start);
      console.log(end);
      
      let colorOfBand = this.colors[color];
      if(start === undefined && end === undefined) return;
      if (0 >= end - start) return;
  
      d3.selectAll(".avg-pathss."+this.placeholderName + i).remove();
  
      this.body.append("svg:path")
        .style("fill", "#f22100")
        .style("opacity", "1")
        // .style("filter", "url(#drop-shadow-avg)")
        .attr("class", "avg-pathss " + this.placeholderName + i)
        .attr("d", d3.arc()
          .startAngle(this.valueToRadians(start))
          .endAngle(this.valueToRadians(end))
          // .innerRadius(0.88 * this.configDonut.raduis)
          // .outerRadius(0.91 * this.configDonut.raduis))
          .innerRadius(inner * this.configDonut.raduis)
          .outerRadius(outer * this.configDonut.raduis))
        .attr("transform", () => { return "translate(" + this.configDonut.cx + ", " + this.configDonut.cy + ") rotate(270)" });
  
  
  
      var fontSize = Math.round(this.configDonut.size / 26);
  
      // var minpoint = this.valueToPoint(start, 0.88);
      var minpoint = this.valueToPoint(start, outer);
      console.log(typeof minpoint.y);
      
      this.body.append("svg:text")
        .attr("x", minpoint.x)
        .attr("y", minpoint.y + 4)
        .attr("dy", fontSize / 3)
        .attr("class", "avg-pathss " + this.placeholderName + i)
        .attr("text-anchor", "start")
        .text(start)
        .style("font-size", 10 + "px")
        .style("fill", "#623829")
        .style('font-weight', 'bold')
        .style("stroke-width", "0px");
      // var maxpoint = this.valueToPoint(end, 0.85);
      // var maxpoint = this.valueToPoint(end, outer);
      // this.body.append("svg:text")
      //   .attr("x", maxpoint.x)
      //   .attr("y", maxpoint.y)
      //   .attr("dy", fontSize / 3)
      //   .attr("class", "avg-path " + this.placeholderName + i)
      //   .attr("text-anchor", "end")
      //   .text(end)
      //   .style("font-size", fontSize + "px")
      //   .style("fill", "#623829")
      //   .style('font-weight', 'bold')
      //   .style("stroke-width", "0px");
    }

    drawAvgToMax = function (start, end, i, inner, outer, min, max, color ) {
      console.log(start);
      console.log(end);
      let colorOfBand = this.colors[color];
      if(start === undefined && end === undefined) return;
      if (0 >= end - start) return;
  
      d3.selectAll(".avg-pathss."+this.placeholderName + i + 1).remove();
  
      this.body.append("svg:path")
        .style("fill", '#49ff00')
        .style("opacity", "1")
        // .style("filter", "url(#drop-shadow-avg)")
        .attr("class", "avg-pathss " + this.placeholderName + i)
        .attr("d", d3.arc()
          .startAngle(this.valueToRadians(start))
          .endAngle(this.valueToRadians(end))
          // .innerRadius(0.88 * this.configDonut.raduis)
          // .outerRadius(0.91 * this.configDonut.raduis))
          .innerRadius(inner * this.configDonut.raduis)
          .outerRadius(outer * this.configDonut.raduis))
        .attr("transform", () => { return "translate(" + this.configDonut.cx + ", " + this.configDonut.cy + ") rotate(270)" });
  
  
  
      var fontSize = Math.round(this.configDonut.size / 24);
  
      // var minpoint = this.valueToPoint(start, 0.88);
      var minpoint = this.valueToPoint(start, outer);
      this.body.append("svg:text")
        .attr("x", minpoint.x)
        .attr("y", minpoint.y + 8)
        .attr("dy", fontSize / 3)
        .attr("class", "avg-pathss " + this.placeholderName + i + 1)
        .attr("text-anchor", "end")
        .text(start)
        .style("font-size", 10 + "px")
        .style("fill", "#623829")
        .style('font-weight', 'bold')
        .style("stroke-width", "0px");
      // var maxpoint = this.valueToPoint(end, 0.85);
      var maxpoint = this.valueToPoint(end, outer);
      this.body.append("svg:text")
        .attr("x", maxpoint.x)
        .attr("y", maxpoint.y + 3)
        .attr("dy", fontSize / 3)
        .attr("class", "avg-pathss " + this.placeholderName + i + 1)
        .attr("text-anchor", "end")
        .text(end)
        .style("font-size", 10 + "px")
        .style("fill", "#623829")
        .style('font-weight', 'bold')
        .style("stroke-width", "0px");
    }
  
    valueToDegrees = function (value) {
      //return value / this.configDonut.range * 270 - 45;
      return value / this.configDonut.range * 360 - (this.configDonut.min / this.configDonut.range * 360 + 80);
    }
  
    valueToRadians = function (value) {
      return this.valueToDegrees(value) * Math.PI / 180;
    }
  
    valueToPoint = function (value, factor) {
      return {
        x: this.configDonut.cx - this.configDonut.raduis * factor * Math.cos(this.valueToRadians(value)),
        y: this.configDonut.cy - this.configDonut.raduis * factor * Math.sin(this.valueToRadians(value))
      };
    }
  
  
  
}
