import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UploadService } from 'src/app/services/upload.service';
import { HttpClientService } from 'src/app/services/http-client.service';
import { HttpResponse } from '@angular/common/http';
import { SingletonService } from 'src/app/services/singleton.service';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-client-upload',
  templateUrl: './client-upload.component.html',
  styleUrls: ['./client-upload.component.scss']
})
export class ClientUploadComponent implements OnInit {
  
  form: FormGroup;
  error: string;
  userId: number = 1;
  filesToUpload: Array<File> = [];
  public uploader:FileUploader = new FileUploader({url:this.ss.uploader, itemAlias: 'file',maxFileSize : 10 * 1024 * 1024 * 1024 });
  

  constructor(
    private formBuilder: FormBuilder, 
    private uploadService: UploadService,
    private httpClient: HttpClientService, 
    public ss: SingletonService
  ) { }

  ngOnInit() {
    
    this.uploader.onBuildItemForm = (file: any, form: any) => {
      form.append('name' , 'sravan');
     };

    this.uploader.onAfterAddingFile = (file) => { 
      file.withCredentials = false; 
    };
    
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
         console.log('ImageUpload:uploaded:', item);
     };
     
    this.form = this.formBuilder.group({
      avatar: ['']
    });
  }
  
  upload() {
    const formData: any = new FormData();
    const files: Array<File> = this.filesToUpload;
    console.log(files);
    formData.time = this.ss.getDateTime();;

    for(let i =0; i < files.length; i++){
      console.log(files[i]);
      
        formData.append("uploads[]", files[i], files[i]['name']);
    }
    console.log('form data variable :   '+ formData.toString());
    this.httpClient.request('post',this.ss.upload,'',formData).subscribe((res: HttpResponse<any>) => {
      
     console.log('success');
     
    });
    // this.http.request('post','http://10.60.62.73:3000/upload', formData)
    //     .map(files => files.json())
    //     .subscribe(files => console.log('files', files))
}

fileChangeEvent(fileInput: any) {
    this.filesToUpload = <Array<File>>fileInput.target.files;
    //this.product.photo = fileInput.target.files[0]['name'];
}

}
