import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { HttpResponse } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-client-project-mapping',
  templateUrl: './client-project-mapping.component.html',
  styleUrls: ['./client-project-mapping.component.scss']
})
export class ClientProjectMappingComponent implements OnInit {

  clientProjectMappingFormGroup:FormGroup;
  clientList:any = [{'client_id':1,'client_name':'atai'}];
  displayedColumns: string[] = ['S No', 'cli_name', 'cliproj_name', 'proj_name', 'created'];
  clientProjectList: any;
  activeProjectList: any;
  mappingList: any;
  @ViewChild('mappingTag', /* TODO: add static flag */ {static:false}) mappingTag;
  role_id: any;
  rolePriviliges: string;
  constructor(
    private formBuilder : FormBuilder,
    private httpClient : HttpClientService,
    private ss :SingletonService,
    private user :UserService
  ) { 
    this.clientProjectMappingFormGroup = this.formBuilder.group({
      'client_id' : ['', Validators.required], 
      'cliproj_id': ['', Validators.required],
      'proj_id'   : ['', Validators.required]
    });
  }

  ngOnInit() {
    this.role_id = this.user.getRole();
    this.user.getUserId();
    this.user.currentMessage.subscribe(val=>{
      this.rolePriviliges = val[0];
    });
    this.clientProjectMappingList();
  }

  changeClient(cli_id){
    
      this.httpClient.request('get', this.ss.clientProjectData, 'client_id=' + cli_id).subscribe((res: HttpResponse<any>) => {
        if (res.status === 200) {
          console.log("success");
          this.clientProjectList = res.body;
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
      this.httpClient.request('get', this.ss.getActiveProjectData,'client_id=' + cli_id).subscribe((res: HttpResponse<any>) => {
        if (res.status === 200) {
          console.log("success");
          this.activeProjectList = res.body;
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });

  }

  addClientProjectMapping(values){
    if(this.clientProjectMappingFormGroup.valid){
    this.httpClient.request('post', this.ss.clientProjectMapping,'values='+JSON.stringify(values)).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.mappingTag.resetForm();
        this.clientProjectMappingList();
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }
  }

  clientProjectMappingList(){
    this.httpClient.request('get', this.ss.clientProjectMappedData).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.mappingList = res.body;
        this.mappingList.forEach(ele => {
          this.clientList.forEach(element => {
            if(ele.cli_id == element.client_id){
              ele.client_name = element.client_name;
            }
          });
        });
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }
}
