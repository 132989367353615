import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as d3 from "d3";
import { config } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'dbd-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent implements OnInit {
  body: any;
  config: any;
  placeholderName: any = "";
  el: HTMLElement;
  @ViewChild("gaugechart", /* TODO: add static flag */   {static:false}) elementRef: ElementRef;

  @Input() bands = 3;

  @Input() colors = { day: "green", week: "black", month: "#2863a8", avg: "#d17d20" };

  @Input() settings: any = {
    size: 330,
    min: 0,
    max: 100,
    minorTicks: 6,
    greenZones: {},
    avg: {},
    avgMin: {},
    avgHour: {},
    yellowZones: {},
    redZones: {}
  };

  constructor() {

  }

  ngOnInit() {
    // initialization
    this.el = this.elementRef.nativeElement;


  }
  ngAfterViewInit() {
    // this.drawAvg(this.settings.avg.from, this.settings.avg.to, 0, 0.52, 0.55, 0.50, 0.48);
    // this.redrawMonth(this.settings.avg.avg);
  }

  // to save the current config which helps in taking a diff if a new config is set. If there is a change then the this.initialized is reset.
  currentConfig;

  createGauge(name, label, min, max) {
    this.configure(name, this.getdeafultconfig(name, label, min, max))
    this.render();
    // console.log(this.settings);

  }

  getdeafultconfig(name, label, min, max) {
    let config =
    {
      size: this.settings.size,
      label: label,
      min: min || this.settings.min,
      max: max || this.settings.max,
      minorTicks: this.settings.minorTicks,
      greenZones: this.settings.greenZones,
      avg: this.settings.avg,
      avgMin: this.settings.avgMin,
      avgHour: this.settings.avgHour,
      yellowZones: this.settings.yellowZones,
      redZones: this.settings.greenZones

    }

    var range = config.max - config.min;
    // config.yellowZones = [{ from: config.min + range * 0.20, to: config.min + range * 0.50 }];
    // config.redZones = [{ from: config.min, to: config.min + range * 0.20 }];
    // config.yellowZones = [{ from: config.min + range*0.5, to: config.min + range*0.7 }];
    config.greenZones = [{ from: config.min, to: config.max }];
    // config.avg = { from: 45, to: 78, color: '#d17d20' }
    // config.avgMin = { from: 55, to: 75, color: '#d17d20' }
    // config.avgHour = { from: 45, to: 60, color: '#d17d20' }
    return config;
  }

  configure(placeholderName, configuration) {
    
    if (this.placeholderName !== placeholderName || JSON.stringify(configuration) !== JSON.stringify(this.currentConfig)) {
      
      // if there is a change in the configuration set the current config to the new config and in order to reinitalize set this.initialized to false
      this.initialized = false;
      this.currentConfig = _.cloneDeep(configuration);

      this.placeholderName = placeholderName;
      // console.log(this.placeholderName);
      this.config = configuration;
      this.config.size = this.config.size * 0.9;

      this.config.raduis = this.config.size * 0.87 / 2;
      this.config.cx = this.config.size / 2;
      this.config.cy = this.config.size / 2;

      this.config.min = undefined != configuration.min ? configuration.min : 0;
      this.config.max = undefined != configuration.max ? configuration.max : 100;
      this.config.range = this.config.max - this.config.min;

      this.config.majorTicks = configuration.majorTicks || 6;
      this.config.minorTicks = configuration.minorTicks || 2;

      this.config.greenColor = configuration.greenColor || "#109618";
      this.config.yellowColor = configuration.yellowColor || "#FF9900";
      this.config.redColor = configuration.redColor || "#DC3912";

      this.config.transitionDuration = configuration.transitionDuration || 500;

    }
  }


  drawBands() {
    if (!this.initialized) {
      for (var index in this.config.greenZones) {
        if (this.bands === 3) {
          this.drawBand(this.config.greenZones[index].from, this.config.greenZones[index].to, '#999', 0.89, 0.90, 0);
          this.drawBand(this.config.greenZones[index].from, this.config.greenZones[index].to, '#999', 0.70, 0.71, 1);
          this.drawBand(this.config.greenZones[index].from, this.config.greenZones[index].to, '#999', 0.52, 0.53, 2);
          this.majorTicksDay();
          this.majorTicksWeek();
          this.majorTicksMonth();


        } else if (this.bands >= 2) {
          this.drawBand(this.config.greenZones[index].from, this.config.greenZones[index].to, "#8a6592", 0.70, 0.71, 0);
          this.drawBand(this.config.greenZones[index].from, this.config.greenZones[index].to, "green", 0.52, 0.53, 1);
          this.majorTicksDay();
          this.majorTicksWeek();

        } else if (this.bands >= 1)
          this.drawBand(this.config.greenZones[index].from, this.config.greenZones[index].to, "green", 0.52, 0.53, 0);
        this.majorTicksDay();
        // this.drawBand3(this.config.greenZones[index].from, this.config.greenZones[index].to, this.config.greenColor);

      }

      for (var index in this.config.yellowZones) {
        this.drawBand(this.config.yellowZones[index].from, this.config.yellowZones[index].to, this.config.yellowColor, 0, 0, 0);
      }

      for (var index in this.config.redZones) {
        this.drawBand(this.config.redZones[index].from, this.config.redZones[index].to, this.config.redColor, 0, 0, 0);
      }
    }
  }

  pointerLine() {
    return d3.line()
      .x(function (d: any) { return d.x; })
      .y(function (d: any) { return d.y; })
      .curve(d3.curveLinear);
  }

  defs: any;

  initialized: boolean = false;

  appendDefs() {

    if (!this.initialized) {

      var defs = this.body.append("defs");

      // create filter with id #drop-shadow
      // height=130% so that the shadow is not clipped
      var filter = defs.append("filter")
        .attr("id", "drop-shadow")
        .attr("height", "130%");

      // SourceAlpha refers to opacity of graphic that this filter will be applied to
      // convolve that with a Gaussian with standard deviation 3 and store result
      // in blur
      filter.append("feGaussianBlur")
        .attr("in", "SourceGraphic")
        .attr("stdDeviation", 3)
        .attr("flood-color", "red")
        .attr("flood-opacity", "1")
        .attr("result", "blur");

      // translate output of Gaussian blur to the right and downwards with 2px
      // store result in offsetBlur
      filter.append("feOffset")
        .attr("in", "blur")
        .attr("dx", -2)
        .attr("dy", -2)
        .attr("result", "offsetBlur");

      // overlay original SourceGraphic over translated blurred opacity by using
      // feMerge filter. Order of specifying inputs is important!
      var feMerge = filter.append("feMerge");

      feMerge.append("feMergeNode")
        .attr("in", "offsetBlur")
      feMerge.append("feMergeNode")
        .attr("in", "SourceGraphic");


      // var defs = this.body.append("defs");

      // create filter with id #drop-shadow
      // height=130% so that the shadow is not clipped
      var filter = defs.append("filter")
        .attr("id", "drop-shadow-avg")
        .attr("height", "130%");

      // SourceAlpha refers to opacity of graphic that this filter will be applied to
      // convolve that with a Gaussian with standard deviation 3 and store result
      // in blur
      filter.append("feGaussianBlur")
        .attr("in", "SourceGraphic")
        .attr("stdDeviation", 3)
        .attr("flood-color", "red")
        .attr("flood-opacity", "1")
        .attr("result", "blur");

      // translate output of Gaussian blur to the right and downwards with 2px
      // store result in offsetBlur
      filter.append("feOffset")
        .attr("in", "blur")
        .attr("dx", -2)
        .attr("dy", -2)
        .attr("result", "offsetBlur");

      // overlay original SourceGraphic over translated blurred opacity by using
      // feMerge filter. Order of specifying inputs is important!
      var feMerge = filter.append("feMerge");

      feMerge.append("feMergeNode")
        .attr("in", "offsetBlur")
      feMerge.append("feMergeNode")
        .attr("in", "SourceGraphic");
    }
  }


  render() {

    // d3.select(this.el)
    //   .append("svg").remove();
    // console.log(d3.selectAll('svg'));

    this.body = d3.select(this.el)
      .append("svg")
      .attr("class", "gauge")
      .attr("width", this.config.size)
      .attr("height", this.config.size);

    this.appendDefs();

    // this.drawAvg(this.config.avg.from, this.config.avg.to, 2, 0.88,0.91, 0.88,0.85);
    // this.drawAvg(this.config.avg.from, this.config.avg.to, 2, 0.88,0.91, 0.88,0.85);
    // this.drawAvg1(this.config.avgMin.from, this.config.avgMin.to, this.config.avgMin.color);
    // this.drawAvg2(this.config.avgHour.from, this.config.avgHour.to, this.config.avgHour.color);

    // console.log(pointerContainer);


    // var pointerLine = d3.line()
    //   .x(function (d: any) { return d.x; })
    //   .y(function (d: any) { return d.y; })
    //   .curve(d3.curveLinear);

    if (!this.initialized) {

      this.drawBands();

      if (undefined != this.config.label) {
        var fontSize = Math.round(this.config.size / 9);
        this.body.append("svg:text")
          .attr("x", this.config.cx)
          .attr("y", this.config.cy / 2 + fontSize / 2)
          .attr("dy", fontSize / 2)
          .attr("text-anchor", "middle")
          .text(this.config.label)
          .style("font-size", fontSize + "px")
          .style("fill", "#333")
          .style("stroke-width", "0px");
      }

      // this.body.select('.pointerContainer').remove();
      // this.body.select('.pointerContainerWeek').remove();
      // this.body.select('.pointerContainerMonth').remove();

      var pointerContainer = this.body.append("svg:g").attr("class", "pointerContainer");

      var pointerContainerWeek = this.body.append("svg:g").attr("class", "pointerContainerWeek");

      var pointerContainerMonth = this.body.append("svg:g").attr("class", "pointerContainerMonth");

      var midValue = (this.config.min + this.config.max) / 2;


      var pointerPath = this.buildPointerPathMonth(midValue, 0.50);

      var pointerPathWeek = this.buildPointerPathWeek(midValue, 0.66);

      var pointerPathMonth = this.buildPointerPathDay(midValue, 0.85);

      pointerContainer.selectAll("path")
        .data([pointerPath])
        .enter()
        .append("svg:path")
        .attr("d", this.pointerLine())
        .style("fill", "red")
        .style("stroke", "red")
        .style("fill-opacity", 1.0)

      pointerContainerWeek.selectAll("path")
        .data([pointerPathWeek])
        .enter()
        .append("svg:path")
        .attr("d", this.pointerLine())
        .style("fill", "red")
        .style("stroke", "red")
        .style("fill-opacity", 1.0)

      pointerContainerMonth.selectAll("path")
        .data([pointerPathMonth])
        .enter()
        .append("svg:path")
        .attr("d", this.pointerLine())
        .style("fill", "rgb(220, 57, 18)")
        .style("stroke", "rgb(198, 51, 16)")
        .style("fill-opacity", 1.0)

      pointerContainerMonth.append("svg:circle")
        .attr("cx", this.config.cx)
        .attr("cy", this.config.cy)
        .attr("r", 0.06 * this.config.raduis)
        .style("fill", "rgb(70, 132, 238)")
        .style("stroke", "rgb(102, 102, 102)")
        .style("opacity", 1);

      var fontSize = Math.round(this.config.size / 10);
      pointerContainer.selectAll("text")
        .data([midValue])
        .enter()
        .append("svg:text")
        .attr("x", this.config.cx)
        .attr("y", this.config.size - this.config.cy / 2 - fontSize)
        .attr("dy", fontSize / 2)
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .style("fill", "#000")
        .style("stroke-width", "0px")
        .style('font-weight', 'bold');

      pointerContainerWeek.selectAll("text")
        .data([midValue])
        .enter()
        .append("svg:text")
        .text('month')
        .attr("x", this.config.cx)
        .attr("y", this.config.size - this.config.cy / 2.5 - fontSize)
        .attr("dy", fontSize / 2)
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .style("fill", "#000")
        .style("stroke-width", "1px")
        .style('font-weight', 'bold');

      pointerContainerMonth.selectAll("text")
        .data([midValue])
        .enter()
        .append("svg:text")
        .attr("x", this.config.cx)
        .attr("y", this.config.size - this.config.cy / 3.5 - fontSize)
        .attr("dy", fontSize / 2)
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .style("fill", "#000")
        .style("stroke-width", "0px")
        .style('font-weight', 'bold');

    }

    this.redraw(this.config.min, 0);
    this.redrawWeek(this.config.min, 0);
    this.redrawMonth(this.config.min, 0);

    this.initialized = true;


  }


  setPointer(pointerFor, append: boolean = false) {

    var midValue = (this.config.min + this.config.max) / 2;
    var pointerPath;
    var pointerContainer
    if (pointerFor === "") {
      pointerPath = this.buildPointerPathDay(midValue, 0.85);
      pointerContainer = this.body.select(".pointerContainer");
    } else if (pointerFor === "week") {
      pointerPath = this.buildPointerPathWeek(midValue, 0.66);
      pointerContainer = this.body.select(".pointerContainerWeek");
    } else if (pointerFor === "Month") {
      pointerPath = this.buildPointerPathMonth(midValue, 0.50);
      pointerContainer = this.body.select(".pointerContainer");
    }


    // pointerContainer.select("path");

    pointerContainer.selectAll("path").data(pointerPath)
      .attr("d", this.pointerLine());

  }



  buildPointerPathMonth(value, factor) {
    var delta = this.config.range / 25;
    var valueToPoint = (value, factor) => {
      var point = this.valueToPoint(value, factor);
      point.x -= this.config.cx;
      point.y -= this.config.cy;
      return point;
    }
    var head = valueToPoint(value, factor);
    var head1 = valueToPoint(value - delta, 0.12);
    var head2 = valueToPoint(value + delta, 0.12);

    var tailValue = value - (this.config.range * (1 / (270 / 360)) / 2);
    var tail = valueToPoint(tailValue, 0.20);
    var tail1 = valueToPoint(tailValue - delta, 0.12);
    var tail2 = valueToPoint(tailValue + delta, 0.12);

    return [head, head1, tail2, tail, tail1, head2, head];

  }

  buildPointerPathWeek = function (value, factor) {
    var delta = this.config.range / 100;
    var valueToPoint = (value, factor) => {
      var point = this.valueToPoint(value, factor);
      point.x -= this.config.cx;
      point.y -= this.config.cy;
      return point;
    }
    var head = valueToPoint(value - 0.20, factor);
    var head1 = valueToPoint(value - delta, 0.61);
    var head2 = valueToPoint(value + delta, 0.61);

    var tailValue = value - (this.config.range * (1 / (270 / 360)) / 2);
    var tail = valueToPoint(tailValue, 0.20);
    var tail1 = valueToPoint(tailValue - delta, 0.12);
    var tail2 = valueToPoint(tailValue + delta, 0.12);

    return [head, head1, head2, head];

  }

  buildPointerPathDay = function (value, factor) {
    var delta = this.config.range / 100;
    var valueToPoint = (value, factor) => {
      var point = this.valueToPoint(value, factor);
      point.x -= this.config.cx;
      point.y -= this.config.cy;
      return point;
    }
    var head = valueToPoint(value, factor);
    var head1 = valueToPoint(value - delta, 0.80);
    var head2 = valueToPoint(value + delta, 0.80);

    var tailValue = value - (this.config.range * (1 / (270 / 360)) / 2);
    var tail = valueToPoint(tailValue, 0.20);
    var tail1 = valueToPoint(tailValue - delta, 0.12);
    var tail2 = valueToPoint(tailValue + delta, 0.12);

    return [head, head1, head2, head];


  }

  majorTicksDay = function () {
    var fontSize = Math.round(this.config.size / 16);
    var majorDelta = this.config.range / (this.config.majorTicks - 1);
    this.body.selectAll('.ticks-day-minor').remove();
    this.body.selectAll('.ticks-day-major').remove();
    for (var major = this.config.min; major <= this.config.max; major += majorDelta) {
      var minorDelta = majorDelta / this.config.minorTicks;
      for (var minor = major + minorDelta; minor < Math.min(major + majorDelta, this.config.max); minor += minorDelta) {
        var point1 = this.valueToPoint(minor, 0.89);
        var point2 = this.valueToPoint(minor, 0.94);

        this.body.append("svg:line")
          .attr("x1", point1.x)
          .attr("y1", point1.y)
          .attr("x2", point2.x)
          .attr("y2", point2.y)
          .style("stroke", "#999")
          .style("stroke-width", "1px")
          .style("filter", "url(#drop-shadow)")
          .attr('class', 'ticks-day-minor');
      }

      var point1 = this.valueToPoint(major, 0.89);
      var point2 = this.valueToPoint(major, 0.97);

      this.body.append("svg:line")
        .attr("x1", point1.x)
        .attr("y1", point1.y)
        .attr("x2", point2.x)
        .attr("y2", point2.y)
        .style("stroke", "#aaa")
        .style("stroke-width", "1px")
        .style("filter", "url(#drop-shadow)")
        .attr('class', 'ticks-day-major');
    }
  }

  majorTicksMonth = function () {
    var fontSize = Math.round(this.config.size / 16);
    var majorDelta = this.config.range / (this.config.majorTicks - 1);
    this.body.selectAll('.ticks-month-minor').remove();
    this.body.selectAll('.ticks-month-major').remove();

    for (var major = this.config.min; major <= this.config.max; major += majorDelta) {
      var minorDelta = majorDelta / this.config.minorTicks;
      for (var minor = major + minorDelta; minor < Math.min(major + majorDelta, this.config.max); minor += minorDelta) {
        var point1 = this.valueToPoint(minor, 0.57);
        var point2 = this.valueToPoint(minor, 0.53);

        this.body.append("svg:line")
          .attr("x1", point1.x)
          .attr("y1", point1.y)
          .attr("x2", point2.x)
          .attr("y2", point2.y)
          .style("stroke", "#999")
          .style("stroke-width", "1px")
          .style("filter", "url(#drop-shadow)")
          .attr('class', 'ticks-month-minor');
      }

      var point1 = this.valueToPoint(major, 0.60);
      var point2 = this.valueToPoint(major, 0.52);

      this.body.append("svg:line")
        .attr("x1", point1.x)
        .attr("y1", point1.y)
        .attr("x2", point2.x)
        .attr("y2", point2.y)
        .style("stroke", "#aaa")
        .style("stroke-width", "2px")
        .style("filter", "url(#drop-shadow)")
        .attr('class', 'ticks-month-major');
      var point = this.valueToPoint(major, 0.34);

      this.body.append("svg:text")
        .attr("x", point.x)
        .attr("y", point.y)
        .attr("dy", fontSize / 3)
        .attr("text-anchor", major >= 50 ? "end" : "start")
        .attr("class", "ticks-week-text")
        .text(major)
        .style("font-size", fontSize - 6 + "px")
        .style("fill", "#444")
        .style("stroke-width", "0px");
    }
  }

  majorTicksWeek = function () {
    var fontSize = Math.round(this.config.size / 16);
    var majorDelta = this.config.range / (this.config.majorTicks - 1);


    this.body.selectAll('.ticks-week-major').remove();
    this.body.selectAll('.ticks-for-week-minor').remove();
    this.body.selectAll('.ticks-week-text').remove();

    for (var major = this.config.min; major <= this.config.max; major += majorDelta) {
      var minorDelta = majorDelta / this.config.minorTicks;

      for (var minor = major + minorDelta; minor < Math.min(major + majorDelta, this.config.max); minor += minorDelta) {

        var point1 = this.valueToPoint(minor, 0.72);
        var point2 = this.valueToPoint(minor, 0.76);

        this.body.append("svg:line")
          .attr("x1", point1.x)
          .attr("y1", point1.y)
          .attr("x2", point2.x)
          .attr("y2", point2.y)
          .style("stroke", "#999")
          .style("stroke-width", "1px")
          .style("filter", "url(#drop-shadow)")
          .attr('class', 'ticks-for-week-minor');



      }

      var point1 = this.valueToPoint(major, 0.72);
      var point2 = this.valueToPoint(major, 0.78);

      this.body.append("svg:line")
        .attr("x1", point1.x)
        .attr("y1", point1.y)
        .attr("x2", point2.x)
        .attr("y2", point2.y)
        .attr("class", "ticks-week-major")
        .style("stroke", "#aaa")
        .style("stroke-width", "2px")
        .style("filter", "url(#drop-shadow)");

      // var point = this.valueToPoint(major, 0.35);

      // this.body.append("svg:text")
      //   .attr("x", point.x)
      //   .attr("y", point.y)
      //   .attr("dy", fontSize / 3)
      //   .attr("text-anchor", major >= 50 ? "end" : "start")
      //   .text(major)
      //   .style("font-size", fontSize - 6 + "px")
      //   .style("fill", "#333")
      //   .style("stroke-width", "0px");
    }
  }

  drawBand = function (start, end, color, min, max, i) {
    if (0 >= end - start) return;
    d3.selectAll('.band-path.' + this.placeholderName + i).remove();
    this.body.append("svg:path")
      .style("fill", color)
      .style("filter", "url(#drop-shadow)")
      .attr("class", "band-path " + this.placeholderName + i)
      .attr("d", d3.arc()
        .startAngle(this.valueToRadians(start))
        .endAngle(this.valueToRadians(end))
        .innerRadius(min * this.config.raduis)
        .outerRadius(max * this.config.raduis))
      .attr("transform", () => { return "translate(" + this.config.cx + ", " + this.config.cy + ") rotate(270)" });
  }


  drawAvg = function (start, end, i, inner, outer, min, max, color) {
    let colorOfBand = this.colors[color];
    if (start === undefined && end === undefined) return;
    if (0 >= end - start) return;

    d3.selectAll(".avg-path." + this.placeholderName + i).remove();

    this.body.append("svg:path")
      .style("fill", colorOfBand)
      .style("opacity", "1")
      .style("filter", "url(#drop-shadow-avg)")
      .attr("class", "avg-path " + this.placeholderName + i)
      .attr("d", d3.arc()
        .startAngle(this.valueToRadians(start))
        .endAngle(this.valueToRadians(end))
        // .innerRadius(0.88 * this.config.raduis)
        // .outerRadius(0.91 * this.config.raduis))
        .innerRadius(inner * this.config.raduis)
        .outerRadius(outer * this.config.raduis))
      .attr("transform", () => { return "translate(" + this.config.cx + ", " + this.config.cy + ") rotate(270)" });


    var fontSize = Math.round(this.config.size / 26);

    // var minpoint = this.valueToPoint(start, 0.88);
    var minpoint = this.valueToPoint(start, outer);
    this.body.append("svg:text")
      .attr("x", minpoint.x)
      .attr("y", minpoint.y)
      .attr("dy", fontSize / 3)
      .attr("class", "avg-path " + this.placeholderName + i)
      .attr("text-anchor", "end")
      .text(start)
      .style("font-size", fontSize + "px")
      .style("fill", "#623829")
      .style('font-weight', 'bold')
      .style("stroke-width", "0px");
    // var maxpoint = this.valueToPoint(end, 0.85);
    var maxpoint = this.valueToPoint(end, outer);
    this.body.append("svg:text")
      .attr("x", maxpoint.x)
      .attr("y", maxpoint.y)
      .attr("dy", fontSize / 3)
      .attr("class", "avg-path " + this.placeholderName + i)
      .attr("text-anchor", "end")
      .text(end)
      .style("font-size", fontSize + "px")
      .style("fill", "#623829")
      .style('font-weight', 'bold')
      .style("stroke-width", "0px");
  }

  redraw = function (value, transitionDuration?, title = "Month") {

    var pointerContainer = this.body.select(".pointerContainer");

    pointerContainer.selectAll("text").text(title + "- " + Math.round(value)).style('fill', this.colors.month);

    var pointer = pointerContainer.selectAll("path");

    pointer.transition()
      .duration(undefined != transitionDuration ? transitionDuration : this.config.transitionDuration)
      //.delay(0)
      //.ease("linear")
      //.attr("transform", function(d) 
      .attrTween("transform", () => {
        var pointerValue = value;
        if (value > this.config.max) pointerValue = this.config.max + 0.02 * this.config.range;
        else if (value < this.config.min) pointerValue = this.config.min - 0.02 * this.config.range;
        var targetRotation = (this.valueToDegrees(pointerValue) - 90);
        var currentRotation = this._currentRotation || targetRotation;
        this._currentRotation = targetRotation;

        return (step) => {
          var rotation = currentRotation + (targetRotation - currentRotation) * step;
          return "translate(" + this.config.cx + ", " + this.config.cy + ") rotate(" + rotation + ")";
        }
      });
  }

  redrawMonth = function (value, transitionDuration?, title = "Day") {

    var pointerContainer = this.body.select(".pointerContainerMonth");

    pointerContainer.selectAll("text").text(title + " -" + Math.round(value)).style('fill', this.colors.day);

    var pointer = pointerContainer.selectAll("path");
    pointer.transition()
      .duration(undefined != transitionDuration ? transitionDuration : this.config.transitionDuration)
      //.delay(0)
      //.ease("linear")
      //.attr("transform", function(d) 
      .attrTween("transform", () => {
        var pointerValue = value;
        if (value > this.config.max) pointerValue = this.config.max + 0.02 * this.config.range;
        else if (value < this.config.min) pointerValue = this.config.min - 0.02 * this.config.range;
        var targetRotation = (this.valueToDegrees(pointerValue) - 90);
        var currentRotation = this._currentRotation || targetRotation;
        this._currentRotation = targetRotation;

        return (step) => {
          var rotation = currentRotation + (targetRotation - currentRotation) * step;
          return "translate(" + this.config.cx + ", " + this.config.cy + ") rotate(" + rotation + ")";
        }
      });
  }

  redrawWeek = function (value, transitionDuration?, title = "Week") {
    var pointerContainer1 = this.body.select(".pointerContainerWeek");

    pointerContainer1.selectAll("text").text(title + " -" + Math.round(value)).style('fill', this.colors.week);

    var pointer = pointerContainer1.selectAll("path");
    pointer.transition()
      .duration(undefined != transitionDuration ? transitionDuration : this.config.transitionDuration)
      //.delay(0)
      //.ease("linear")
      //.attr("transform", function(d) 
      .attrTween("transform", () => {
        var pointerValue = value;
        if (value > this.config.max) pointerValue = this.config.max + 0.02 * this.config.range;
        else if (value < this.config.min) pointerValue = this.config.min - 0.02 * this.config.range;
        var targetRotation = (this.valueToDegrees(pointerValue) - 90);
        var currentRotation = this._currentRotation || targetRotation;
        this._currentRotation = targetRotation;

        return (step) => {
          var rotation = currentRotation + (targetRotation - currentRotation) * step;
          return "translate(" + this.config.cx + ", " + this.config.cy + ") rotate(" + rotation + ")";
        }
      });
  }

  valueToDegrees = function (value) {
    //return value / this.config.range * 270 - 45;
    return value / this.config.range * 270 - (this.config.min / this.config.range * 270 + 45);
  }

  valueToRadians = function (value) {
    return this.valueToDegrees(value) * Math.PI / 180;
  }

  valueToPoint = function (value, factor) {
    return {
      x: this.config.cx - this.config.raduis * factor * Math.cos(this.valueToRadians(value)),
      y: this.config.cy - this.config.raduis * factor * Math.sin(this.valueToRadians(value))
    };
  }


}
