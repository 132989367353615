import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { HttpResponse } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-image-distribution',
  templateUrl: './image-distribution.component.html',
  styleUrls: ['./image-distribution.component.scss']
})
export class ImageDistributionComponent implements OnInit {

  clientImageDistributionFormGroup:FormGroup;
  clientList:any = [{'client_id':1,'client_name':'atai'}];
  displayedColumns: string[] = ['cli_name', 'cliproj_name', 'cliupload_no','waveproj_name','emp_name','emp_dis_count','date'];
  
  clientProjectList: any;
  clientWaveProjectList :any;
  activeProjectList: any;
  mappingList: any;
  projectWiseEmployeeList: any;
  clientprojectWiseUploads: any;
  imageDistributionList: any;
  dataSource:any;
  @ViewChild('distributionNgForm', /* TODO: add static flag */ {static:false}) distributionNgForm;
  @ViewChild(MatPaginator, /* TODO: add static flag */ {static:false}) paginator: MatPaginator;
  role_id: any;
  rolePriviliges: any;
  constructor(
    private formBuilder : FormBuilder,
    private httpClient : HttpClientService,
    private ss :SingletonService,
    private user: UserService
  ) { 
    this.clientImageDistributionFormGroup = this.formBuilder.group({
      'client_id' : ['', Validators.required],
      'cliproj_id' : ['', Validators.required],
      'upload_id': ['', Validators.required],
      'upload_no':[''],
      'upload_count': [''],
      'proj_id': ['', Validators.required],
      'emp_id'   : ['', Validators.required]
    });
    this.imageDistribution();
  }

  ngOnInit() {
    this.role_id = this.user.getRole();
    this.user.getUserId();
    this.user.currentMessage.subscribe(val=>{
      this.rolePriviliges = val[0];
    });
    this.clientProjectMappingList();
    this.imageDistribution();
    this.clientImageDistributionFormGroup.controls['upload_count'].disable();
  }

  imageDistribution(){
    this.httpClient.request('get', this.ss.imageDistributionData).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.imageDistributionList = res.body;
        this.imageDistributionList.forEach(element => {
          this.clientList.forEach(ele => {
            if(element.cli_id == ele.client_id){
              element.client_name = ele.client_name;
            }
          });
        });
        this.dataSource = new MatTableDataSource(this.imageDistributionList);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  changeClient(cli_id){
    
      this.httpClient.request('get', this.ss.clientProjectMappingClientWise, 'client_id=' + cli_id).subscribe((res: HttpResponse<any>) => {
        if (res.status === 200) {
          console.log("success");
          this.clientProjectList = res.body;
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
  }


  changeClientProject(proj_id){
    let client_id = this.clientImageDistributionFormGroup.controls['client_id'].value;
    this.httpClient.request('get', this.ss.getClientProjectUploads,'proj_id=' + proj_id + '&client_id=' + client_id).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.clientprojectWiseUploads = res.body;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });

    this.httpClient.request('get', this.ss.getClientWaveProjectData,'proj_id=' + proj_id + '&client_id=' + client_id).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.clientWaveProjectList = res.body;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  changeWaveProject(proj_id){
    this.httpClient.request('get', this.ss.getProjectWiseActiveEmpList,'proj_id=' + proj_id).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.projectWiseEmployeeList = res.body;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  changeClientUpload(upload_id){
    
    this.clientprojectWiseUploads.forEach(element => {
      if(element.upload_id === upload_id){
        this.clientImageDistributionFormGroup.controls['upload_count'].setValue(element.upload_count)
      }
    });
    let uploads = this.clientprojectWiseUploads.length;
    for (let i = 0; i < uploads; i++) {
      if(this.clientprojectWiseUploads[i].upload_id === upload_id){
        this.clientImageDistributionFormGroup.controls['upload_no'].setValue(this.clientprojectWiseUploads[i].upload_no);
      }
    }
  }

  addProjectImageDistribution(values){
    console.log(values);
  
    if(this.clientImageDistributionFormGroup.valid){
    this.httpClient.request('post', this.ss.clientProjectImageDistribution,'values='+JSON.stringify(values)).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.distributionNgForm.resetForm();
        this.imageDistribution();
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }
  }

  clientProjectMappingList(){
    this.httpClient.request('get', this.ss.clientProjectMappedList).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.mappingList = res.body;
        this.mappingList.forEach(ele => {
          this.clientList.forEach(element => {
            if(ele.cli_id == element.client_id){
              ele.client_name = element.client_name;
            }
          });
        });
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }
}
