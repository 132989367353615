import { Component, OnInit, ViewChild } from '@angular/core';
import { SingletonService } from '../../services/singleton.service';
import * as _ from 'lodash';
import { HttpClientService } from 'src/app/services/http-client.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgxDrpOptions } from 'ngx-mat-daterange-picker';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';


@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss']
})
export class ExamComponent implements OnInit {

  options: NgxDrpOptions;
  feedbackFormGroup: FormGroup;
  @ViewChild('examSubmit', /* TODO: add static flag */ { static: false }) examSubmit: ModalPopupComponent;

  foods = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' }
  ];
  questions: any = [];
  display: boolean = false;
  displayQuestions: boolean = true;
  role_id: any;
  rolePriviliges: string;
  today = new Date;
  answers: any;


  constructor(private fb: FormBuilder, private httpClient: HttpClientService,
    private ss: SingletonService,
    private user: UserService, private formBuilder: FormBuilder,
    public datepipe: DatePipe) {
    this.feedbackFormGroup = this.formBuilder.group({
      // 'userName': ['', Validators.required],
      'feedback': this.fb.array([

      ])
    })
  }

  ngOnInit() {
    this.role_id = this.user.getRole();
    this.user.getUserId();
    this.user.currentMessage.subscribe(val => {
      this.rolePriviliges = val[0];
    });

    this.getQuestions();
    this.getQuestionOptions();

  }

  ngAfterViewInit() {
  }

  formatLabel(value: number) {
    return value;
  }

  submitFeedback(value) {
    this.examSubmit.open();
    // alert(JSON.stringify(value));
    value.userName = this.user.getUserName();
    this.answers = value;
    // 	if(this.feedbackFormGroup.valid){
    // 	this.httpClient.request('post', "feedback/add-feedback", '', value).subscribe((res: any) => {
    // 		if (res.status === 200) {
    // 			this.displayQuestions =false;
    // 		}
    // 	})
    // }
  }

  submitExam(value) {
    if (value) {
      if (this.feedbackFormGroup.valid) {
        this.httpClient.request('post', "feedback/add-feedback", '', this.answers).subscribe((res: any) => {
          if (res.status === 200) {
            this.displayQuestions = false;
            this.examSubmit.close();
          }
        })
      }
    }
    else {
      this.examSubmit.close();
    }
  }

  getQuestions() {
    this.httpClient.request('get', "feedback/get-questions").subscribe((res: any) => {
      if (res.status === 200) {
        this.questions = res.body;
        for (let i = 0; i < this.questions.length; i++) {
          let temp = this.fb.group({
            'id': [''],
            'answer': [''],
            'typeId': ['']
          })
          temp['controls'].id.setValue(this.questions[i].id);
          temp['controls'].typeId.setValue(this.questions[i].typeId);
          let fa = <FormArray>this.feedbackFormGroup.get('feedback');
          fa.push(temp);
        }
        this.display = true;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get events list");
      }
    });
  }

  getQuestionOptions() {
    this.httpClient.request('get', "feedback/get-questionOptions").subscribe((res: any) => {
      if (res.status === 200) {
        this.foods = res.body;
        let x = _.map(this.options, _.property('questionId'));
        console.log("----------options------" + JSON.stringify(this.foods));
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get events list");
      }
    })
  }

}
