import { Component, OnInit, ViewChild } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { HttpResponse } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.scss']
})
export class MappingComponent implements OnInit {

  @ViewChild('addMappingModal', /* TODO: add static flag */ {static:false}) addMappingModal:ModalPopupComponent;
  @ViewChild('updateEmployeeMappingModal', /* TODO: add static flag */ {static:false}) updateEmployeeMappingModal:ModalPopupComponent;
  @ViewChild('editMappingModal', /* TODO: add static flag */ {static:false}) editMappingModal:ModalPopupComponent;
  @ViewChild('addProjectMappingModal', /* TODO: add static flag */ {static:false}) addProjectMappingModal:ModalPopupComponent;
  @ViewChild('updateProjectMappingModal', /* TODO: add static flag */ {static:false}) updateProjectMappingModal:ModalPopupComponent;
  @ViewChild('employeeActive', /* TODO: add static flag */ {static:false}) employeeActive:ModalPopupComponent;
  @ViewChild('AddMappingTag', /* TODO: add static flag */ {static:false}) AddMappingTag;
  @ViewChild('UpdateEmployeeMappingTag', /* TODO: add static flag */ {static:false}) UpdateEmployeeMappingTag;
  @ViewChild('AddProjectMappingTag', /* TODO: add static flag */ {static:false}) AddProjectMappingTag;
  @ViewChild('UpdateProjectMappingTag', /* TODO: add static flag */ {static:false}) UpdateProjectMappingTag;
  @ViewChild('changeemployeeProject', /* TODO: add static flag */ {static:false}) changeemployeeProject;
  employeeMappingFormGroup:FormGroup;
  UpdateEmployeeMappingFormGroup: FormGroup;
  projectMappingFormGroup:FormGroup;
  updateProjectMappingFormGroup:FormGroup;
  editMappingFormGroup:FormGroup;
  empAssignmentFormGroup: FormGroup;
  projectWiseCategoryList: any;
  mappingList: any;
  employeeList: any;
  activeProjects: any;
  distinctProjects: any;
  employeeWiseProjectList: any;
  activeEmployeeList: any;
  employeeMappingList: any;
  employeeMappingActive = new FormControl('');
  getActiveSolutionList: any;
  activeSolutionList: any;
  projectMappingList: any;
  active: boolean = false;
  employeeLessProjectList: any;
  role_id: any;
  rolePriviliges: any;
  projName: any;
  hours = _.range(8);
  prevProjId: any;
  employeeWiseWorkedHours: any;
  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClientService,
    private ss: SingletonService,
    private user: UserService
  ) { 
    this.employeeMappingFormGroup = this.formBuilder.group({
      'proj_id': ['', Validators.required],
      'emp_id': ['', Validators.required],
      'target_count': ['', Validators.required]
    });
    
    this.UpdateEmployeeMappingFormGroup = this.formBuilder.group({
      'map_id' : ['', Validators.required],
      'proj_id': ['', Validators.required],
      'emp_id': ['', Validators.required],
      'target_count': ['', Validators.required]
    });
    // this.projectMappingFormGroup = this.formBuilder.group({
    //   'sol_id': ['', Validators.required],
    //   'proj_id': ['', Validators.required]
    // });
    // this.updateProjectMappingFormGroup = this.formBuilder.group({
    //   'projsol_id': ['', Validators.required],
    //   'sol_id': ['', Validators.required],
    //   'proj_id': ['', Validators.required]
    // });
    this.editMappingFormGroup = this.formBuilder.group({
      'projects': ['', Validators.required],
      'categories': ['', Validators.required],
      'employees': ['', Validators.required],
      'target': ['', Validators.required]
    });
    this.empAssignmentFormGroup = this.formBuilder.group({
      'emp_id':[''],
      'proj_id': ['', Validators.required],
      'hours': ['', Validators.required]
    });
  }
  capacityControl= new FormArray([]);

  ngOnInit() {
    this.role_id = this.user.getRole();
    this.user.getUserId();
    this.user.currentMessage.subscribe(val=>{
      this.rolePriviliges = val[0];
    });
    this.getEmployeeMappingDetails();
    this.getProjectMappingDetails();
  }

  getactiveProjectDetails(){
    this.httpClient.request('get', this.ss.getactiveProjectList).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.activeProjects = res.body;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getDistinctProjectList(){
    
    this.httpClient.request('get', this.ss.getDistinctProjectList).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.distinctProjects = res.body;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getActiveEmployeeDetails(){
    this.httpClient.request('get', this.ss.getActiveEmployeeList).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.activeEmployeeList = res.body;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getActiveSolutionDetails(){
    this.httpClient.request('get', this.ss.getActiveSolutionList).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.activeSolutionList = res.body;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getEmployeeMappingDetails(){
    this.httpClient.request('get', this.ss.getEmployeeMappingList).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.employeeMappingList = res.body;
        this.employeeMappingList.forEach(element => {
          this.capacityControl.push(new FormControl());
          element.editable = false;
        });
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getProjectMappingDetails(){
    this.httpClient.request('get', this.ss.getProjectMappingList).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.projectMappingList = res.body;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  addMappingModalOpen(){
    this.AddMappingTag.resetForm();
    this.getActiveEmployeeDetails();
    this.getDistinctProjectList();
    this.addMappingModal.open();
  }

  closeUpdateCapacity(details,i){
    details.editable = false;
  }

  UpdateEmployeeMappingModalOpen(details,i){
    details.editable = true;
    // this.UpdateEmployeeMappingTag.resetForm();
    // this.getActiveEmployeeDetails();
    // this.getDistinctProjectList();
    // this.capacityControl.controls[i].setValue(details.target_count);
    // this.UpdateEmployeeMappingFormGroup.controls['map_id'].setValue(details.map_id);
    // this.UpdateEmployeeMappingFormGroup.controls['proj_id'].setValue(details.proj_id);
    // this.UpdateEmployeeMappingFormGroup.controls['emp_id'].setValue(details.emp_id);
    // this.UpdateEmployeeMappingFormGroup.controls['target_count'].setValue(details.target_count);
    // this.updateEmployeeMappingModal.open();
  }

  // addProjectMappingModalOpen(){
  //   this.AddProjectMappingTag.resetForm();
  //   this.getActiveSolutionDetails();
  //   this.getDistinctProjectList();
  //   this.addProjectMappingModal.open();
  // }

  // updateProjectMappingModalOpen(details){
  //   this.UpdateProjectMappingTag.resetForm();
  //   this.getActiveSolutionDetails();
  //   this.getDistinctProjectList();
  //   this.updateProjectMappingFormGroup.controls['projsol_id'].setValue(details.projsol_id);
  //   this.updateProjectMappingFormGroup.controls['sol_id'].setValue(details.sol_id);
  //   this.updateProjectMappingFormGroup.controls['proj_id'].setValue(details.proj_id);
  //   this.updateProjectMappingModal.open();
  // }

  addEmployeeMapping(value){
    console.log(value);
    if(this.employeeMappingFormGroup.valid){
      var formData = new FormData();
      formData = value;
      this.httpClient.request('post', this.ss.addEmployeeMapping,'',formData).subscribe((res: any) => {
        if (res.status === 200){
          if(res.error.text == "Employee Mapping Added Successfully"){
            this.ss.statusMessage.showStatusMessage(true,'Employee Mapping Added Successfully');
            this.addMappingModal.close();
            this.AddMappingTag.resetForm();
            this.getEmployeeMappingDetails();
          }
          else if(res.error.text == "Employee Mapping already exist"){
            this.ss.statusMessage.showStatusMessage(false,'Employee Mapping already exist');
          }
        }
        else {
          this.ss.statusMessage.showStatusMessage(false,"Something went wrong");
        }
      });
    }
    
  }

  updateEmployeeMapping(value,capacity,index){
    if(capacity){
    value.target_count = capacity;
    console.log(value);
    if(this.capacityControl.controls[index].valid){
      var formData = new FormData();
      formData = value;
      this.httpClient.request('post', this.ss.updateEmployeeMapping,'',formData).subscribe((res: any) => {
        if (res.status === 200){
          if(res.error.text == "Employee Mapping Updated Successfully"){
            this.ss.statusMessage.showStatusMessage(true,'Employee Mapping Updated Successfully');
            // this.updateEmployeeMappingModal.close();
            // this.UpdateEmployeeMappingTag.resetForm();
            this.getEmployeeMappingDetails();
          }
          else if(res.error.text == "Employee Mapping already exist"){
            this.ss.statusMessage.showStatusMessage(false,'Change the target to update the mapping',2000,'empmap');
          }
        }
        else {
          this.ss.statusMessage.showStatusMessage(false,"Something went wrong");
        }
      });
    }
  }
  else{
    this.ss.statusMessage.showStatusMessage(false,'Please Enter/Change the Capacity',2000,'capacity');
    // alert('Please Enter/Change the Capacity');
  }
    
  }

  // addProjectMapping(value){
  //   console.log(value);
  //   if(this.projectMappingFormGroup.valid){
  //     var formData = new FormData();
  //     formData = value;
  //     this.httpClient.request('post', this.ss.addProjectMapping,'',formData).subscribe((res: any) => {
  //       if (res.status === 200){
  //         if(res.error.text == "Project Mapping Added Successfully"){
  //           this.ss.statusMessage.showStatusMessage(true,'Project Mapping Added Successfully');
  //           this.addProjectMappingModal.close();
  //           this.AddProjectMappingTag.resetForm();
  //           this.getProjectMappingDetails();
  //         }
  //         else if(res.error.text == "Project Mapping already exist"){
  //           this.ss.statusMessage.showStatusMessage(false,'Project Mapping already exist',2000,'projmap');
  //         }
  //       }
  //       else {
  //         this.ss.statusMessage.showStatusMessage(false,"Something went wrong");
  //       }
  //     });
  //   }
    
  // }

  // updateProjectMapping(value){
  //   console.log(value);
  //   if(this.updateProjectMappingFormGroup.valid){
  //     var formData = new FormData();
  //     formData = value;
  //     this.httpClient.request('post', this.ss.updateProjectMapping,'',formData).subscribe((res: any) => {
  //       if (res.status === 200){
  //         if(res.error.text == "Project Mapping Updated Successfully"){
  //           this.ss.statusMessage.showStatusMessage(true,'Project Mapping Updated Successfully');
  //           this.updateProjectMappingModal.close();
  //           this.UpdateProjectMappingTag.resetForm();
  //           this.getProjectMappingDetails();
  //         }
  //         else if(res.error.text == "Project Mapping already exist"){
  //           this.ss.statusMessage.showStatusMessage(false,'Project Mapping already exist',2000,'updateprojmap');
  //         }
  //       }
  //       else {
  //         this.ss.statusMessage.showStatusMessage(false,"Something went wrong");
  //       }
  //     });
  //   }
    
  // }

  editMappingUpdate(value){
    console.log(value);
    if(this.editMappingFormGroup.valid){
      var formData = new FormData();
      formData = value;
      this.httpClient.request('post', this.ss.updateTargetMapping,'',formData).subscribe((res: any) => {
        if (res.status === 200){
          this.ss.statusMessage.showStatusMessage(true,"Mapping updated succesfully");
          this.editMappingModal.close();
          this.editMappingFormGroup.reset();
          this.getEmployeeMappingDetails();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false,"Something went wrong");
        }
      });
    }
    
  }

  changeEmployee(emp_id){

    this.httpClient.request('get', this.ss.employeeWiseProjects,'emp_id=' + emp_id).subscribe((res: any) => {
      if (res.status === 200){
        this.employeeLessProjectList = res.body;
        if(this.employeeLessProjectList.length === 0){
          this.ss.statusMessage.showStatusMessage(false, 'There are no inactive projects to assign this employee', 2000,'no projects');
        }
      }
    });
  }

  empAssignmentUpdate(value){
    
    console.log(value);
    if(this.empAssignmentFormGroup.valid){
      let formData = new FormData();
      formData = value;
      value.prev_projid = this.prevProjId;
      this.httpClient.request('post', this.ss.employeeAssignment,'',formData).subscribe((res: any) => {
        if (res.status === 200){
            this.ss.statusMessage.showStatusMessage(true,'Employee Mapped to other Project Successfully');
            this.employeeActive.close();
            this.changeemployeeProject.resetForm();
            this.getEmployeeMappingDetails();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false,"Something went wrong");
        }
      });
    }
  }

  onChangeEmpMapStatus(id,value,emp_id,proj_name,proj_id){
    console.log(emp_id);
    console.log(value);
    this.projName = proj_name;
    this.changeemployeeProject.resetForm();
    this.httpClient.request('get', this.ss.getemployeeWiseProjectList,"emp_id="+ emp_id + '&proj_id=' + proj_id).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.employeeWiseProjectList = res.body;
        console.log(this.employeeWiseProjectList);
        // this.ss.statusMessage.showStatusMessage(true,"Employee added succesfully");
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
    this.httpClient.request('get', this.ss.getemployeeWiseWorkedHours,"emp_id="+ emp_id).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        console.log("success");
        this.employeeWiseWorkedHours = res.body;
        console.log(this.employeeWiseWorkedHours);
        var workedHours = 0;
        if(this.employeeWiseWorkedHours.length > 0){
        this.employeeWiseWorkedHours.forEach(element => {
          workedHours =+ workedHours + element.workedhours;
        });
      }
      console.log(workedHours);
      
      this.hours = _.range(9 - workedHours);
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
    this.empAssignmentFormGroup.controls['emp_id'].setValue(emp_id);
    this.prevProjId = proj_id;
    this.employeeActive.open();
  }

  IsEmployeeMappingActive(value){
    
    if(value == 1){
      this.employeeMappingActive.setValue(true);
    }
    if(value == 0){
      this.employeeMappingActive.setValue(false);
    }
  }

  onChangeProjectMappingStatus(id,proj_id,sol_id, value){
    let status;
    if(value == true){
      status = 1;
    }
    else{
      status = 0;
    }
    let formData = {};
    formData['status'] = status;
    formData['id'] = id;
    formData['proj_id'] = proj_id;
    formData['sol_id'] = sol_id;

    this.httpClient.request('post', this.ss.updateProjectMappingStatus, '', formData).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        this.ss.statusMessage.showStatusMessage(true, "Project Mapping Status updated succesfully");
        this.getProjectMappingDetails();
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

}
