
import {throwError as observableThrowError,  Observer, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import * as socketIo from 'socket.io-client';
import { Socket } from '../shared/interfaces';
import { Event } from "../shared/event";

declare var io : {
  connect(url: string): Socket;
};

@Injectable()
export class SocketService {
  private sock: socketIo.client;
  socket: Socket;
  observer: Observer<number>;

  getQuotes() : Observable<number> {
    this.socket = socketIo('https://wave.atdata.ai');
    // this.socket = socketIo('http://10.60.62.54:3100');
// alert("yessssssssssss");
    this.socket.on('data', (res) => {
      this.observer.next(res);
      // alert("hiiiiiiiiiiiiiiiiiiiii" + res);
    });

    return this.createObservable();
  }

  createObservable() : Observable<number> {
      return new Observable<number>(observer => {
        this.observer = observer;
      });
  }

  private handleError(error) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
        let errMessage = error.error.message;
        return observableThrowError(errMessage);
    }
    return observableThrowError(error || 'Socket.io server error');
  }

  public InitializeSocketConnection(): void {
    this.sock = socketIo.connect('https://wave.atdata.ai');
    // this.sock = socketIo.connect('http://10.60.62.54:3100');
    this.sock.send('hi');
    // this.sock.on('message', function (msg) {
    //   alert(msg)
    //   console.log(msg);




      
    // });
    this.sock.on('news', function (data) {
      console.log(data);
      // this.sock.emit('my other event', { my: 'data' });
    });
    
    this.sock.on('sale-data', function (data) {
      console.log(data);
      this.saleData = data;
      // this.sock.emit('my other event', { my: 'data' });
    });


  }

  public onEvent(event: Event): Observable<any> {
    return new Observable<Event>(observer => {
      this.sock.on(event, response => observer.next(response));
    });
  }

}
