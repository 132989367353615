import { Component, OnInit, ViewChild, Input, ElementRef, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import { easeBackInOut } from 'd3';
import { defaultColors } from 'ng2-charts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BarChartComponent implements OnInit {
  @ViewChild('chart', /* TODO: add static flag */   {static:false}) chartContainer: ElementRef;
  @Input() data: any;
  @Input() w = 500;
  @Input() h = 500;
  @Input() colors = ["#16A085", "#33435C", "#7b6888", "#6b486b", "#a05d56", "#d0743c", "#ff8c00"];

  constructor() { }

  ngOnInit() {


  }
  ngAfterViewInit() {
    this.createChart();
  }
  createChart() {
    let svg = d3.select(this.chartContainer.nativeElement);
    let margin = { top: 20, right: 20, bottom: 30, left: 40 };
    let width = +svg.attr("width") - margin.left - margin.right;
    let height = +svg.attr("height") - margin.top - margin.bottom;
    let g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    let x0 = d3.scaleBand()
      .rangeRound([0, width])
      .paddingInner(0.1);

    let x1 = d3.scaleBand()
      .padding(0.05);

    let y: any = d3.scaleLinear()
      .rangeRound([height, 0]);

    let z: any = d3.scaleOrdinal()
      .range(this.colors);

    let data = this.data;
    let keys = Object.keys(data[0]).slice(1);

    x0.domain(data.map(function (d) { return d.label; }));
    x1.domain(keys).rangeRound([0, x0.bandwidth()]);
    y.domain([0, d3.max(data, (d) => { return d3.max(keys, (key) => { return d[key]; }); })]).nice();

    // tooltips

    var tooltip = d3.select('body').append("div").attr("class", "tooltips");

    tooltip.append("rect")
      .attr("width", 30)
      .attr("height", 20)
      .attr("fill", "white")
      .style("opacity", 0.5);

    tooltip.append("text")
      .attr("x", 15)
      .attr("dy", "1.2em")
      .style("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("font-weight", "bold");

    g.append("g")
      .selectAll("g")
      .data(data)
      .enter().append("g")
      .attr("transform", (d: any) => { return "translate(" + x0(d.label) + ",0)"; })
      .selectAll("rect")
      .data((d) => {
        return keys.map((key) => {
          return { key: key, value: d[key] };
        });
      })
      .enter().append("rect")
      .attr("x", (d) => { return x1(d.key); })
      .attr("y", (d) => { return y(d.value); })
      .attr("width", x1.bandwidth())
      .attr("height", (d) => { return height - y(d.value); })
      .attr("fill", (d: any) => { return z(d.key); })
      .on("mousemove", function (d) {
        tooltip
          .style("left", d3.event.pageX - 50 + "px")
          .style("top", d3.event.pageY - 100 + "px")
          .style("display", "inline-block")
          .html((d.key) + "<br>" + (d.value));
      })
      .on("mouseout", function (d) { tooltip.style("display", "none"); });

    g.append("g")
      .attr("class", "axis")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x0));

    g.append("g")
      .attr("class", "axis")
      .call(d3.axisLeft(y).ticks(null, "s"))
      .append("text")
      .attr("x", 2)
      .attr("y", y(y.ticks().pop()) + 0.5)
      .attr("dy", "0.32em")
      .attr("fill", "#000")
      .attr("font-weight", "bold")
      .attr("text-anchor", "start")
      .text('count');

    var legend = g.append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "end")
      .selectAll("g")
      .data(keys.slice().reverse())
      .enter().append("g")
      .attr("transform", (d, i) => { return "translate(0," + i * 20 + ")"; });

    legend.append("rect")
      .attr("x", width - 19)
      .attr("width", 19)
      .attr("height", 19)
      .attr("fill", z)


    legend.append("text")
      .attr("x", width - 24)
      .attr("y", 9.5)
      .attr("dy", "0.32em")
      .text((d) => { return d; });
  }


}
